import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Paper,
  Typography,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import DeleteIcon from "@mui/icons-material/Delete";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { useTranslation } from "react-i18next";

function GroupManagement({ group, open, onClose }) {
  const { t } = useTranslation(["Employee", "Common"]);
  const userState = useSelector((state) => state.user);
  const [users, setUsers] = useState([]);
  const [availableUsers, setAvailableUsers] = useState([]);
  const [addUserDialogOpen, setAddUserDialogOpen] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);

  const fetchGroupUsers = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/Employee/GetEmployeByGroup/${group.id}`,
        {
          headers: {
            Authorization: `Bearer ${userState.token}`,
          },
        }
      );
      const data = await response.json();
      const formattedData = data.map((user, index) => ({
        id: user.noEmploye,
        firstname: user.firstname,
        lastName: user.lastName,
        username: user.username,
      }));
      setUsers(formattedData);
    } catch (error) {
      console.error("Error fetching group users:", error);
    }
  };

  const fetchAvailableUsers = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/Employee/GetAvailableEmployesByGroup/${group.id}`,
        {
          headers: {
            Authorization: `Bearer ${userState.token}`,
          },
        }
      );
      const data = await response.json();
      console.log(data);
      const formattedData = data.map((user) => ({
        id: user.noEmploye,
        firstname: user.name,
        lastName: user.lastName,
        username: user.userName,
      }));
      setAvailableUsers(formattedData);
    } catch (error) {
      console.error("Error fetching available users:", error);
    }
  };

  useEffect(() => {
    if (open && group) {
      fetchGroupUsers();
      fetchAvailableUsers();
    }
  }, [open, group]);

  const handleRemoveUser = async (userIds) => {
    // Convert single userId to array if necessary
    const userIdsArray = Array.isArray(userIds) ? userIds : [userIds];

    try {
      const payload = {
        Id: 0,
        IdGroupe: group.id,
        NoEmploye: userIdsArray[0], // First user for the main record
        Users: userIdsArray.map((userId) => ({
          Id: 0,
          NoEmploye: userId,
          GroupeId: group.id,
        })),
      };

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/Employee/RemoveUserFromGroup`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userState.token}`,
          },
          body: JSON.stringify(payload),
        }
      );

      if (response.ok) {
        fetchGroupUsers();
      }
    } catch (error) {
      console.error("Error removing users:", error);
    }
  };

  const handleAddUsers = async () => {
    if (selectedUsers.length === 0) return;

    try {
      const payload = {
        Id: 0,
        IdGroupe: group.id,
        NoEmploye: selectedUsers[0],
        Users: selectedUsers.map((userId) => ({
          Id: 0,
          NoEmploye: userId,
          GroupeId: group.id,
        })),
      };

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/Employee/AddUserToGroup`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userState.token}`,
          },
          body: JSON.stringify(payload),
        }
      );

      if (response.ok) {
        setAddUserDialogOpen(false);
        setSelectedUsers([]);
        fetchGroupUsers();
      }
    } catch (error) {
      console.error("Error adding users:", error);
    }
  };

  const columns = [
    { field: "firstname", headerName: t("FirstName"), flex: 1 },
    { field: "lastName", headerName: t("LastName"), flex: 1 },
    { field: "username", headerName: t("Username"), flex: 1 },
    {
      field: "actions",
      headerName: t("Actions"),
      width: 100,
      renderCell: (params) => (
        <IconButton
          onClick={() => handleRemoveUser(params.row.id)}
          color="error"
          size="small"
        >
          <DeleteIcon />
        </IconButton>
      ),
    },
  ];

  const availableUsersColumns = [
    { field: "firstname", headerName: t("FirstName"), flex: 1 },
    { field: "lastName", headerName: t("LastName"), flex: 1 },
    { field: "username", headerName: t("Username"), flex: 1 },
  ];

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={false}
      PaperProps={{
        sx: {
          width: "80vw",
          height: "80vh",
        },
      }}
    >
      <DialogTitle>
        {t("ManageUsersInGroup", { groupName: group?.nom })}
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            height: "calc(100% - 64px)",
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h6">{t("GroupUsers")}</Typography>
            <Button
              startIcon={<PersonAddIcon />}
              variant="contained"
              onClick={() => setAddUserDialogOpen(true)}
            >
              {t("AddUsers")}
            </Button>
          </Box>

          <Box sx={{ flex: 1, minHeight: 400 }}>
            <DataGridPro
              rows={users}
              columns={columns}
              disableRowSelectionOnClick
              initialState={{
                pagination: { paginationModel: { pageSize: 10 } },
              }}
              pageSizeOptions={[5, 10, 15]}
              sx={{ height: "100%" }}
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t("Close")}</Button>
      </DialogActions>

      {/* Add Users Dialog */}
      <Dialog
        open={addUserDialogOpen}
        onClose={() => setAddUserDialogOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>{t("AddUsersToGroup")}</DialogTitle>
        <DialogContent sx={{ height: "60vh" }}>
          <Box sx={{ height: "100%", width: "100%" }}>
            <DataGridPro
              rows={availableUsers}
              columns={availableUsersColumns}
              checkboxSelection
              onRowSelectionModelChange={(newSelection) => {
                setSelectedUsers(newSelection);
              }}
              rowSelectionModel={selectedUsers}
              pagination={true}
              pageSizeOptions={[5, 10, 25, 50, 100, 200, 500]}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                    page: 0,
                  },
                },
              }}
              paginationMode="client"
              disableVirtualization={false}
              loading={!availableUsers.length}
              sx={{ height: "100%" }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAddUserDialogOpen(false)}>
            {t("Cancel")}
          </Button>
          <Button
            variant="contained"
            onClick={handleAddUsers}
            disabled={selectedUsers.length === 0}
          >
            {t("AddSelected", { count: selectedUsers.length })}
          </Button>
        </DialogActions>
      </Dialog>
    </Dialog>
  );
}

export default GroupManagement;
