import React, { useState } from "react";
import { ResponsiveBar } from "@nivo/bar";
import {
  Typography,
  Box,
  CircularProgress,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import { useTranslation } from "react-i18next";

function SalesBarChart({ data: rawData, loading }) {
  const { t } = useTranslation(["Dashboard"]);
  const [timeFilter, setTimeFilter] = useState("month");
  const now = new Date();

  const getChartData = () => {
    if (!rawData || !rawData[377]) return [];

    const companyData = rawData[377];

    const sumDeclarationResults = (items) => {
      if (!items || !Array.isArray(items)) return 0;
      return items.reduce((sum, item) => {
        const value = parseInt(item.declarationDataResultat, 10);
        return sum + (isNaN(value) ? 0 : value);
      }, 0);
    };

    switch (timeFilter) {
      case "day": {
        // Get last 7 days
        const days = [];
        for (let i = 6; i >= 0; i--) {
          const date = new Date(now);
          date.setDate(date.getDate() - i);
          const key = date.toISOString().split("T")[0];
          days.push({
            day: t(date.toLocaleDateString("en-US", { weekday: "long" })),
            value: sumDeclarationResults(companyData.daily[key]),
          });
        }
        return days;
      }

      case "week": {
        // Get last 4 weeks
        const weeks = [];
        for (let i = 52; i >= 0; i--) {
          const date = new Date(now);
          date.setDate(date.getDate() - i * 7);
          const weekKey = `${date.getFullYear()}-W${String(
            date.getWeek()
          ).padStart(2, "0")}`;
          weeks.push({
            day: t(`Week ${weekKey.split("-W")[1]}`),
            value: sumDeclarationResults(companyData.weekly[weekKey]),
          });
        }
        return weeks;
      }

      case "month": {
        // Get all months comparing current year and previous year
        const months = [];
        const currentYear = now.getFullYear();
        const previousYear = currentYear - 1;

        // Create array for all 12 months, comparing current and previous year
        for (let i = 0; i < 12; i++) {
          const currentMonthKey = `${currentYear}-${String(i + 1).padStart(
            2,
            "0"
          )}`;
          const previousMonthKey = `${previousYear}-${String(i + 1).padStart(
            2,
            "0"
          )}`;
          const monthName = new Date(currentYear, i).toLocaleDateString(
            "en-US",
            {
              month: "long",
            }
          );
          months.push({
            day: t(monthName), // Translate month name
            [currentYear]: companyData.monthly[currentMonthKey]
              ? sumDeclarationResults(companyData.monthly[currentMonthKey])
              : 0,
            [previousYear]: companyData.monthly[previousMonthKey]
              ? sumDeclarationResults(companyData.monthly[previousMonthKey])
              : 0,
          });
        }
        return months;
      }

      case "year": {
        // Get last 3 years
        const years = [];
        for (let i = 2; i >= 0; i--) {
          const year = now.getFullYear() - i;
          years.push({
            day: year.toString(),
            value: sumDeclarationResults(companyData.yearly[year]),
          });
        }
        return years;
      }

      default:
        return [];
    }
  };

  // Helper function for week number
  Date.prototype.getWeek = function () {
    const d = new Date(
      Date.UTC(this.getFullYear(), this.getMonth(), this.getDate())
    );
    const dayNum = d.getUTCDay() || 7;
    d.setUTCDate(d.getUTCDate() + 4 - dayNum);
    const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    return Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
  };

  const chartData = getChartData();

  return (
    <Box sx={{ width: "100%", height: "100%", minHeight: 400 }}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Typography variant="h6">{t("SalesComparison")}</Typography>
        <FormControl size="small">
          <Select
            value={timeFilter}
            onChange={(e) => setTimeFilter(e.target.value)}
            sx={{ minWidth: 120 }}
          >
            <MenuItem value="month">{t("Monthly")}</MenuItem>
            <MenuItem value="year">{t("Yearly")}</MenuItem>
          </Select>
        </FormControl>
      </Box>

      <Box height="90%">
        {loading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
          >
            <CircularProgress />
          </Box>
        ) : (
          <ResponsiveBar
            data={chartData}
            keys={
              timeFilter === "month"
                ? [
                    now.getFullYear().toString(),
                    (now.getFullYear() - 1).toString(),
                  ]
                : ["value"]
            }
            indexBy="day"
            margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
            padding={0.3}
            groupMode="grouped"
            valueScale={{ type: "linear" }}
            indexScale={{ type: "band", round: true }}
            colors={
              timeFilter === "month" ? ["#0088FE", "#82ca9d"] : ["#0088FE"]
            }
            borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: t("Sales"),
              legendPosition: "middle",
              legendOffset: -40,
            }}
            labelSkipWidth={12}
            labelSkipHeight={12}
            legends={
              timeFilter === "month"
                ? [
                    {
                      dataFrom: "keys",
                      anchor: "bottom-right",
                      direction: "column",
                      justify: false,
                      translateX: 120,
                      translateY: 0,
                      itemsSpacing: 2,
                      itemWidth: 100,
                      itemHeight: 20,
                      itemDirection: "left-to-right",
                      itemOpacity: 0.85,
                      symbolSize: 20,
                    },
                  ]
                : undefined
            }
            role="application"
            ariaLabel="Sales comparison chart"
            barAriaLabel={(e) => `${e.indexValue}: ${e.value} sales`}
          />
        )}
      </Box>
    </Box>
  );
}

export default SalesBarChart;
