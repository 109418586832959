import React, { useEffect, useState } from "react";
import TabEmplacement from "./Tabs/TabEmplacement";
import TabRestaurant from "./Tabs/TabRestaurant";
import Autocomplete from "@mui/material/Autocomplete";
import FormControl from "@mui/material/FormControl";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import TabTenant from "./Tabs/TabTenant";
import TabWebTime from "./Tabs/TabWebTime";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { Tabs, Tab } from "@mui/material";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import "../../css/Emplacement.css";
import { useSelector } from "react-redux";
import CustomAlert from "../Common/Components/CustomAlert";
import CreateLocationModal from "./Modal/CreateLocationModal";
import dayjs from "dayjs";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: 800,
  width: 800,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`location-tabpanel-${index}`}
      aria-labelledby={`location-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `location-tab-${index}`,
    "aria-controls": `location-tabpanel-${index}`,
  };
}

function LocationView() {
  const { t, i18n } = useTranslation(["Location"]);
  const [restaurants, setRestaurants] = useState([]);
  const [restaurant, setRestaurant] = useState();
  const [currentRestaurant, setCurrentRestaurant] = useState();
  const [open, setOpen] = React.useState(false);

  const [ville, setVille] = useState("");
  const [region, setRegion] = useState("");
  const [adresse, setAdresse] = useState("");
  const [codePostal, setCodePostal] = useState("");

  const [regions, setRegions] = useState([]);
  const [country, setCountry] = useState("");
  const [countries, setCountries] = useState([]);
  const [province, setProvince] = useState("");
  const [provinces, setProvinces] = useState([]);

  const [locationName, setLocationName] = useState();
  const [alignment, setAlignment] = React.useState("web");
  const [showAlert, setShowAlert] = useState(false);

  const [typeMessage, setTypeMessage] = useState("");
  const [message, setMessage] = useState("");

  const userState = useSelector((state) => state.user);

  const [isLoading, setIsLoading] = useState(true);
  const [tabValue, setTabValue] = useState(0);

  // Add state for alert
  const [alert, setAlert] = useState({
    show: false,
    type: "",
    message: "",
  });

  useEffect(() => {
    GetCountriesProvincesRegions();
    GetEmplacement();
  }, []);

  const GetCountriesProvincesRegions = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/Localization/GetProvince`,
        {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userState.token}`,
          },
        }
      );

      const data = await response.json();

      // Create arrays to store unique objects
      const uniqueCountries = [];
      const uniqueProvinces = [];
      const uniqueRegions = [];

      // Process each item from the API
      data.forEach((element) => {
        // Process countries
        const countryExists = uniqueCountries.find(
          (c) => c.nomFr === element.paysFr && c.nomEn === element.paysEn
        );
        if (!countryExists) {
          uniqueCountries.push({
            id: uniqueCountries.length + 1,
            nomFr: element.paysFr,
            nomEn: element.paysEn,
          });
        }

        // Process provinces
        const provinceExists = uniqueProvinces.find(
          (p) =>
            p.nomFr === element.provinceFr && p.nomEn === element.provinceEn
        );
        if (!provinceExists) {
          uniqueProvinces.push({
            id: uniqueProvinces.length + 1,
            nomFr: element.provinceFr,
            nomEn: element.provinceEn,
          });
        }

        // Process regions
        const regionExists = uniqueRegions.find(
          (r) => r.nomFr === element.regionFr && r.nomEn === element.regionEn
        );
        if (!regionExists) {
          uniqueRegions.push({
            id: uniqueRegions.length + 1,
            nomFr: element.regionFr,
            nomEn: element.regionEn,
          });
        }
      });

      setCountries(uniqueCountries);
      setProvinces(uniqueProvinces);
      setRegions(uniqueRegions);
    } catch (error) {
      console.error("Error fetching location data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleOpen = async () => {
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const GetEmplacement = async () => {
    fetch(`${process.env.REACT_APP_API_URL}/Location/GetEmplacement`, {
      method: "GET",
      withCredentials: true,
      credentials: "include",

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userState.token}`,
      },
    })
      .then((results) => {
        return results.json();
      })
      .then((data) => {
        setRestaurants(data);
      });
  };

  const GetEmplacementsWithoutFilter = async () => {
    fetch(
      `${process.env.REACT_APP_API_URL}/Location/GetEmplacementsWithoutFilter`,
      {
        method: "GET",
        withCredentials: true,
        credentials: "include",

        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userState.token}`,
        },
      }
    )
      .then((results) => {
        return results.json();
      })
      .then((data) => {
        setRestaurants(data);
      });
  };

  const handleChangeEmplacement = (event, newValue) => {
    if (newValue) {
      const selectedRestaurant = restaurants.find((r) => r.nom === newValue);
      setCurrentRestaurant(selectedRestaurant);
      setRestaurant(newValue);
    } else {
      setCurrentRestaurant(null);
      setRestaurant(null);
    }
  };

  const handleChangeToggleButton = (event, newAlignment) => {
    setAlignment(newAlignment);
    if (newAlignment) {
      GetEmplacementsWithoutFilter();
    } else {
      GetEmplacement();
    }
  };

  const saveLocationData = async (data, userToken, activeTab) => {
    // Helper function to parse date in DD-MM-YYYY format
    const parseDate = (dateString) => {
      if (!dateString) return null;
      const [day, month, year] = dateString.split("-");
      return dayjs(`${year}-${month}-${day}`);
    };

    const baseLocationData = {
      noEmplacement: Number(data.noEmplacement) || 0,
      noAdresse: data.noAdresse || 0,
      activeTab: activeTab,
    };

    let locationData = { ...baseLocationData };

    switch (activeTab) {
      case 0: // Location tab
        // Parse dates
        const dateOuverture = parseDate(data.dateOuverture);
        const dateFermeture = parseDate(data.dateFermeture);

        locationData = {
          ...locationData,
          nom: data.nameLocation || "",
          noTelPrincipal: data.phoneNumber || null,
          noTelFax: data.faxNumber || null,
          email: data.email || null,
          noAdresse: data.noAdresse || null,
          dateOuverture: dateOuverture
            ? dateOuverture.format("YYYY-MM-DDTHH:mm:ss")
            : null,
          dateFermeture: dateFermeture
            ? dateFermeture.format("YYYY-MM-DDTHH:mm:ss")
            : null,
          idEmployePrincipal: data.responsableCoach || null,
          idDirecteurOps: data.directeurOps || null,
          adresseEmplacement: {
            adresse: data.address || "",
            ville: data.city || "",
            codePostal: data.zipCode || "",
            nomRegionFr: data.region?.nomFr || "",
            nomProvinceFr: data.province?.nomFr || "",
            nomPaysFr: data.country?.nomFr || "",
            nomRegionEn: data.region?.nomEn || "",
            nomProvinceEn: data.province?.nomEn || "",
            nomPaysEn: data.country?.nomEn || "",
          },
        };
        break;

      case 1: // Restaurant tab
        locationData = {
          ...locationData,
          nbSiege: data.nbSiege, // Direct assignment, no null check
          superficie: data.superficie, // Direct assignment, no null check
          IpAddress: data.IpAddress,
          PrixLivraison: data.PrixLivraison,
          PrixLivraisonParPalette: data.PrixLivraisonParPalette,
          IdTypePaiement: data.IdTypePaiement,
          NomTypePaiementFr: data.NomTypePaiementFr,
        };
        break;

      case 2: // Tenant tab
        locationData = {
          ...locationData,
          IdBailleur: data.id || null,
          NomBailleur: data.lessorName || null,
          AdresseBailleur: data.lessorAddress || null,
          TelAffaireBailleur: data.lessorBusinessPhone || null,
          FaxBailleur: data.lessorFax || null,
          MailAffaireBailleur: data.lessorBusinessEmail || null,
          ContactBailleur: data.lessorContact || null,
          DateBailEffectif: data.leaseEffectiveDate || null,
          DateBailExpiration: data.leaseExpirationDate || null,
          OptionRenouvelerBailleur: data.lessorRenewalOption || null,
          PeriodeAvisBailleur: data.lessorNoticePeriod || null,
        };
        break;

      case 3: // WebTime tab
        locationData = {
          ...locationData,
          monday_open: data.mondayOpen || null,
          tuesday_open: data.tuesdayOpen || null,
          wednesday_open: data.wednesdayOpen || null,
          thursday_open: data.thursdayOpen || null,
          friday_open: data.fridayOpen || null,
          saturday_open: data.saturdayOpen || null,
          sunday_open: data.sundayOpen || null,
          monday_close: data.mondayClose || null,
          tuesday_close: data.tuesdayClose || null,
          wednesday_close: data.wednesdayClose || null,
          thursday_close: data.thursdayClose || null,
          friday_close: data.fridayClose || null,
          saturday_close: data.saturdayClose || null,
          sunday_close: data.sundayClose || null,
          delivery: data.delivery ?? false,
          takeout: data.takeout ?? false,
          dinein: data.dinein ?? false,
          terrace: data.terrace ?? false,
          meeting_room: data.meetingRoom ?? false,
        };
        break;

      default:
        break;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/Location/SaveLocation`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify(locationData),
        }
      );

      const result = await response.json();

      if (response.status === 200) {
        setAlert({
          show: true,
          type: "success",
          message: t("LocationSavedSuccessfully"),
        });

        // Reload the data after successful save
        try {
          const reloadResponse = await fetch(
            `${process.env.REACT_APP_API_URL}/Location/GetEmplacement`,
            {
              method: "GET",
              withCredentials: true,
              credentials: "include",
              headers: {
                Authorization: `Bearer ${userToken}`,
              },
            }
          );

          if (reloadResponse.ok) {
            const updatedData = await reloadResponse.json();
            // Update the form with fresh data
            setRestaurants(updatedData);
          }
        } catch (reloadError) {
          console.error("Error reloading location data:", reloadError);
        }

        return {
          success: true,
          message: "Location saved successfully",
        };
      } else {
        setAlert({
          show: true,
          type: "error",
          message: result.title || t("ErrorSavingLocation"),
        });

        return {
          success: false,
          message: result.title || "Error saving location",
        };
      }
    } catch (error) {
      console.error("Error saving location:", error);

      setAlert({
        show: true,
        type: "error",
        message: t("ErrorConnectingToServer"),
      });

      return {
        success: false,
        message: "Error connecting to server",
      };
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ display: "flex", gap: 2, mb: 3 }}>
        <Button
          variant="contained"
          onClick={handleOpen}
          sx={{ textTransform: "none" }}
        >
          {t("NewLocation")}
        </Button>
        <ToggleButtonGroup
          color="primary"
          value={alignment}
          exclusive
          onChange={handleChangeToggleButton}
          aria-label="Platform"
        >
          <ToggleButton value={true}>{t("ShowInactiveLocation")}</ToggleButton>
        </ToggleButtonGroup>
      </Box>

      <CreateLocationModal
        open={open}
        handleClose={handleClose}
        locationName={locationName}
        setLocationName={setLocationName}
        adresse={adresse}
        setAdresse={setAdresse}
        ville={ville}
        setVille={setVille}
        codePostal={codePostal}
        setCodePostal={setCodePostal}
        country={country}
        setCountry={setCountry}
        province={province}
        setProvince={setProvince}
        region={region}
        setRegion={setRegion}
        countries={countries}
        provinces={provinces}
        regions={regions}
      />

      <Box sx={{ minWidth: 120, mb: 3 }}>
        <div className="APIKEY__filter">
          <FormControl fullWidth>
            <Autocomplete
              id={t("Location")}
              options={restaurants.map((resto) => resto.nom)}
              onChange={handleChangeEmplacement}
              value={restaurant || null}
              renderInput={(params) => (
                <TextField {...params} label={t("Location")} />
              )}
            />
          </FormControl>
        </div>
      </Box>

      <Box>
        {!isLoading && countries && provinces && regions ? (
          <Box
            sx={{
              backgroundColor: "white",
              borderRadius: 1,
              p: 3,
            }}
          >
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                centered
                sx={{
                  "& .MuiTab-root": {
                    textTransform: "none",
                    fontWeight: "medium",
                    "&.Mui-selected": {
                      fontWeight: "bold",
                      color: "primary.main",
                    },
                  },
                  "& .MuiTabs-indicator": {
                    backgroundColor: "primary.main",
                  },
                }}
              >
                <Tab label={t("Location")} {...a11yProps(0)} />
                <Tab label={t("Restaurant")} {...a11yProps(1)} />
                <Tab label={t("Tenant")} {...a11yProps(2)} />
                <Tab label={t("WebTime")} {...a11yProps(3)} />
              </Tabs>
            </Box>

            <TabPanel value={tabValue} index={0}>
              <TabEmplacement
                emplacement={currentRestaurant}
                countries={countries}
                provinces={provinces}
                regions={regions}
                onSave={saveLocationData}
              />
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <TabRestaurant
                emplacement={currentRestaurant}
                onSave={saveLocationData}
              />
            </TabPanel>
            <TabPanel value={tabValue} index={2}>
              <TabTenant
                emplacement={currentRestaurant}
                onSave={saveLocationData}
              />
            </TabPanel>
            <TabPanel value={tabValue} index={3}>
              <TabWebTime
                emplacement={currentRestaurant}
                onSave={saveLocationData}
              />
            </TabPanel>
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <CircularProgress sx={{ color: "white" }} />
          </Box>
        )}
      </Box>

      <CustomAlert
        open={alert.show}
        closeMessage={() => setAlert((prev) => ({ ...prev, show: false }))}
        typeMessage={alert.type}
        message={alert.message}
      />
    </Box>
  );
}

export default LocationView;
