import React, { useState } from "react";
import Fab from "@mui/material/Fab";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PropTypes from "prop-types";

const FabMenu = ({
  className,
  fabIcon,
  fabColor,
  fabStyle,
  menuItems,
  label,
  onClick,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpenMenu = (event) => {
    if (menuItems) {
      setAnchorEl(event.currentTarget);
    } else if (onClick) {
      onClick();
    }
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <div className={`FabButton ${className}`}>
      <Fab color={fabColor} onClick={handleOpenMenu} style={fabStyle}>
        {fabIcon}
      </Fab>
      <div style={{ marginTop: 8, textAlign: "center" }}>{label}</div>

      {menuItems && (
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          {menuItems.map((item, index) => (
            <MenuItem
              key={index}
              onClick={() => {
                handleCloseMenu();
                item.action();
              }}
            >
              {item.label}
            </MenuItem>
          ))}
        </Menu>
      )}
    </div>
  );
};

FabMenu.propTypes = {
  fabIcon: PropTypes.node.isRequired,
  fabColor: PropTypes.string,
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      action: PropTypes.func,
    })
  ),
  label: PropTypes.string,
  position: PropTypes.shape({
    top: PropTypes.number,
    right: PropTypes.number,
    bottom: PropTypes.number,
    left: PropTypes.number,
  }),
  onClick: PropTypes.func, // New onClick prop for single action
};

export default FabMenu;
