import React, { useEffect, useState } from "react";
import {
  Grid,
  Paper,
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Autocomplete,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import CustomAlert from "../../Common/Components/CustomAlert";
import PropTypes from "prop-types";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

function TabEmplacement({
  emplacement,
  onSave,
  countries,
  provinces,
  regions,
}) {
  const { t } = useTranslation(["Location"]);
  const userState = useSelector((state) => state.user);
  const [employees, setEmployees] = useState([]);

  // Form state
  const [formData, setFormData] = useState({
    nameLocation: "",
    phoneNumber: "",
    email: "",
    address: "",
    city: "",
    zipCode: "",
    country: "",
    province: "",
    region: "",
    dateOuverture: null,
    dateFermeture: null,
    responsableCoach: null,
    directeurOps: null,
  });

  useEffect(() => {
    console.log(emplacement);
    if (emplacement) {
      setFormData({
        nameLocation: emplacement.nom || "",
        phoneNumber: emplacement.noTelPrincipal || "",
        email: emplacement.email || "",
        address: emplacement.adresseEmplacement?.adresse || "",
        city: emplacement.adresseEmplacement?.ville || "",
        zipCode: emplacement.adresseEmplacement?.codePostal || "",
        dateOuverture: emplacement.dateOuverture
          ? dayjs(emplacement.dateOuverture)
          : null,
        dateFermeture: emplacement.dateFermeture
          ? dayjs(emplacement.dateFermeture)
          : null,
        country:
          countries?.find((c) =>
            userState.lang === "FR"
              ? c.nomFr === emplacement.adresseEmplacement?.nomPaysFr
              : c.nomEn === emplacement.adresseEmplacement?.nomPaysEn
          ) || null,
        province:
          provinces?.find((p) =>
            userState.lang === "FR"
              ? p.nomFr === emplacement.adresseEmplacement?.nomProvinceFr
              : p.nomEn === emplacement.adresseEmplacement?.nomProvinceEn
          ) || null,
        region:
          regions?.find((r) =>
            userState.lang === "FR"
              ? r.nomFr === emplacement.adresseEmplacement?.nomRegionFr
              : r.nomEn === emplacement.adresseEmplacement?.nomRegionEn
          ) || null,
        responsableCoach: emplacement.responsableCoach || null,
        directeurOps: emplacement.directeurOps || null,
      });
    }
  }, [emplacement, countries, provinces, regions, userState.lang]);

  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/employee/GetInternalEmployees`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${userState.token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();

        // Check if data is null or undefined
        if (!data) {
          console.warn("Received null or undefined data from API");
          setEmployees([]);
          return;
        }

        // Ensure data is an array
        const employeeArray = Array.isArray(data) ? data : [];

        // Filter active employees and sort alphabetically
        const sortedEmployees = employeeArray.filter((emp) => {
          return emp && emp.estInactif === false;
        });

        setEmployees(sortedEmployees);
      } catch (error) {
        console.error("Error fetching employees:", error);
        setEmployees([]); // Set empty array on error
      }
    };

    fetchEmployees();
  }, [userState.token]);

  useEffect(() => {
    if (emplacement && Array.isArray(employees) && employees.length > 0) {
      const responsableFound = employees.find(
        (emp) => emp.noPersonne === emplacement.idEmployePrincipal
      );
      const directeurFound = employees.find(
        (emp) => emp.noPersonne === emplacement.idDirecteurOps
      );

      setFormData((prev) => {
        const newState = {
          ...prev,
          responsableCoach: responsableFound || null,
          directeurOps: directeurFound || null,
        };
        return newState;
      });
    }
  }, [emplacement, employees]);

  // Add this useEffect to monitor formData changes
  useEffect(() => {
    console.log("FormData updated:", formData);
  }, [formData]);

  const handleInputChange = (field) => (event) => {
    setFormData((prev) => ({
      ...prev,
      [field]: event.target.value,
    }));
  };

  const handleDateChange = (field) => (date) => {
    setFormData((prev) => ({
      ...prev,
      [field]: date,
    }));
  };

  const handleSave = async () => {
    const saveData = {
      // Required fields
      noEmplacement: emplacement?.noEmplacement,
      noAdresse: emplacement?.noAdresse,

      // Format dates only when saving
      dateOuverture: formData.dateOuverture
        ? formData.dateOuverture.format("DD-MM-YYYY")
        : null,
      dateFermeture: formData.dateFermeture
        ? formData.dateFermeture.format("DD-MM-YYYY")
        : null,

      // Location tab specific data
      nameLocation: formData.nameLocation,
      phoneNumber: formData.phoneNumber,
      email: formData.email,
      address: formData.address,
      city: formData.city,
      zipCode: formData.zipCode,
      region: formData.region,
      province: formData.province,
      country: formData.country,
      responsableCoach: formData.responsableCoach?.noPersonne || null,
      directeurOps: formData.directeurOps?.noPersonne || null,
    };

    // Pass 0 as the activeTab index for Location tab
    const result = await onSave(saveData, userState.token, 0);

    if (result.success) {
      // Handle successful save
      console.log("Location saved successfully");
    } else {
      // Handle error
      console.error("Error saving location:", result.message);
    }
  };

  return (
    <div>
      <Paper elevation={3} sx={{ marginRight: "5%", marginLeft: "5%" }}>
        <Box sx={{ padding: 5 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale="fr"
                >
                  <DatePicker
                    label={t("openDate")}
                    value={dayjs(formData.dateOuverture)}
                    onChange={(newValue) => {
                      setFormData((prev) => ({
                        ...prev,
                        dateOuverture: newValue,
                      }));
                    }}
                    format="DD/MM/YYYY"
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        error: false,
                      },
                    }}
                    disableMaskedInput
                    shouldDisableDate={() => false}
                  />
                </LocalizationProvider>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale="fr"
                >
                  <DatePicker
                    label={t("closureDate")}
                    value={dayjs(formData.dateFermeture)}
                    onChange={(newValue) => {
                      setFormData((prev) => ({
                        ...prev,
                        dateFermeture: newValue,
                      }));
                    }}
                    format="DD/MM/YYYY"
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        error: false,
                      },
                    }}
                    disableMaskedInput
                    shouldDisableDate={() => false}
                  />
                </LocalizationProvider>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                id="Nom"
                label={t("Lastname")}
                name="Nom"
                fullWidth
                size="medium"
                autoComplete="off"
                variant="outlined"
                value={formData.nameLocation}
                onChange={handleInputChange("nameLocation")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="NoTelephonePrincipal"
                label={t("PhoneNumber")}
                name="No Telephone Principal"
                fullWidth
                size="medium"
                autoComplete="off"
                variant="outlined"
                value={formData.phoneNumber}
                onChange={handleInputChange("phoneNumber")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="Courriel"
                name="Courriel"
                label={t("Email")}
                fullWidth
                size="medium"
                autoComplete="off"
                variant="outlined"
                value={formData.email}
                onChange={handleInputChange("email")}
              />
            </Grid>
          </Grid>
        </Box>
      </Paper>

      <Paper
        elevation={3}
        sx={{
          marginRight: "5%",
          marginLeft: "5%",
          marginTop: "2%",
          marginBottom: "2%",
        }}
      >
        <Box sx={{ padding: 5 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                id="responsableCoach"
                options={employees}
                getOptionLabel={(option) =>
                  option ? `${option.firstname} ${option.lastName}` : ""
                }
                value={formData.responsableCoach || null}
                onChange={(_, newValue) => {
                  setFormData((prev) => ({
                    ...prev,
                    responsableCoach: newValue,
                  }));
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("ResponsableCoach")}
                    fullWidth
                    size="medium"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                id="directeurOps"
                options={employees}
                getOptionLabel={(option) =>
                  option ? `${option.firstname} ${option.lastName}` : ""
                }
                value={formData.directeurOps || null}
                onChange={(_, newValue) => {
                  setFormData((prev) => ({
                    ...prev,
                    directeurOps: newValue,
                  }));
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("DirecteurOps")}
                    fullWidth
                    size="medium"
                  />
                )}
              />
            </Grid>
          </Grid>
        </Box>
      </Paper>

      <Paper
        elevation={3}
        sx={{ marginRight: "5%", marginLeft: "5%", marginTop: "2%" }}
      >
        <Box sx={{ padding: 5 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                id="Adresse"
                label={t("Adress")}
                name="Adresse"
                fullWidth
                size="medium"
                autoComplete="off"
                variant="outlined"
                value={formData.address}
                onChange={handleInputChange("address")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="Ville"
                label={t("City")}
                name="Ville"
                fullWidth
                size="medium"
                autoComplete="off"
                variant="outlined"
                value={formData.city}
                onChange={handleInputChange("city")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                value={formData.country || null}
                onChange={(_, newValue) => {
                  setFormData((prev) => ({
                    ...prev,
                    country: newValue,
                    province: null,
                    region: null,
                  }));
                }}
                options={countries ?? []}
                getOptionLabel={(option) =>
                  userState.lang === "FR"
                    ? option?.nomFr
                    : option?.nomEn ?? t("NoCountryName")
                }
                isOptionEqualToValue={(option, value) =>
                  option?.id === value?.id
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("Country")}
                    fullWidth
                    size="medium"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                value={formData.province || null}
                onChange={(_, newValue) => {
                  setFormData((prev) => ({
                    ...prev,
                    province: newValue,
                    region: null,
                  }));
                }}
                options={provinces ?? []}
                getOptionLabel={(option) =>
                  userState.lang === "FR"
                    ? option?.nomFr
                    : option?.nomEn ?? t("NoProvinceName")
                }
                isOptionEqualToValue={(option, value) =>
                  option?.id === value?.id
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("Province")}
                    fullWidth
                    size="medium"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                value={formData.region || null}
                onChange={(_, newValue) => {
                  setFormData((prev) => ({
                    ...prev,
                    region: newValue,
                  }));
                }}
                options={regions ?? []}
                getOptionLabel={(option) =>
                  userState.lang === "FR"
                    ? option?.nomFr
                    : option?.nomEn ?? t("NoRegionName")
                }
                isOptionEqualToValue={(option, value) =>
                  option?.id === value?.id
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("Region")}
                    fullWidth
                    size="medium"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="codePostal"
                name="codePostal"
                label={t("Zipcode")}
                fullWidth
                size="medium"
                autoComplete="off"
                variant="outlined"
                value={formData.zipCode}
                onChange={handleInputChange("zipCode")}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Button variant="contained" onClick={handleSave}>
                {t("GeneralSave")}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Paper>
      <CustomAlert
        open={alert.show}
        typeMessage={alert.type}
        message={alert.message}
      />
    </div>
  );
}

TabEmplacement.propTypes = {
  emplacement: PropTypes.object,
  countries: PropTypes.array.isRequired,
  provinces: PropTypes.array.isRequired,
  regions: PropTypes.array.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default TabEmplacement;
