import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  InputLabel,
  TextField,
  Typography,
  Paper,
  Divider,
  Button,
} from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useTranslation } from "react-i18next";
import { useForm, Controller, useWatch } from "react-hook-form";
import CreateShareholder from "./CreateShareholder";
import { useSelector } from "react-redux";

function CreateCompany() {
  const { t } = useTranslation(["Company"]);
  const { control, handleSubmit } = useForm({
    defaultValues: {
      NoAccpac: "",
      nom: "",
      dateOuverture: null,
      dateFermeture: null,
      NoMarchand: "",
      noMarchandDC: "",
      passwordDC: "",
      escomptePaiementDetailFr: "",
      nomEmplacement: "",
      accessCodeMEV: "",
      nomExploitant: "",
      PST: "",
      GST_HST: "",
      shareholders: [],
    },
  });

  const userState = useSelector((state) => state.user);

  // Add state for shareholders
  const [shareholders, setShareholders] = useState([]);

  // Watch for changes in the shareholders field
  const watchShareholders = useWatch({
    control,
    name: "shareholders",
  });

  // Update shareholders state when the form field changes
  useEffect(() => {
    if (watchShareholders) {
      setShareholders(watchShareholders);
    }
  }, [watchShareholders]);

  const onSubmit = async (formData) => {
    const dataCompany = {
      shareholders: shareholders,
      NoAccpac: formData.NoAccpac,
      nom: formData.nom,
      dateOuverture: formData.dateOuverture,
      NoMarchand: formData.NoMarchand,
      noMarchandDC: formData.noMarchandDC,
      passwordDC: formData.passwordDC,
      escomptePaiementDetailFr: formData.escomptePaiementDetailFr,
      nomEmplacement: formData.nomEmplacement,
      accessCodeMEV: formData.accessCodeMEV,
      nomExploitant: formData.nomExploitant,
      PST: formData.PST,
      GST_HST: formData.GST_HST,
    };

    handleNewCompany(dataCompany);
  };

  const handleNewCompany = async (dataCompany) => {
    var dataCompany = {
      shareholders: dataCompany.shareholders || [],
      NoAccpac: dataCompany.NoAccpac,
      nom: dataCompany.nom,
      dateOuverture: dataCompany.dateOuverture,
    };

    fetch(`${process.env.REACT_APP_API_URL}/company/CreateCompany`, {
      method: "POST",
      withCredentials: true,
      credentials: "include",

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userState.token}`,
      },
      body: JSON.stringify(dataCompany),
    }).then((results) => {
      return results.json();
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Paper elevation={0} sx={{ p: 3, mb: 3 }}>
        <Typography
          variant="h6"
          sx={{ mb: 3, color: "primary.main", fontWeight: "bold" }}
        >
          {t("GeneralInformation")}
        </Typography>
        <Divider sx={{ mb: 3 }} />

        <Box sx={{ px: 2 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={3}>
              <InputLabel
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  fontWeight: 700,
                }}
              >
                {t("StoreNumber")}
              </InputLabel>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Controller
                name="NoAccpac"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    size="small"
                    label={t("StoreNumber")}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <InputLabel
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  fontWeight: 700,
                }}
              >
                {t("CompanyName")}
              </InputLabel>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Controller
                name="nom"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    size="small"
                    label={t("CompanyName")}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <InputLabel
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  fontWeight: 700,
                }}
              >
                {t("OpenDate")}
              </InputLabel>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Controller
                name="dateOuverture"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label={t("OpenDate")}
                      value={value}
                      onChange={(newValue) => {
                        onChange(
                          newValue ? newValue.format("YYYY-MM-DD") : null
                        );
                      }}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          size: "small",
                        },
                      }}
                    />
                  </LocalizationProvider>
                )}
              />
            </Grid>
          </Grid>
        </Box>
      </Paper>

      <Paper elevation={0} sx={{ p: 3 }}>
        <Typography
          variant="h6"
          sx={{ mb: 3, color: "primary.main", fontWeight: "bold" }}
        >
          {t("Shareholders")}
        </Typography>
        <Divider sx={{ mb: 3 }} />

        <Box sx={{ px: 2 }}>
          <CreateShareholder
            control={control}
            name="shareholders"
            setShareholders={setShareholders}
          />
        </Box>
      </Paper>

      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          m: 2,
          px: 3,
        }}
      >
        <Button
          type="submit"
          variant="contained"
          color="primary"
          size="large"
          sx={{
            minWidth: 200,
            textTransform: "none",
            fontWeight: "bold",
          }}
        >
          {t("CreateCompany")}
        </Button>
      </Box>
    </form>
  );
}

export default CreateCompany;
