import React, { useEffect, useState } from "react";
import { DataGridPro, GridToolbar } from "@mui/x-data-grid-pro";
import { useSelector } from "react-redux";
import InternalUser from "./InternalUser";
import { Button, Box, Switch, FormControlLabel } from "@mui/material";
import AddInternalUser from "./AddInternalUser";
import CustomAlert from "../../Common/Components/CustomAlert";

function InternalUserManagementView() {
  const [internalEmployees, setInternalEmployees] = useState([]);
  const [rows, setRows] = useState([]);
  const [openEditUser, setOpenEditUser] = useState(false);
  const [openAddUser, setOpenAddUser] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [treeViewProvince, setTreeViewProvince] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [showInactive, setShowInactive] = useState(false);

  const userState = useSelector((state) => state.user);

  const columns = [
    { field: "id", headerName: "ID", width: 90 },
    { field: "username", headerName: "Username", width: 150 },
    { field: "firstname", headerName: "First name", width: 150 },
    { field: "lastName", headerName: "Lastname", width: 150 },
    { field: "email", headerName: "Email", width: 300 },
    { field: "noTelephone", headerName: "No Telephone", width: 150 },
    { field: "lang", headerName: "Lang", width: 200 },
    {
      field: "departmentDisplay",
      headerName: "Department",
      width: 150,
      editable: true,
    },
    { field: "title", headerName: "Title", width: 110 },
  ];

  const handleCloseEditUser = () => {
    setOpenEditUser(false);
  };

  const handleOpenAddUser = () => {
    setOpenAddUser(true);
  };

  const handleCloseAddUser = () => {
    setOpenAddUser(false);
  };

  const [alert, setAlert] = useState({
    open: false,
    type: "success",
    message: "",
  });

  const handleUpdateStatus = (status) => {
    setAlert({
      open: true,
      type: status.type,
      message: status.message,
    });

    // Reload internal employees data
    fetch(`${process.env.REACT_APP_API_URL}/Employee/GetInternalEmployees`, {
      method: "GET",
      withCredentials: true,
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userState.token}`,
      },
    })
      .then((results) => {
        if (!results.ok) {
          throw new Error(`HTTP error! status: ${results.status}`);
        }
        return results.json();
      })
      .then((data) => {
        setInternalEmployees(data);
      })
      .catch((error) => {
        console.error("Error fetching internal employees:", error);
      });
  };

  const handleCloseAlert = () => {
    setAlert((prev) => ({
      ...prev,
      open: false,
    }));
  };

  useEffect(() => {
    const GetInternalEmployees = async () => {
      fetch(`${process.env.REACT_APP_API_URL}/Employee/GetInternalEmployees`, {
        method: "GET",
        withCredentials: true,
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userState.token}`,
        },
      })
        .then((results) => {
          if (!results.ok) {
            throw new Error(`HTTP error! status: ${results.status}`);
          }
          return results.json();
        })
        .then((data) => setInternalEmployees(data))
        .catch((error) =>
          console.error("Error fetching internal employees:", error)
        );
    };
    const GetDepartments = async () => {
      fetch(
        `${process.env.REACT_APP_API_URL}/Localization/GetInternalDepartment`,
        {
          method: "GET",
          withCredentials: true,
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userState.token}`,
          },
        }
      )
        .then((results) => {
          if (!results.ok) {
            throw new Error(`HTTP error! status: ${results.status}`);
          }
          return results.json();
        })
        .then((data) => setDepartments(data));
    };

    GetInternalEmployees();
    GetDepartments();
  }, []);

  useEffect(() => {
    if (internalEmployees.length > 0) {
      Buildrows();
    }
  }, [userState.lang, internalEmployees, showInactive]);

  const handleRowClick = (params) => {
    setSelectedUser(params.row);
    setOpenEditUser(true);
  };

  const Buildrows = () => {
    let rows = [];
    internalEmployees.forEach((e) => {
      console.log(e);
      if (e.department?.estInterne) {
        if (showInactive || !e.estInactif) {
          rows.push({
            id: e.noEmploye,
            isInactive: e.estInactif,
            firstname: e.firstname,
            lastName: e.lastName,
            username: e.username,
            email: e.email,
            noTelephone: e.noCellulaire,
            nodooliz: e.posPwd,
            lang: e.language,
            department: e.department,
            departmentDisplay:
              userState.lang === "FR"
                ? e.department.textFr
                : e.department.textEn,
            locations: e.emplacements.map((emp) => emp.noEmplacement),
            title: e.title,
            action: "",
          });
        }
      }
    });
    setRows(rows);
  };

  const TreeProvince = (data) => {
    const tree = [];
    let ids = 0;
    console.log(data);
    data.forEach((item) => {
      // Find or create the country node
      let country = tree.find((p) => p.paysFr === item.paysFr);
      if (!country) {
        country = {
          id: "country" + item.noPays,
          paysFr: item.paysFr,
          paysEn: item.paysEn,
          children: [],
        };
        tree.push(country);
      }

      // Find or create the province node
      let province = country.children.find(
        (p) => p.provinceFr === item.provinceFr
      );
      if (!province) {
        province = {
          id: "province" + item.noProvince,
          parent: country.id,
          provinceFr: item.provinceFr,
          provinceEn: item.provinceEn,
          children: [],
        };
        country.children.push(province);
      }

      // Find or create the region node
      let region = province.children.find((r) => r.regionFr === item.regionFr);
      if (!region) {
        region = {
          id: "region" + item.noRegion,
          parent: province.id,
          regionFr: item.regionFr,
          regionEn: item.regionEn,
          children: [],
        };
        province.children.push(region);
      }

      // Find or create the emplacement node
      if (
        !region.children.some((e) => e.nomEmplacement === item.nomEmplacement)
      ) {
        region.children.push({
          id: item.noEmplacement,
          parent: region.id,
          nomEmplacement: item.nomEmplacement,
          noEmplacement: item.noEmplacement,
        });
      }
    });
    console.log(tree);
    setTreeViewProvince(tree);
    return tree;
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const provinceResponse = await fetch(
          `${process.env.REACT_APP_API_URL}/Localization/GetProvince`,
          {
            method: "GET",
            withCredentials: true,
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userState.token}`,
            },
          }
        );

        const provinceData = await provinceResponse.json();
        TreeProvince(provinceData);
      } catch (error) {
        console.error("Error fetching province data:", error);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "90%",
          margin: "auto",
          mb: 2,
        }}
      >
        <Button onClick={handleOpenAddUser}>Add User</Button>
        <FormControlLabel
          control={
            <Switch
              checked={showInactive}
              onChange={(e) => setShowInactive(e.target.checked)}
              color="primary"
            />
          }
          label="Show Inactive Employees"
        />
      </Box>
      <AddInternalUser
        open={openAddUser}
        onClose={handleCloseAddUser}
        locationTree={treeViewProvince}
        Departments={departments}
      />
      <InternalUser
        user={selectedUser}
        open={openEditUser}
        onClose={handleCloseEditUser}
        locationTree={treeViewProvince}
        Departments={departments}
        onUpdateStatus={handleUpdateStatus}
      />
      <div>
        <DataGridPro
          rows={rows}
          columns={columns}
          initialState={{
            filter: {
              filterModel: {
                items: [],
              },
            },
          }}
          slots={{
            toolbar: GridToolbar,
          }}
          pageSizeOptions={[5, 10, 50]}
          onRowClick={handleRowClick}
          sx={{
            height: "700px",
            width: "90%",
            margin: "auto",
          }}
        />
      </div>
      <CustomAlert
        open={alert.open}
        typeMessage={alert.type}
        message={alert.message}
        closeMessage={handleCloseAlert}
      />
    </>
  );
}

export default InternalUserManagementView;
