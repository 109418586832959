import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  Modal,
  Autocomplete,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: 800,
  width: 800,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function CreateLocationModal({
  open,
  handleClose,
  locationName,
  setLocationName,
  adresse,
  setAdresse,
  ville,
  setVille,
  codePostal,
  setCodePostal,
}) {
  const { t } = useTranslation(["Location"]);
  const userState = useSelector((state) => state.user);

  // Local state for provinces and regions
  const [countries, setCountries] = useState([]);
  const [country, setCountry] = useState(null);
  const [province, setProvince] = useState(null);
  const [region, setRegion] = useState(null);
  const [availableProvinces, setAvailableProvinces] = useState([]);
  const [availableRegions, setAvailableRegions] = useState([]);

  // Fetch provinces when country changes
  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/Localization/GetCountries`,
          {
            headers: {
              Authorization: `Bearer ${userState.token}`,
            },
          }
        );
        if (!response.ok) throw new Error("Failed to fetch countries");
        const data = await response.json();
        setCountries(data);
        // Reset province and region when country changes
        setProvince(null);
        setRegion(null);
      } catch (error) {
        console.error("Error fetching countries:", error);
        setCountries([]);
      }
    };

    fetchCountries();
  }, []);

  useEffect(() => {
    const fetchProvinces = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/Localization/GetProvinceByCountry/${country.noPays}`,
          {
            headers: {
              Authorization: `Bearer ${userState.token}`,
            },
          }
        );
        if (!response.ok) throw new Error("Failed to fetch provinces");
        const data = await response.json();
        setAvailableProvinces(data);
        // Reset province and region when country changes
        setProvince(null);
        setRegion(null);
      } catch (error) {
        console.error("Error fetching provinces:", error);
        setAvailableProvinces([]);
      }
    };
    if (country) {
      fetchProvinces();
    }
  }, [country]);

  // Fetch regions when province changes
  useEffect(() => {
    const fetchRegions = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/Localization/GetRegionsByProvinces/${province.noProvince}`,
          {
            headers: {
              Authorization: `Bearer ${userState.token}`,
            },
          }
        );
        if (!response.ok) throw new Error("Failed to fetch regions");
        const data = await response.json();
        setAvailableRegions(data);
        // Reset region when province changes
        setRegion(null);
      } catch (error) {
        console.error("Error fetching regions:", error);
        setAvailableRegions([]);
      }
    };
    if (province) {
      fetchRegions();
    }
  }, [province]);

  const handleCreateLocation = async () => {
    var dataNewLocation = {
      Nom: locationName,
      city: ville,
      Adresse: adresse,
      PostalCode: codePostal,
      Region: region,
      Province: province,
      Country: country,
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/Location/CreateLocation`,
        {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userState.token}`,
          },
          body: JSON.stringify(dataNewLocation),
        }
      );

      if (!response.ok) throw new Error("Failed to create location");

      const result = await response.json();
      handleClose();
      return result;
    } catch (error) {
      console.error("Error creating location:", error);
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} className="Emplacement__Modal">
        <Typography variant="h5" sx={{ mb: 3 }}>
          {t("CreateLocationTitle")}
        </Typography>
        <Grid container spacing={4} className="Emplacement__Modal__grid">
          <Grid item xs={12} sm={4}>
            <TextField
              id="Nom"
              name="Nom"
              label={t("LocationName")}
              fullWidth
              size="small"
              autoComplete="off"
              variant="outlined"
              value={locationName}
              onChange={(e) => setLocationName(e.currentTarget.value)}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              id="adresse"
              name="adresse"
              label={t("Adress")}
              fullWidth
              size="small"
              autoComplete="off"
              variant="outlined"
              value={adresse}
              onChange={(e) => setAdresse(e.currentTarget.value)}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              id="city"
              name="city"
              label={t("City")}
              fullWidth
              size="small"
              autoComplete="off"
              variant="outlined"
              value={ville}
              onChange={(e) => setVille(e.currentTarget.value)}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField
              id="zipcode"
              name="zipcode"
              label={t("Zipcode")}
              fullWidth
              size="small"
              autoComplete="off"
              variant="outlined"
              value={codePostal}
              onChange={(e) => setCodePostal(e.currentTarget.value)}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Autocomplete
              value={country}
              onChange={(_, newValue) => setCountry(newValue)}
              options={countries ?? []}
              getOptionLabel={(option) => option?.nomFr ?? ""}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("Country")}
                  size="small"
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Autocomplete
              value={province}
              onChange={(_, newValue) => setProvince(newValue)}
              options={availableProvinces}
              getOptionLabel={(option) => option?.nomFr ?? ""}
              disabled={!country}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("Province")}
                  size="small"
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Autocomplete
              value={region}
              onChange={(_, newValue) => setRegion(newValue)}
              options={availableRegions}
              getOptionLabel={(option) => option?.nomFr ?? ""}
              disabled={!province}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("Region")}
                  size="small"
                  fullWidth
                />
              )}
            />
          </Grid>
        </Grid>
        <Box sx={{ mt: 4, display: "flex", justifyContent: "flex-end" }}>
          <Button
            variant="contained"
            onClick={handleCreateLocation}
            sx={{ minWidth: 120 }}
          >
            {t("Create")}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}

export default CreateLocationModal;
