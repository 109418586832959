import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormControlLabel,
  Switch,
  MenuItem,
  Tabs,
  Tab,
  Box,
  Autocomplete,
} from "@mui/material";
import CustomTreeView from "../../Common/Components/CustomTreeView";
import { useSelector } from "react-redux";
import GroupsManagement from "./Tabs/GroupsManagement";

function TabPanel({ children, value, index }) {
  return (
    <div hidden={value !== index} style={{ paddingTop: "20px" }}>
      {value === index && children}
    </div>
  );
}

function InternalUser({
  user,
  open,
  onClose,
  onSave,
  locationTree,
  Departments,
  onUpdateStatus,
}) {
  const [activeTab, setActiveTab] = useState(0);
  const [indeterminateCheckbox, setIndeterminateCheckbox] = useState([]);
  const [userData, setUserData] = useState({
    isInactive: user?.isInactive || false,
    firstname: user?.firstname || "",
    lastName: user?.lastName || "",
    username: user?.username || "",
    email: user?.email || "",
    noTelephone: user?.noTelephone || "",
    posPwd: user?.posPwd || "",
    lang: user?.lang || "",
    department: user?.department || "",
    title: user?.title || "",
    selectedLocations: user?.locations || [],
  });
  const userState = useSelector((state) => state.user);

  const computeNodeStates = (tree, selectedLocations) => {
    const checkedNodes = new Set(selectedLocations);
    const indeterminateNodes = new Set();

    const traverse = (node) => {
      if (!node.children || node.children.length === 0) {
        // Leaf node: it is checked if in selectedLocations
        return checkedNodes.has(node.id);
      }

      // Non-leaf node: check children
      let allChildrenChecked = true;
      let hasCheckedChild = false;
      let hasUncheckedChild = false;

      node.children.forEach((child) => {
        const isChildChecked = traverse(child);
        if (isChildChecked) {
          hasCheckedChild = true;
        } else {
          hasUncheckedChild = true;
          allChildrenChecked = false;
        }
      });

      // Mark the node as checked or indeterminate based on its children
      if (allChildrenChecked) {
        checkedNodes.add(node.id);
      } else if (hasCheckedChild && hasUncheckedChild) {
        indeterminateNodes.add(node.id);
      }

      // Return true if the node is fully checked, false otherwise
      return allChildrenChecked;
    };

    const propagateIndeterminate = (node) => {
      if (!node.children || node.children.length === 0) {
        return;
      }

      node.children.forEach((child) => {
        if (indeterminateNodes.has(child.id)) {
          indeterminateNodes.add(node.id);
        }
        propagateIndeterminate(child);
      });
    };

    tree.forEach((node) => traverse(node));
    tree.forEach((node) => propagateIndeterminate(node));

    return {
      checkedNodes: Array.from(checkedNodes),
      indeterminateNodes: Array.from(indeterminateNodes),
    };
  };

  useEffect(() => {
    if (user && locationTree) {
      // Compute checked and indeterminate states
      const { checkedNodes, indeterminateNodes } = computeNodeStates(
        locationTree,
        user.locations || []
      );

      setUserData({
        ...user,
        selectedLocations: user.locations || [],
      });

      setIndeterminateCheckbox([...indeterminateNodes]);
    }
  }, [user, locationTree]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSwitchChange = (e) => {
    setUserData((prev) => ({
      ...prev,
      isInactive: e.target.checked,
    }));
  };

  const handleSubmit = async () => {
    try {
      // Filter only leaf nodes from selectedLocations
      const leafLocations = userData.selectedLocations.filter((nodeId) => {
        const node = bfsSearch(locationTree, nodeId);
        return !node.children || node.children.length === 0;
      });

      // Get noEmplacement values from the nodes
      const validLocations = [
        ...new Set([...leafLocations, ...userData.selectedLocations]),
      ] // Remove duplicates from combined array
        .map((nodeId) => {
          const node = bfsSearch(locationTree, nodeId);
          return node ? parseInt(node.noEmplacement) : null;
        })
        .filter(
          (noEmplacement) => noEmplacement !== null && !isNaN(noEmplacement)
        );

      // Additional deduplication of final noEmplacement values
      const uniqueLocations = [...new Set(validLocations)];

      const payload = {
        id: user.id,
        EstInactif: userData.isInactive || false,
        firstname: userData.firstname,
        lastName: userData.lastName,
        Username: userData.username,
        email: userData.email,
        noCellulaire: userData.noTelephone,
        posPwd: userData.nodooliz,
        language: userData.lang,
        department: userData.department,
        title: userData.title,
        IdEmplacements: uniqueLocations,
      };

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/Employee/UpdateInternalEmploye/${user.id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userState.token}`,
          },
          credentials: "include",
          body: JSON.stringify(payload),
        }
      );

      const data = await response.text();

      if (!response.ok && response.status !== 204) {
        const errorMessage =
          data.message || "Failed to update user. Please try again.";
        onUpdateStatus({
          type: "error",
          message: errorMessage,
        });
        throw new Error(errorMessage);
      }

      onUpdateStatus({
        type: "success",
        message: "User updated successfully",
      });
      onClose();
    } catch (error) {
      console.error("Error updating internal user:", error);
      onUpdateStatus({
        type: "error",
        message: error.message,
      });
    }
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleNodeSelectV2 = (event, nodeId) => {
    event.stopPropagation();

    const allChild = getAllChild(nodeId); // Get all child nodes
    const fathers = getAllFathers(nodeId); // Get all parent nodes

    setUserData((prev) => {
      const isSelected = prev.selectedLocations.includes(nodeId);
      let updatedLocations;

      if (isSelected) {
        // If node is already selected, deselect it along with its children and parents
        updatedLocations = prev.selectedLocations.filter(
          (id) => ![nodeId, ...allChild, ...fathers].includes(id)
        );
      } else {
        // If node is not selected, select it along with its children and parents
        updatedLocations = [
          ...new Set([
            ...prev.selectedLocations,
            nodeId,
            ...allChild,
            ...fathers,
          ]),
        ];
      }

      console.log("Updated Locations:", updatedLocations);

      return {
        ...prev,
        selectedLocations: updatedLocations,
      };
    });

    // Update indeterminate states for parent nodes
    updateIndeterminateCheckbox(fathers, nodeId);
  };

  const updateIndeterminateCheckbox = (fathers, nodeId) => {
    setIndeterminateCheckbox((prev) => {
      const newIndeterminate = [...prev];

      fathers.forEach((fatherId) => {
        const fatherNode = bfsSearch(locationTree, fatherId);

        if (isAllChildrenChecked(fatherNode, userData.selectedLocations)) {
          // Remove from indeterminate if all children are checked
          const index = newIndeterminate.indexOf(fatherId);
          if (index > -1) newIndeterminate.splice(index, 1);
        } else if (
          isAtLeastOneChildIsChecked(fatherNode, userData.selectedLocations)
        ) {
          // Add to indeterminate if at least one child is checked
          if (!newIndeterminate.includes(fatherId)) {
            newIndeterminate.push(fatherId);
          }
        }
      });

      return newIndeterminate;
    });
  };

  const bfsSearch = (graph, targetId) => {
    const queue = [...graph];

    while (queue.length > 0) {
      const currNode = queue.shift();
      if (currNode.id === targetId) {
        return currNode;
      }
      if (currNode.children) {
        queue.push(...currNode.children);
      }
    }
    return []; // Target node not found
  };

  const getAllChild = (id) => {
    return getAllIds(bfsSearch(locationTree, id));
  };

  const getAllFathers = (id, list = []) => {
    const node = bfsSearch(locationTree, id);
    list.push(node.parent);
    if (node.parent) {
      return getAllFathers(node.parent, list);
    }
    return list;
  };

  // Utility to check if all children of a node are selected
  function isAllChildrenChecked(node, selectedLocations) {
    const allChildIds = getAllChild(node.id);
    return allChildIds.every((id) => selectedLocations.includes(id));
  }

  // Utility to check if at least one child of a node is selected
  function isAtLeastOneChildIsChecked(node, selectedLocations) {
    const allChildIds = getAllChild(node.id);
    return allChildIds.some((id) => selectedLocations.includes(id));
  }

  function getAllIds(node, idList = []) {
    idList.push(node.id);
    if (node.children) {
      node.children.forEach((child) => getAllIds(child, idList));
    }
    return idList;
  }

  const handleExpandClick = (event, nodeIds) => {
    // Handle expand/collapse if needed
  };
  console.log(userData);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Edit User</DialogTitle>
      <DialogContent>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={activeTab} onChange={handleTabChange}>
            <Tab label="General Information" />
            <Tab label="Location" />
            <Tab label="Groups" />
          </Tabs>
        </Box>

        <TabPanel value={activeTab} index={0}>
          <FormControlLabel
            control={
              <Switch
                checked={userData.isInactive}
                onChange={handleSwitchChange}
                name="isInactive"
              />
            }
            label="Inactive"
            sx={{ mb: 2, mt: 1 }}
          />

          <TextField
            fullWidth
            label="First Name"
            name="firstname"
            value={userData.firstname}
            onChange={handleChange}
            margin="dense"
          />

          <TextField
            fullWidth
            label="Last Name"
            name="lastName"
            value={userData.lastName}
            onChange={handleChange}
            margin="dense"
          />

          <TextField
            fullWidth
            label="Username"
            name="username"
            value={userData.username}
            onChange={handleChange}
            margin="dense"
          />

          <TextField
            fullWidth
            label="Email"
            name="email"
            type="email"
            value={userData.email}
            onChange={handleChange}
            margin="dense"
          />

          <TextField
            fullWidth
            label="No Telephone"
            name="noTelephone"
            value={userData.noTelephone}
            onChange={handleChange}
            margin="dense"
          />

          <TextField
            fullWidth
            label="Nodooliz"
            name="nodooliz"
            value={userData.nodooliz || ""}
            onChange={handleChange}
            margin="dense"
          />

          <TextField
            fullWidth
            select
            label="Language"
            name="lang"
            value={userData.lang}
            onChange={handleChange}
            margin="dense"
          >
            <MenuItem value="EN">English</MenuItem>
            <MenuItem value="FR">French</MenuItem>
          </TextField>

          <Autocomplete
            fullWidth
            options={Departments || []}
            getOptionLabel={(option) => {
              if (!option) return "";
              return typeof option === "string"
                ? option
                : option[userData.lang === "FR" ? "textFr" : "textEn"] || "";
            }}
            value={
              Departments.find(
                (d) =>
                  d.textFr === userData.department?.textFr ||
                  d.textEn === userData.department?.textEn
              ) || null
            }
            onChange={(event, newValue) => {
              setUserData((prev) => ({
                ...prev,
                department: newValue, // Store the entire department object
              }));
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Department"
                margin="dense"
                fullWidth
                required
              />
            )}
            isOptionEqualToValue={(option, value) => {
              if (!option || !value) return false;
              return (
                option.id === value.id ||
                option.textFr === value.textFr ||
                option.textEn === value.textEn
              );
            }}
          />

          <TextField
            fullWidth
            label="Title"
            name="title"
            value={userData.title}
            onChange={handleChange}
            margin="dense"
          />
        </TabPanel>

        <TabPanel value={activeTab} index={1}>
          <Box sx={{ p: 2 }}>
            <CustomTreeView
              TreeViewProvince={locationTree || []}
              selectedNodes={userData.selectedLocations || []}
              handleNodeSelect={handleNodeSelectV2}
              handleExpandClick={handleExpandClick}
              indeterminateCheckbox={indeterminateCheckbox}
              readOnly={false}
            />
          </Box>
        </TabPanel>
        <TabPanel value={activeTab} index={2}>
          <Box sx={{ p: 2 }}>
            <GroupsManagement employee={user} />
          </Box>
        </TabPanel>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit} variant="contained" color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default InternalUser;
