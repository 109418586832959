import { DataGridPro } from "@mui/x-data-grid-pro";
import {
  Box,
  Button,
  Typography,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

// ... rest of your imports

const GroupsManagement = ({ employee }) => {
  const [groups, setGroups] = useState([]);
  const [allGroups, setAllGroups] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const { t } = useTranslation(["Employee", "Common"]);
  const userState = useSelector((state) => state.user);

  const fetchUserGroups = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/Employee/GetGroupsByEmploye/${employee.id}`,
        {
          headers: {
            Authorization: `Bearer ${userState.token}`,
          },
        }
      );
      const data = await response.json();
      setGroups(data);
      console.log(data);
    } catch (error) {
      console.error("Error fetching user groups:", error);
    }
  };

  const fetchAllGroups = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/Employee/GetGroups`,
        {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userState.token}`,
          },
        }
      );
      const data = await response.json();
      setAllGroups(data);
    } catch (error) {
      console.error("Error fetching all groups:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (employee) {
      fetchUserGroups();
      fetchAllGroups();
    }
  }, [employee]);

  const handleRemoveGroup = async (groupId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/Employee/RemoveUserFromGroup`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userState.token}`,
          },
          body: JSON.stringify({
            idGroupe: groupId,
            noEmploye: employee.id,
            users: [{ Noemploye: employee.id, GroupeId: groupId }],
          }),
        }
      );

      if (response.ok) {
        fetchUserGroups();
      }
    } catch (error) {
      console.error("Error removing group:", error);
    }
  };

  const handleAddGroups = async () => {
    try {
      console.log(employee);
      // Use Promise.all to handle multiple requests concurrently
      await Promise.all(
        selectedGroups.map((groupId) =>
          fetch(`${process.env.REACT_APP_API_URL}/Employee/AddUserToGroup`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userState.token}`,
            },
            body: JSON.stringify({
              idGroupe: groupId,
              noEmploye: employee.id,
              users: [{ Noemploye: employee.id, GroupeId: groupId }],
            }),
          })
        )
      );

      fetchUserGroups();
      setOpenDialog(false);
      setSelectedGroups([]); // Reset selection
    } catch (error) {
      console.error("Error adding groups:", error);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedGroups([]); // Reset selection when closing
  };

  const columns = [
    { field: "nom", headerName: t("GroupName"), flex: 1 },
    { field: "descriptionFr", headerName: t("Description"), flex: 2 },
    {
      field: "actions",
      headerName: t("Actions"),
      width: 100,
      renderCell: (params) => (
        <Chip
          label={t("Remove")}
          onDelete={() => handleRemoveGroup(params.row.id)}
          color="error"
          size="small"
        />
      ),
    },
  ];

  const availableGroupsColumns = [
    { field: "nom", headerName: t("GroupName"), flex: 1 },
    { field: "descriptionFr", headerName: t("Description"), flex: 2 },
  ];

  return (
    <Box sx={{ p: 2 }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
        <Typography variant="h6">{t("UserGroups")}</Typography>
        <Button
          startIcon={<AddIcon />}
          variant="contained"
          onClick={() => setOpenDialog(true)}
        >
          {t("AddGroups")}
        </Button>
      </Box>

      <DataGridPro
        rows={groups}
        columns={columns}
        loading={loading}
        autoHeight
        disableRowSelectionOnClick
        initialState={{
          pagination: { paginationModel: { pageSize: 5 } },
        }}
        pageSizeOptions={[5, 10, 15]}
      />

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>{t("AddGroupsToUser")}</DialogTitle>
        <DialogContent>
          <DataGridPro
            rows={allGroups.filter(
              (group) => !groups.some((g) => g.id === group.id)
            )}
            columns={availableGroupsColumns}
            autoHeight
            checkboxSelection
            onRowSelectionModelChange={(newSelection) => {
              setSelectedGroups(newSelection);
            }}
            rowSelectionModel={selectedGroups}
            initialState={{
              pagination: { paginationModel: { pageSize: 5 } },
            }}
            pageSizeOptions={[5, 10, 15]}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>{t("Cancel")}</Button>
          <Button
            variant="contained"
            onClick={handleAddGroups}
            disabled={selectedGroups.length === 0}
          >
            {t("AddSelected", { count: selectedGroups.length })}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default GroupsManagement;
