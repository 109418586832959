import * as React from "react";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Modal,
  Tabs,
  Tab,
  Autocomplete,
  TextField,
  Paper,
  Typography,
  Container,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import GroupIcon from "@mui/icons-material/Group";
import PersonIcon from "@mui/icons-material/Person";
import AddNewRight from "./AddNewRight";
import GroupManagement from "./Tabs/GroupManagement";
import GroupsUserManagement from "./Tabs/GroupsUserManagement";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`access-right-tabpanel-${index}`}
      aria-labelledby={`access-right-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export function AccessRightManagement() {
  const [group, setGroup] = useState(null);
  const [open, setOpen] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [groupList, setGroupList] = useState([]);

  const { t } = useTranslation(["AccessRights", "Common"]);
  const userState = useSelector((state) => state.user);

  const handleTabChange = (event, newValue) => setTabValue(newValue);
  const handleModalOpen = () => setOpen(true);
  const handleModalClose = () => setOpen(false);

  const GetGroup = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/Employee/GetGroups`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userState.token}`,
        },
      }
    );
    const data = await response.json();
    setGroupList(data);
  };

  useEffect(() => {
    GetGroup();
  }, []);

  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
        <Typography variant="h5" component="h1" sx={{ mb: 3 }}>
          {t("AccessRightManagement")}
        </Typography>

        <Box sx={{ display: "flex", gap: 2, mb: 4 }}>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={handleModalOpen}
            sx={{ height: "fit-content" }}
          >
            {t("NewGroup")}
          </Button>
          {tabValue === 1 && (
            <Autocomplete
              id="access-right-autocomplete"
              options={groupList}
              getOptionLabel={(option) => option.nom || ""}
              onChange={(_, newValue) => setGroup(newValue)}
              value={group}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("SelectGroup")}
                  sx={{ minWidth: 300 }}
                />
              )}
            />
          )}
        </Box>

        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="access right tabs"
            sx={{
              "& .MuiTab-root": {
                textTransform: "none",
                fontSize: "1rem",
              },
            }}
          >
            <Tab
              icon={<GroupIcon />}
              iconPosition="start"
              label={t("GroupsManagement")}
            />
            <Tab
              icon={<PersonIcon />}
              iconPosition="start"
              label={t("GroupsUserManagement")}
            />
          </Tabs>
        </Box>

        <CustomTabPanel value={tabValue} index={0}>
          <GroupManagement group={group} />
        </CustomTabPanel>
        <CustomTabPanel value={tabValue} index={1}>
          <GroupsUserManagement group={group} />
        </CustomTabPanel>
      </Paper>

      <Modal
        open={open}
        onClose={handleModalClose}
        aria-labelledby="add-access-right-modal"
      >
        <Paper sx={modalStyle}>
          <AddNewRight onClose={handleModalClose} />
        </Paper>
      </Modal>
    </Container>
  );
}

export default AccessRightManagement;
