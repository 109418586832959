import React, { useRef, useState } from "react";
import { ResponsiveRadialBar } from "@nivo/radial-bar";
import {
  Box,
  Typography,
  CircularProgress,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import { useTranslation } from "react-i18next";

const CustomLabel = ({ center, bars, label }) => {
  const ref = useRef(null);
  const value = bars[0]?.value || 0;

  return (
    <g>
      {/* Background for better visibility */}
      <circle cx={center[0]} cy={center[1]} r={60} fill="white" opacity={0.8} />
      {/* Value */}
      <text
        x={center[0]}
        y={center[1] - 10}
        textAnchor="middle"
        dominantBaseline="middle"
        style={{
          fontSize: "28px",
          fontWeight: "bold",
          fill: "#333",
        }}
      >
        {value.toFixed(1)}%
      </text>
      {/* Label */}
      <text
        x={center[0]}
        y={center[1] + 20}
        textAnchor="middle"
        dominantBaseline="middle"
        style={{
          fontSize: "16px",
          fill: "#666",
        }}
      >
        {label}
      </text>
    </g>
  );
};

const GoalMarker = ({ center, radius, angle, color = "#9333EA" }) => {
  // Convert angle from percentage (0-100) to radians for a 180-degree range (-180 to 0)
  const angleInRadians = ((angle / 100) * 180 - 180) * (Math.PI / 180);

  // Calculate start point at half radius
  const x1 = center[0] + radius * 0.3 * Math.cos(angleInRadians);
  const y1 = center[1] + radius * 0.3 * Math.sin(angleInRadians);

  // Calculate end point at outer radius
  const x2 = center[0] + radius * 1.1 * Math.cos(angleInRadians);
  const y2 = center[1] + radius * 1.1 * Math.sin(angleInRadians);

  return (
    <g>
      {/* Line */}
      <line x1={x1} y1={y1} x2={x2} y2={y2} stroke={color} strokeWidth={4} />
      {/* Dot at the end */}
      <circle cx={x2} cy={y2} r={5} fill={color} />
      {/* Target label */}
      <text
        x={x2 + 10}
        y={y2}
        fill={color}
        dominantBaseline="middle"
        fontSize="12px"
        fontWeight="bold"
      >
        Target {angle}%
      </text>
    </g>
  );
};

function GaugeChart({ data: rawData, loading, type = "labor", goal = 30 }) {
  const { t } = useTranslation(["Dashboard"]);
  const [selectedPeriod, setSelectedPeriod] = useState("latest");

  // Process periods for the filter
  const periods = rawData
    ? [...new Set(rawData.map((item) => item.period))].sort().reverse()
    : [];

  // Get unique years for year filter
  const years = [...new Set(periods.map((period) => period.split("-")[0]))];

  const getGaugeData = () => {
    // Get value based on selected period
    const getValue = () => {
      if (!rawData || !rawData.length) return 0;

      if (selectedPeriod === "latest") {
        return rawData[0]?.[type === "labor" ? "laborCost" : "foodCost"] || 0;
      }

      const selectedData = rawData.find(
        (item) => item.period === selectedPeriod
      );
      return selectedData?.[type === "labor" ? "laborCost" : "foodCost"] || 0;
    };

    const value = getValue();

    return [
      {
        id: "Current",
        data: [
          {
            x: "value",
            y: Number(value.toFixed(3)),
            color: "#7bdff2",
            tip: type === "labor" ? t("LaborCost") : t("FoodCost"),
            label: type === "labor" ? t("LaborCost") : t("FoodCost"),
            displayLabel: Number(value.toFixed(3)) + "%",
          },
        ],
      },
      {
        id: "Gauge",
        data: [
          {
            x: "good",
            y: 25,
            color: "#aaf683",
            tip: t("Good"),
            label: t("Good"),
            range: "0-25%",
            displayLabel: t("Good"),
          },
          {
            x: "normal",
            y: 25,
            color: "#ffd97d",
            tip: t("Normal"),
            label: t("Normal"),
            range: "25-50%",
            displayLabel: t("Normal"),
          },
          {
            x: "warning",
            y: 25,
            color: "#ff9b85",
            tip: t("Warning"),
            label: t("Warning"),
            range: "50-75%",
            displayLabel: t("Warning"),
          },
          {
            x: "danger",
            y: 25,
            color: "#ee6055",
            tip: t("High"),
            label: t("High"),
            range: "75-100%",
            displayLabel: t("Danger"),
          },
        ],
      },
    ];
  };

  return (
    <Box sx={{ width: "100%", height: "100%", minHeight: 300 }}>
      {/* Add period filter */}
      <Box sx={{ mb: 2 }}>
        <FormControl size="small" fullWidth>
          <Select
            value={selectedPeriod}
            onChange={(e) => setSelectedPeriod(e.target.value)}
            sx={{ minWidth: 120 }}
          >
            <MenuItem value="latest">Latest</MenuItem>
            {years.map((year) => [
              <MenuItem
                key={`year-${year}`}
                sx={{ fontWeight: "bold", backgroundColor: "#f5f5f5" }}
                disabled
              >
                {year}
              </MenuItem>,
              periods
                .filter((period) => period.startsWith(year))
                .map((period) => (
                  <MenuItem key={period} value={period} sx={{ pl: 4 }}>
                    {`Period ${period.split("-P")[1]}`}
                  </MenuItem>
                )),
            ])}
          </Select>
        </FormControl>
      </Box>

      <Typography
        variant="h6"
        align="center"
        gutterBottom
        sx={{
          fontWeight: "bold",
          color: "#333",
          mb: 2,
        }}
      >
        {type === "labor" ? t("LaborCostGauge") : t("FoodCostGauge")}
      </Typography>

      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box height="calc(100% - 40px)" position="relative">
          <ResponsiveRadialBar
            data={getGaugeData()}
            startAngle={-90}
            endAngle={90}
            label={(d) => d.data.displayLabel}
            padding={0.4}
            margin={{ top: 60, right: 120, bottom: -140, left: 120 }}
            circularAxisOuter={null}
            enableTracks={false}
            colors={(d) => d.data.color}
            maxValue={100}
            enableRadialGrid={false}
            enableCircularGrid={false}
            radialAxisStart={null}
            cornerRadius={4}
            enableLabels={true}
            labelsSkipAngle={0}
            labelsRadiusOffset={0.85}
            labelsTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
            arcLabelsSkipAngle={10}
            arcLabelsTextColor={{ from: "color", modifiers: [["darker", 2]] }}
            arcLinkLabelsSkipAngle={10}
            arcLinkLabelsTextColor="#333333"
            arcLinkLabelsThickness={2}
            arcLinkLabelsColor={{ from: "color" }}
            enableArcLabels={true}
            enableArcLinkLabels={false}
            arcLinkLabel={(d) => d.data.label}
            arcLabel={(d) => d.data.displayLabel}
            arcLabelComponent={({ datum, label, style }) => (
              <g transform={`translate(${style.x},${style.y})`}>
                <text
                  textAnchor="middle"
                  dominantBaseline="middle"
                  style={{
                    fontSize: "14px",
                    fontWeight: "bold",
                    fill: "#000000",
                  }}
                >
                  {label}
                </text>
              </g>
            )}
            innerRadius={0.2}
            padAngle={0.5}
            arcLabelsRadiusOffset={0.7}
            motionConfig={{
              mass: 1,
              tension: 170,
              friction: 26,
              clamp: false,
              precision: 0.01,
              velocity: 0,
            }}
            onMouseEnter={(data, event) => {
              event.target.style.transform = "scale(1.1)";
              event.target.style.transition = "transform 200ms";
              event.target.style.cursor = "pointer";
            }}
            onMouseLeave={(data, event) => {
              event.target.style.transform = "scale(1)";
              event.target.style.transition = "transform 200ms";
            }}
            tooltip={({ bar }) => (
              <Box
                sx={{
                  bgcolor: "background.paper",
                  p: 1.5,
                  boxShadow: 2,
                  borderRadius: 1,
                  fontSize: "0.875rem",
                  fontWeight: "medium",
                }}
              >
                <Typography sx={{ color: bar.data.color, fontWeight: "bold" }}>
                  {bar.data.label}
                </Typography>
                <Typography>{bar.data.range}</Typography>
              </Box>
            )}
            legends={[
              {
                anchor: "right",
                direction: "column",
                justify: false,
                translateX: 120,
                translateY: 0,
                itemsSpacing: 6,
                itemWidth: 100,
                itemHeight: 18,
                itemTextColor: "#999",
                itemDirection: "left-to-right",
                itemOpacity: 1,
                symbolSize: 18,
                symbolShape: "circle",
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemTextColor: "#000",
                    },
                  },
                ],
              },
            ]}
            layers={[
              "grid",
              "tracks",
              "bars",
              "labels",
              "legends",
              (props) => (
                <CustomLabel
                  {...props}
                  label={type === "labor" ? t("LaborCost") : t("FoodCost")}
                />
              ),
              (layerProps) => {
                const { center, outerRadius } = layerProps;

                return (
                  <GoalMarker
                    center={center}
                    radius={outerRadius}
                    angle={goal}
                    color="#9333EA"
                  />
                );
              },
            ]}
          />
        </Box>
      )}
    </Box>
  );
}

export default GaugeChart;
