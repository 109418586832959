import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
  Tabs,
  Tab,
  Typography,
  List,
  ListItem,
  ListItemText,
  Paper,
  Button,
} from "@mui/material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useSelector } from "react-redux";

function EditOrderRecipe({ open, onClose, categories = [], isNew }) {
  const [tabIndex, setTabIndex] = useState(0);
  const [orderedCategories, setOrderedCategories] = useState([]);
  const [recipesByCategory, setRecipesByCategory] = useState({});
  const [recipes, setRecipes] = useState([]);

  const userState = useSelector((state) => state.user);

  useEffect(() => {
    const fetchRecipes = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/Recipe/GetRecipes`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userState.token}`,
            },
            credentials: "include",
          }
        );
        if (!response.ok) throw new Error("Failed to fetch recipe");
        const data = await response.json();
        setRecipes(data.map((recipe) => recipe.recette));
      } catch (error) {
        console.error("Error fetching Recipe:", error);
      }
    };
    fetchRecipes();
  }, []);

  useEffect(() => {
    if (!categories || !recipes) return;

    const sortedCategories = [...categories].sort((a, b) => a.ordre - b.ordre);
    const categorizedRecipes = sortedCategories.reduce((acc, category) => {
      acc[category.textFr] = recipes
        .filter((recipe) => recipe.idRecetteCategorie === category.id)
        .map((recipe) => recipe.nomFr);
      return acc;
    }, {});

    setOrderedCategories(sortedCategories.map((cat) => cat.textFr));
    setRecipesByCategory(categorizedRecipes);
  }, [categories, recipes]);

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const handleDragEnd = (result, type) => {
    if (!result.destination) return;

    if (type === "categories") {
      const reorderedCategories = Array.from(orderedCategories);
      const [moved] = reorderedCategories.splice(result.source.index, 1);
      reorderedCategories.splice(result.destination.index, 0, moved);
      setOrderedCategories(reorderedCategories);
    } else {
      const category = result.type;
      const reorderedRecipes = Array.from(recipesByCategory[category]);
      const [moved] = reorderedRecipes.splice(result.source.index, 1);
      reorderedRecipes.splice(result.destination.index, 0, moved);

      setRecipesByCategory((prev) => ({
        ...prev,
        [category]: reorderedRecipes,
      }));
    }
  };

  const handleSaveOrder = async () => {
    try {
      // Map the reordered categories to their IDs or original objects
      const updatedCategories = orderedCategories.map((categoryName) => {
        return categories.find((cat) => cat.textFr === categoryName);
      });

      // Send the updated order to the backend
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/Recipe/UpdateOrderCategories`,
        {
          method: "PUT", // or "PUT", based on your API
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userState.token}`,
          },
          credentials: "include",
          body: JSON.stringify(
            updatedCategories.map((category, index) => ({
              id: category.id,
              ordre: index + 1, // Update order based on the new index
            }))
          ),
        }
      );

      if (!response.ok) throw new Error("Failed to save category order");
      console.log("Order saved successfully");
      onClose(); // Close the dialog after saving
    } catch (error) {
      console.error("Error saving category order:", error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
      <DialogTitle>{isNew ? "Add Recipe" : "Edit Recipes"}</DialogTitle>
      <DialogContent>
        <Box sx={{ width: "100%", padding: 2 }}>
          <Tabs
            value={tabIndex}
            onChange={handleTabChange}
            aria-label="Recipe Tabs"
          >
            <Tab label="Order Categories" />
          </Tabs>

          {tabIndex === 0 && (
            <Box sx={{ marginTop: 2 }}>
              <Typography variant="h6">Order Categories</Typography>
              <DragDropContext
                onDragEnd={(result) => handleDragEnd(result, "categories")}
              >
                <Droppable droppableId="categories" type="categories">
                  {(provided) => (
                    <List ref={provided.innerRef} {...provided.droppableProps}>
                      {orderedCategories.map((category, index) => (
                        <Draggable
                          key={category}
                          draggableId={`category-${index}`}
                          index={index}
                        >
                          {(provided) => (
                            <ListItem
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              component={Paper}
                              sx={{ marginBottom: 1 }}
                            >
                              <ListItemText primary={category} />
                            </ListItem>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </List>
                  )}
                </Droppable>
              </DragDropContext>
              <Button
                variant="contained"
                color="primary"
                sx={{ marginTop: 2 }}
                onClick={handleSaveOrder}
              >
                Save Order
              </Button>
            </Box>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default EditOrderRecipe;
