import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import RecipeCard from "./RecipeCard";
import { Grid, CircularProgress, Button } from "@mui/material";

function RecipesList({
  category,
  setRecipe,
  setImagesRecipe,
  selectedRecipe,
  inactiveRecipe,
  editRecipe,
  isAdmin,
}) {
  const [recipeList, setRecipeList] = useState([]);
  const [loadingCategory, setLoadingCategory] = useState(true);
  const userState = useSelector((state) => state.user);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    const fetchRecipe = async () => {
      if (category.textEn === "Inactive Recipes") {
        setRecipeList(inactiveRecipe);
        setLoadingCategory(false);
        return;
      }

      setLoadingCategory(true);
      try {
        const response = await fetch(
          `${
            process.env.REACT_APP_API_URL
          }/Recipe/GetRecipesByCategory/${encodeURIComponent(category.textEn)}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userState.token}`,
              Accept: "application/json",
              "Access-Control-Allow-Origin": "*",
            },
            credentials: "include",
            signal: signal,
            mode: "cors",
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        const sortedData = data.sort((a, b) => {
          if (!a.recette || !b.recette) return 0;

          const aName =
            userState.lang === "FR"
              ? a.recette.nomFr || ""
              : a.recette.nomEn || "";
          const bName =
            userState.lang === "FR"
              ? b.recette.nomFr || ""
              : b.recette.nomEn || "";

          return aName.localeCompare(bName);
        });

        setRecipeList(sortedData);
      } catch (error) {
        if (error.name === "AbortError") {
          console.log("Fetch aborted");
        } else {
          console.error("Error fetching Recipes:", error, {
            url: `${process.env.REACT_APP_API_URL}/Recipe/GetRecipesByCategory/${category.textEn}`,
            status: error.status,
          });
        }
      } finally {
        setLoadingCategory(false);
      }
    };

    fetchRecipe();

    // Cleanup function to abort the fetch request if the component unmounts or category changes
    return () => {
      controller.abort();
      setLoadingCategory(true);
    };
  }, [category]);

  useEffect(() => {
    /* Sort RecipeList when Language is changed */
    const sortedData = [...recipeList].sort((a, b) =>
      userState.lang === "FR"
        ? a.recette.nomFr.localeCompare(b.recette.nomFr)
        : a.recette.nomEn.localeCompare(b.recette.nomEn)
    );
    setRecipeList(sortedData);
  }, [userState]);

  return (
    <div>
      <Grid container className="RecipeList">
        {loadingCategory ? (
          <CircularProgress className="CategoryLoadingSpinner" size="2.5rem" />
        ) : (
          recipeList.map((recipe, index) => {
            return (
              <Grid
                item
                sm={1}
                className="RecipeCard"
                key={recipe.id || `recipe-${index}`}
              >
                <RecipeCard
                  recipe={recipe.recette}
                  selectedRecipe={selectedRecipe}
                  onClick={setRecipe}
                  setImagesRecipe={setImagesRecipe}
                  images={recipe.images}
                  onEdit={() => editRecipe(recipe)}
                  isAdmin={isAdmin}
                />
              </Grid>
            );
          })
        )}
      </Grid>
    </div>
  );
}

export default RecipesList;
