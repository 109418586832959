import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  Box,
  TextField,
  Button,
  Typography,
  Stack,
  Divider,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";

function AddNewRight({ onClose }) {
  const { t } = useTranslation(["AccessRights", "Common"]);
  const userState = useSelector((state) => state.user);

  const [formData, setFormData] = useState({
    nom: "",
    descriptionFr: "",
    descriptionEn: "",
  });

  const [errors, setErrors] = useState({
    nom: false,
    descriptionFr: false,
    descriptionEn: false,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    // Clear error when user types
    if (errors[name]) {
      setErrors((prev) => ({
        ...prev,
        [name]: false,
      }));
    }
  };

  const validateForm = () => {
    const newErrors = {
      nom: !formData.nom.trim(),
      descriptionFr: !formData.descriptionFr.trim(),
      descriptionEn: !formData.descriptionEn.trim(),
    };

    setErrors(newErrors);
    return !Object.values(newErrors).some((error) => error);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/Employee/AddGroup`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userState.token}`,
          },
          body: JSON.stringify(formData),
        }
      );

      if (response.ok) {
        onClose();
        // You might want to add a success notification here
      } else {
        // Handle error
        console.error("Failed to create access right");
      }
    } catch (error) {
      console.error("Error creating access right:", error);
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit} noValidate>
      <Typography variant="h6" component="h2" gutterBottom>
        {t("NewGroup")}
      </Typography>

      <Divider sx={{ mb: 3 }} />

      <Stack spacing={3}>
        <TextField
          name="nom"
          label={t("GroupName")}
          value={formData.nom}
          onChange={handleChange}
          error={errors.nom}
          helperText={errors.nom ? t("Required") : ""}
          fullWidth
          required
        />

        <TextField
          name="descriptionFr"
          label={t("DescriptionFr")}
          value={formData.descriptionFr}
          onChange={handleChange}
          error={errors.descriptionFr}
          helperText={errors.descriptionFr ? t("Required") : ""}
          fullWidth
          required
          multiline
          rows={3}
        />

        <TextField
          name="descriptionEn"
          label={t("DescriptionEn")}
          value={formData.descriptionEn}
          onChange={handleChange}
          error={errors.descriptionEn}
          helperText={errors.descriptionEn ? t("Required") : ""}
          fullWidth
          required
          multiline
          rows={3}
        />

        <Box
          sx={{ display: "flex", gap: 2, justifyContent: "flex-end", mt: 2 }}
        >
          <Button
            variant="outlined"
            onClick={onClose}
            startIcon={<CancelIcon />}
          >
            {t("Cancel")}
          </Button>
          <Button type="submit" variant="contained" startIcon={<SaveIcon />}>
            {t("Save")}
          </Button>
        </Box>
      </Stack>
    </Box>
  );
}

export default AddNewRight;
