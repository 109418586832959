import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import { useSelector } from "react-redux";

function GroupsUserManagement({ group }) {
  const [checked, setChecked] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [employeesByGroup, setEmployeesByGroup] = useState([]);
  const userState = useSelector((state) => state.user);

  function not(a, b) {
    return a.filter((value) => !b.includes(value));
  }

  function intersection(a, b) {
    return a.filter((value) => b.includes(value));
  }

  function union(a, b) {
    return [...a, ...not(b, a)];
  }

  // Calculate items that are checked in both lists
  const availableChecked = intersection(
    checked,
    employees.map((item) => item.noEmploye)
  );
  const selectedChecked = intersection(
    checked,
    employeesByGroup.map((item) => item.noEmploye)
  );

  const numberOfChecked = (items) =>
    intersection(
      checked,
      items.map((item) => item.noEmploye)
    ).length;

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
    console.log("Selected values:", newChecked);
  };

  const handleToggleAll = (items) => () => {
    const itemUsernames = items.map((item) => item.username);
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, itemUsernames));
    } else {
      setChecked(union(checked, itemUsernames));
    }
  };

  const handleCheckedRight = async () => {
    const newlySelected = employees.filter((user) =>
      checked.includes(user.noEmploye)
    );

    try {
      const payload = {
        Id: 0,
        IdGroupe: group.id,
        NoEmploye: newlySelected[0].noEmploye,
        Users: newlySelected.map((user) => ({
          Id: 0,
          NoEmploye: user.noEmploye,
          GroupeId: group.id,
        })),
      };

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/Employee/AddUserToGroup`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userState.token}`,
          },
          body: JSON.stringify(payload),
        }
      );

      if (response.ok) {
        setEmployeesByGroup((prev) => union(prev, newlySelected));
        setEmployees((prev) => not(prev, newlySelected));
        setChecked([]);
      }
    } catch (error) {
      console.error("Error adding users to group:", error);
    }
  };

  const handleCheckedLeft = async () => {
    const newlyAvailable = employeesByGroup.filter((user) =>
      checked.includes(user.noEmploye)
    );

    try {
      const payload = {
        Id: 0,
        IdGroupe: group.id,
        NoEmploye: newlyAvailable[0].noEmploye,
        Users: newlyAvailable.map((user) => ({
          Id: 0,
          NoEmploye: user.noEmploye,
          GroupeId: group.id,
        })),
      };

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/Employee/RemoveUserFromGroup`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userState.token}`,
          },
          body: JSON.stringify(payload),
        }
      );

      if (response.ok) {
        setEmployees((prev) => union(prev, newlyAvailable));
        setEmployeesByGroup((prev) => not(prev, newlyAvailable));
        setChecked([]);
      }
    } catch (error) {
      console.error("Error removing users from group:", error);
    }
  };

  const GetEmployeByGroup = async () => {
    if (group) {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/Employee/GetEmployeByGroup/${group.id}`,
          {
            method: "GET",
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userState.token}`,
            },
          }
        );
        const data = await response.json();
        setEmployeesByGroup(data);
        console.log(data);
      } catch (error) {
        console.error("Error fetching employees by group:", error);
      }
    }
  };

  const GetAvailableEmployees = async () => {
    if (group) {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/Employee/GetAvailableEmployesByGroup/${group.id}`,
          {
            method: "GET",
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userState.token}`,
            },
          }
        );
        const data = await response.json();
        console.log(data);

        // Create a Set of employee IDs that are already in the group
        const existingEmployeeIds = new Set(
          employeesByGroup.map((employee) => employee.username)
        );

        // Filter out employees that are already in the group
        setEmployees(
          data.filter((employee) => !existingEmployeeIds.has(employee.username))
        );
      } catch (error) {
        console.error("Error fetching employees", error);
      }
    }
  };

  useEffect(() => {
    GetEmployeByGroup();
    GetAvailableEmployees();
  }, [group]);

  const customList = (title, items) => (
    <Card>
      <CardHeader
        sx={{ px: 2, py: 1 }}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={
              numberOfChecked(items) === items.length && items.length !== 0
            }
            indeterminate={
              numberOfChecked(items) !== items.length &&
              numberOfChecked(items) !== 0
            }
            disabled={items.length === 0}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} selected`}
      />
      <Divider />
      <List
        sx={{
          width: 400,
          height: 320,
          bgcolor: "background.paper",
          overflow: "auto",
        }}
        dense
        component="div"
        role="list"
        virtualized
      >
        {items.slice(0, 100).map((value) => {
          const labelId = `transfer-list-${value.username}`;
          return (
            <ListItemButton
              key={value.noEmploye}
              role="listitem"
              onClick={handleToggle(value.noEmploye)}
              dense
              disableRipple
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.includes(value.noEmploye)}
                  tabIndex={-1}
                  disableRipple
                  edge="start"
                />
              </ListItemIcon>
              <ListItemText
                id={labelId}
                primary={
                  value.firstname
                    ? `${value.firstname} ${value.lastName} (${value.username})`
                    : `${value.name} ${value.lastName} (${
                        value.username || value.userName
                      })`
                }
              />
            </ListItemButton>
          );
        })}
      </List>
    </Card>
  );

  return (
    <div>
      <Grid
        container
        spacing={2}
        justifyContent="center"
        alignItems="center"
        sx={{ m: 5 }}
      >
        <Grid item>{customList("All Employees", employees)}</Grid>
        <Grid item>
          <Grid container direction="column" alignItems="center">
            <Button
              sx={{ my: 0.5 }}
              variant="outlined"
              size="small"
              onClick={handleCheckedRight}
              disabled={availableChecked.length === 0}
              aria-label="move selected right"
            >
              &gt;
            </Button>
            <Button
              sx={{ my: 0.5 }}
              variant="outlined"
              size="small"
              onClick={handleCheckedLeft}
              disabled={selectedChecked.length === 0}
              aria-label="move selected left"
            >
              &lt;
            </Button>
          </Grid>
        </Grid>
        <Grid item>
          {customList("Employees with Selected Group", employeesByGroup)}
        </Grid>
      </Grid>
    </div>
  );
}

export default GroupsUserManagement;
