import React, { useEffect, useState } from "react";
import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import "./css/RecipesCategory.css";

function RecipesCategory({
  name,
  image,
  onClick,
  selectedCategory,
  onDelete,
  onEdit,
  onToggleActive,
  isAdmin,
  isInactive,
}) {
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (
      selectedCategory &&
      (selectedCategory.textEn === name || selectedCategory.textFr === name)
    ) {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  }, [selectedCategory, name]);

  const handleClick = (e) => {
    setIsActive(!isActive);
    onClick(e);
  };

  const handleDelete = (e) => {
    e.stopPropagation();
    onDelete();
  };

  const handleEdit = (e) => {
    e.stopPropagation();
    onEdit(name);
  };

  const handleToggleActive = (e) => {
    e.stopPropagation();
    onToggleActive();
  };

  return (
    <div
      className={`RecipesCategory ${isActive ? "active" : ""}`}
      data={name}
      onClick={(e) => handleClick(e)}
    >
      <img
        src={`data:image/png;base64,${image}`}
        alt={name}
        style={{
          opacity: isInactive ? 0.5 : 1,
          filter: isInactive ? "grayscale(100%)" : "none",
        }}
      />
      <div className="CategoryHeader">
        <h6
          style={{
            opacity: isInactive ? 0.5 : 1,
            filter: isInactive ? "grayscale(100%)" : "none",
          }}
        >
          {name}
        </h6>
        {isAdmin && (
          <>
            <IconButton
              aria-label="toggle-active"
              color={"success"}
              size="small"
              onClick={handleToggleActive}
            >
              {isInactive ? (
                <VisibilityOffIcon className="ToggleIcon" />
              ) : (
                <VisibilityIcon className="ToggleIcon" />
              )}
            </IconButton>
            <IconButton
              aria-label="edit"
              color="primary"
              size="small"
              onClick={handleEdit}
            >
              <EditIcon className="EditIcon" />
            </IconButton>
            <IconButton
              aria-label="delete"
              color="error"
              size="small"
              onClick={handleDelete}
            >
              <DeleteIcon className="DeleteIcon" />
            </IconButton>
          </>
        )}
      </div>
    </div>
  );
}

export default RecipesCategory;
