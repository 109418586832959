import React, { useEffect, useState } from "react";
import {
  Grid,
  Paper,
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Autocomplete,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import CustomAlert from "../../Common/Components/CustomAlert";

function TabRestaurant({ emplacement, onSave }) {
  const { t } = useTranslation(["Location"]);
  const userState = useSelector((state) => state.user);

  // Form state with correct field names matching the backend
  const [formData, setFormData] = useState({
    nbSiege: "",
    superficie: "",
    ipAddress: "",
    prixLivraison: "",
    prixLivraisonParPalette: "",
    idTypePaiement: null,
    nomTypePaiementFr: "",
  });

  // Alert state
  const [alert, setAlert] = useState({
    show: false,
    type: "",
    message: "",
  });

  // Payment types state
  const [typePaiements, setTypePaiements] = useState([]);

  useEffect(() => {
    getTypePaiement();
  }, []);

  // Update form with emplacement data
  useEffect(() => {
    if (emplacement) {
      setFormData({
        nbSiege: emplacement.nbSiege || "",
        superficie: emplacement.superficie || "",
        ipAddress: emplacement.ipAddress || "",
        prixLivraison: emplacement.prixLivraison || 0,
        prixLivraisonParPalette: emplacement.prixLivraisonParPalette || 0,
        idTypePaiement: emplacement.idTypePaiement || null,
        nomTypePaiementFr: emplacement.nomTypePaiementFr || "",
      });
    }
  }, [emplacement]);

  const handleInputChange = (field) => (event) => {
    const value = event.target.value;

    // Handle numeric fields
    if (field === "prixLivraison" || field === "prixLivraisonParPalette") {
      setFormData((prev) => ({
        ...prev,
        [field]: value, // Don't convert to number immediately to allow typing
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [field]: value,
      }));
    }
  };

  const handleDateChange = (field) => (date) => {
    setFormData((prev) => ({
      ...prev,
      [field]: date,
    }));
  };

  const handlePaymentTypeChange = (event) => {
    const selectedType = typePaiements.find((t) => t.id === event.target.value);
    setFormData((prev) => ({
      ...prev,
      idTypePaiement: selectedType.id,
      nomTypePaiementFr: selectedType.nomFr,
    }));
  };

  const getTypePaiement = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/location/GetTypePaiement`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userState.token}`,
          },
        }
      );
      const data = await response.json();
      setTypePaiements(data);
    } catch (error) {
      setAlert({
        show: true,
        type: "error",
        message: "Error loading payment types",
      });
    }
  };

  const handleSave = async () => {
    console.log("Form data before save:", {
      nbSiege: formData.nbSiege,
      type: typeof formData.nbSiege,
    }); // Detailed debug log

    // Create saveData with direct assignment
    const saveData = {
      // Required fields
      noEmplacement: emplacement?.noEmplacement,
      noAdresse: emplacement?.noAdresse,
      activeTab: 1,

      // Restaurant tab specific data
      nbSiege: Number(formData.nbSiege), // Force number conversion
      superficie: Number(formData.superficie), // Force number conversion
      IpAddress: formData.ipAddress,
      PrixLivraison: parseFloat(formData.prixLivraison) || 0, // Handle float values
      PrixLivraisonParPalette:
        parseFloat(formData.prixLivraisonParPalette) || 0, // Handle float values
      IdTypePaiement: formData.idTypePaiement,
      NomTypePaiementFr: formData.nomTypePaiementFr,
    };

    // Debug log to verify the conversion
    console.log("Save data nbSiege:", {
      value: saveData.nbSiege,
      type: typeof saveData.nbSiege,
    });

    const result = await onSave(saveData, userState.token, 1);

    setAlert({
      show: true,
      type: result.success ? "success" : "error",
      message: result.message,
    });
  };

  return (
    <Paper elevation={3} sx={{ mx: "5%" }}>
      <Box sx={{ p: 5 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label={t("seatsNumber")}
              value={formData.nbSiege}
              onChange={handleInputChange("nbSiege")}
              type="number"
              InputProps={{
                inputProps: { min: 0 },
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label={t("Surface")}
              value={formData.superficie}
              onChange={handleInputChange("superficie")}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <Autocomplete
                value={
                  typePaiements.find(
                    (type) =>
                      type.nomFr === formData.nomTypePaiementFr ||
                      type.nomEn === formData.nomTypePaiementEn
                  ) || null
                }
                onChange={(event, newValue) => {
                  setFormData((prev) => ({
                    ...prev,
                    idTypePaiement: newValue ? newValue.id : null,
                    nomTypePaiementFr: newValue ? newValue.nomFr : "",
                  }));
                }}
                options={typePaiements}
                getOptionLabel={(option) =>
                  userState.lang === "EN" ? option.nomEn : option.nomFr
                }
                renderInput={(params) => (
                  <TextField {...params} label={t("PaymentType")} fullWidth />
                )}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label={t("IPCaisse")}
              value={formData.ipAddress}
              onChange={handleInputChange("ipAddress")}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label={t("DeliveryCost")}
              value={formData.prixLivraison}
              onChange={handleInputChange("prixLivraison")}
              type="number"
              inputProps={{
                step: "0.01",
                min: "0",
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label={t("DeliveryCostByPallets")}
              value={formData.prixLivraisonParPalette}
              onChange={handleInputChange("prixLivraisonParPalette")}
              type="number"
              inputProps={{
                step: "0.01",
                min: "0",
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Button variant="contained" onClick={handleSave} sx={{ mt: 2 }}>
              {t("Save")}
            </Button>
          </Grid>
        </Grid>
      </Box>

      <CustomAlert
        open={alert.show}
        closeMessage={() => setAlert((prev) => ({ ...prev, show: false }))}
        typeMessage={alert.type}
        message={alert.message}
      />
    </Paper>
  );
}

export default TabRestaurant;
