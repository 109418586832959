import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { DataGridPro } from "@mui/x-data-grid-pro";
import {
  Box,
  IconButton,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import GroupUsersManagement from "../Tabs/Modal/GroupManagement";
import GroupIcon from "@mui/icons-material/Group";

function GroupManagement() {
  const [groups, setGroups] = useState([]);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation(["AccessRights", "Common"]);
  const userState = useSelector((state) => state.user);
  const [editingGroup, setEditingGroup] = useState(null);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [editFormData, setEditFormData] = useState({
    nom: "",
    descriptionFr: "",
    descriptionEn: "",
  });
  const [userManagementOpen, setUserManagementOpen] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);

  const fetchGroups = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/Employee/GetGroups`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${userState.token}`,
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      setGroups(data);
    } catch (error) {
      console.error("Error fetching groups:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchGroups();
  }, []);

  const handleEditClick = (group) => {
    setEditingGroup(group);
    setEditFormData({
      nom: group.nom,
      descriptionFr: group.descriptionFr,
      descriptionEn: group.descriptionEn,
    });
    setEditDialogOpen(true);
  };

  const handleEditSave = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/Employee/UpdateGroup/${editingGroup.id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userState.token}`,
          },
          body: JSON.stringify({
            ...editingGroup,
            ...editFormData,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update group");
      }

      setEditDialogOpen(false);
      // Refresh the groups list
      fetchGroups();
    } catch (error) {
      console.error("Error updating group:", error);
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm(t("DeleteGroupConfirmation"))) {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/Employee/DeleteGroup/${id}`,
          {
            method: "DELETE",
            headers: {
              Authorization: `Bearer ${userState.token}`,
            },
          }
        );
        if (response.ok) {
          fetchGroups(); // Refresh the list
        }
      } catch (error) {
        console.error("Error deleting group:", error);
      }
    }
  };

  const handleManageUsers = (group) => {
    setSelectedGroup(group);
    setUserManagementOpen(true);
  };

  const columns = [
    {
      field: "nom",
      headerName: t("GroupName"),
      flex: 1,
      minWidth: 150,
    },
    {
      field: "descriptionFr",
      headerName: t("DescriptionFr"),
      flex: 2,
      minWidth: 200,
    },
    {
      field: "descriptionEn",
      headerName: t("DescriptionEn"),
      flex: 2,
      minWidth: 200,
    },
    {
      field: "actions",
      headerName: t("Actions"),
      width: 200,
      renderCell: (params) => (
        <Box sx={{ display: "flex", gap: 1 }}>
          <IconButton
            onClick={() => handleEditClick(params.row)}
            size="small"
            color="primary"
          >
            <EditIcon />
          </IconButton>
          <IconButton
            onClick={() => handleManageUsers(params.row)}
            size="small"
            color="primary"
          >
            <GroupIcon />
          </IconButton>
          <IconButton
            onClick={() => handleDelete(params.row.id)}
            size="small"
            color="error"
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  return (
    <>
      <Box sx={{ height: 600, width: "100%" }}>
        <DataGridPro
          rows={groups}
          columns={columns}
          loading={loading}
          pagination
          checkboxSelection
          disableRowSelectionOnClick
          getRowId={(row) => row.id}
          initialState={{
            pagination: {
              paginationModel: { pageSize: 10 },
            },
          }}
          pageSizeOptions={[5, 10, 25, 50]}
          slots={{
            toolbar: DataGridPro.Toolbar,
          }}
          density="comfortable"
        />
      </Box>

      <Dialog open={editDialogOpen} onClose={() => setEditDialogOpen(false)}>
        <DialogTitle>{t("EditGroup")}</DialogTitle>
        <DialogContent>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2, pt: 2 }}>
            <TextField
              label={t("GroupName")}
              value={editFormData.nom}
              onChange={(e) =>
                setEditFormData((prev) => ({ ...prev, nom: e.target.value }))
              }
              disabled={true}
              fullWidth
            />
            <TextField
              label={t("DescriptionFr")}
              value={editFormData.descriptionFr}
              onChange={(e) =>
                setEditFormData((prev) => ({
                  ...prev,
                  descriptionFr: e.target.value,
                }))
              }
              fullWidth
              multiline
              rows={2}
            />
            <TextField
              label={t("DescriptionEn")}
              value={editFormData.descriptionEn}
              onChange={(e) =>
                setEditFormData((prev) => ({
                  ...prev,
                  descriptionEn: e.target.value,
                }))
              }
              fullWidth
              multiline
              rows={2}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditDialogOpen(false)}>
            {t("Cancel")}
          </Button>
          <Button onClick={handleEditSave} variant="contained">
            {t("Save")}
          </Button>
        </DialogActions>
      </Dialog>

      <GroupUsersManagement
        group={selectedGroup}
        open={userManagementOpen}
        onClose={() => setUserManagementOpen(false)}
      />
    </>
  );
}

export default GroupManagement;
