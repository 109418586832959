import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Paper,
  TextField,
  Button,
  Autocomplete,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { useForm, Controller } from "react-hook-form";
import { useSelector } from "react-redux";
import CustomAlert from "../../Common/Components/CustomAlert";

function Company({
  isAdmin,
  company,
  emplacements,
  shareHoldersByEmplacement = [],
  onCompanyUpdate,
}) {
  const { t } = useTranslation(["Company"], ["Common"]);
  const [discountPayments, setDiscountPayments] = useState([]);
  const [discountPayment, setDiscountPayment] = useState();

  // Add state for alert
  const [alert, setAlert] = useState({
    show: false,
    type: "",
    message: "",
  });

  const { register, control, setValue, watch, reset, handleSubmit, errors } =
    useForm({
      defaultValues: {
        noAccpac: "",
        nom: "",
        dateOuverture: "",
        dateFermeture: "",
        noMarchand: "",
        noMarchandDC: "",
        passwordDC: "",
        escomptePaiement: "",
        nomEmplacement: "",
        accessCodeMEV: "",
        nomExploitant: "",
        PST: "",
        GST_HST: "",
      },
    });

  const userState = useSelector((state) => state.user);

  useEffect(() => {
    GetEscomptePaiement();
  }, []);

  useEffect(() => {
    if (company) {
      reset({
        noAccpac: company.noAccpac || "",
        nom: company.nom || "",
        dateOuverture: company.dateOuverture
          ? dayjs(company.dateOuverture)
          : null,
        dateFermeture: company.dateFermeture
          ? dayjs(company.dateFermeture)
          : null,
        noMarchand: company.noMarchand,
        noMarchandDC: company.noMarchandDC || 0,
        passwordDC: company.passwordDC || "",
        escomptePaiement:
          userState.lang === "EN"
            ? company.escomptePaiementDetailEn
            : company.escomptePaiementDetailFr || "",
        nomEmplacement: company.nomEmplacement || "",
        accessCodeMEV: company.accessCodeMEV || "",
        nomExploitant: company.nomExploitant || "",
        pst: company.pst || "",
        gsT_HST: company.gsT_HST || "",
      });
    }
  }, [company, reset]);

  const GetEscomptePaiement = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/company/GetEscomptePaiement`,
        {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userState.token}`,
            "Cache-Control": "no-cache",
            Pragma: "no-cache",
          },
        }
      );

      if (!response.ok && response.status !== 304) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setDiscountPayments(data);
    } catch (error) {
      console.error("Error fetching discount payments:", error);
    }
  };

  const onSubmit = async (formData) => {
    const dataCompany = {
      noAccpac: formData.noAccpac,
      dateOuverture: formData.dateOuverture,
      dateFermeture: formData.dateFermeture,
      nomExploitant: formData.nomExploitant,
      NoMarchand: formData.noMarchand,
      noMarchandDC: formData.noMarchandDC,
      nomEmplacement: formData.nomEmplacement,
      nom: formData.nom,
      PasswordDC: formData.passwordDC,
      escomptePaiement: formData.escomptePaiement,
      AccessCodeMEV: formData.accessCodeMEV,
      PST: formData.pst,
      GST_HST: formData.gsT_HST,
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/company/SaveCompany`,
        {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userState.token}`,
          },
          body: JSON.stringify(dataCompany),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to save company");
      }

      setAlert({
        show: true,
        type: "success",
        message: t("SaveSuccess"),
      });
      // Reload data after successful save
      if (onCompanyUpdate) {
        onCompanyUpdate();
      }
    } catch (error) {
      console.error("Error saving company:", error);
      setAlert({
        show: true,
        type: "error",
        message: t("SaveError"),
      });
    }
  };

  useEffect(() => {
    GetEscomptePaiement();
    // If you need to clean up
    return () => {
      setDiscountPayments([]);
    };
  }, []); // Empty dependency array if you only want it to run once

  return (
    <>
      <Paper elevation={3} sx={{ marginRight: "5%", marginLeft: "5%" }}>
        <Box sx={{ padding: 5 }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="noAccpac"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id="No Accpac"
                      label={t("StoreNumber")}
                      name="No Accpac"
                      fullWidth
                      size="small"
                      autoComplete="off"
                      variant="outlined"
                      disabled={!company?.estActif}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="nom"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id="Nom"
                      label={t("CompanyName")}
                      name="Nom"
                      fullWidth
                      size="small"
                      autoComplete="off"
                      variant="outlined"
                      disabled={!company?.estActif}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Controller
                  name="nomEmplacement"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      options={emplacements || []}
                      getOptionLabel={(option) => option?.nomEmplacement || ""}
                      value={
                        emplacements?.find(
                          (r) => r.nomEmplacement === field.value
                        ) || null
                      }
                      onChange={(_, newValue) => {
                        field.onChange(newValue?.nomEmplacement || "");
                      }}
                      isOptionEqualToValue={(option, value) =>
                        option?.nomEmplacement === value?.nomEmplacement
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t("Location")}
                          fullWidth
                          size="small"
                        />
                      )}
                      disabled={!company?.estActif}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="nomExploitant"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      value={
                        value
                          ? (shareHoldersByEmplacement ?? []).find((s) => {
                              if (!s) return false;
                              const fullName =
                                `${s.firstname} ${s.lastName} (${s.username})`.trim();
                              return fullName === value;
                            }) || null
                          : null
                      }
                      onChange={(event, newValue) => {
                        if (newValue) {
                          const fullName =
                            `${newValue.firstname} ${newValue.lastName} (${newValue.username})`.trim();
                          onChange(fullName);
                        } else {
                          onChange(null);
                        }
                      }}
                      options={shareHoldersByEmplacement ?? []}
                      getOptionLabel={(option) => {
                        if (!option) return "";
                        return `${option.firstname} ${option.lastName} (${option.username})`.trim();
                      }}
                      isOptionEqualToValue={(option, value) => {
                        if (!option || !value) return false;
                        const optionName =
                          `${option.firstname} ${option.lastName} (${option.username})`.trim();
                        const valueName =
                          `${value.firstname} ${value.lastName} (${value.username})`.trim();
                        return optionName === valueName;
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t("Operator")}
                          fullWidth
                          size="small"
                        />
                      )}
                      disabled={!company?.estActif}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="dateOuverture"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label={t("OpenDate")}
                        value={value ? dayjs(value) : null}
                        onChange={(newValue) => {
                          onChange(
                            newValue ? newValue.format("YYYY-MM-DD") : null
                          );
                        }}
                        disabled={!isAdmin && !company?.estActif}
                        slotProps={{
                          textField: {
                            fullWidth: true,
                            size: "small",
                          },
                        }}
                      />
                    </LocalizationProvider>
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="dateFermeture"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label={t("CloseDate")}
                        value={value ? dayjs(value) : null}
                        onChange={(newValue) => {
                          onChange(
                            newValue ? newValue.format("YYYY-MM-DD") : null
                          );
                        }}
                        disabled={!isAdmin && !company?.estActif}
                        slotProps={{
                          textField: {
                            fullWidth: true,
                            size: "small",
                          },
                        }}
                      />
                    </LocalizationProvider>
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="noMarchand"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id="No marchand"
                      label="No Marchand"
                      name="No marchand"
                      fullWidth
                      size="small"
                      autoComplete="off"
                      variant="outlined"
                      disabled={!company?.estActif}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Controller
                  name="noMarchandDC"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id="No marchand DC"
                      label="No marchand DC"
                      name="No marchand DC"
                      fullWidth
                      size="small"
                      autoComplete="off"
                      variant="outlined"
                      disabled={!company?.estActif}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="accessCodeMEV"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id="AccessCodeMEV"
                      label="Code acces MEV"
                      name="AccessCodeMEV"
                      fullWidth
                      size="small"
                      autoComplete="off"
                      variant="outlined"
                      disabled={!company?.estActif}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="passwordDC"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id="Mot de passe DC"
                      name="Mot de passe DC"
                      label="Mot de passe DC"
                      fullWidth
                      size="small"
                      autoComplete="off"
                      variant="outlined"
                      disabled={!company?.estActif}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="gsT_HST"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id="GST/HST"
                      label="GST/HST"
                      name="GST/HST"
                      fullWidth
                      size="small"
                      autoComplete="off"
                      variant="outlined"
                      disabled={!company?.estActif}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{
                        "& .MuiInputLabel-root": {
                          background: "white",
                          padding: "0 4px",
                        },
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="pst"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id="pst"
                      name="pst"
                      label="PST"
                      fullWidth
                      size="small"
                      autoComplete="off"
                      variant="outlined"
                      disabled={!company?.estActif}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{
                        "& .MuiInputLabel-root": {
                          background: "white",
                          padding: "0 4px",
                        },
                      }}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Controller
                  name="escomptePaiement"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      options={discountPayments ?? []}
                      getOptionLabel={(option) => {
                        if (!option) return "";
                        return userState.lang === "FR"
                          ? option.nomFr || ""
                          : option.nomEn || "";
                      }}
                      value={
                        field.value
                          ? (discountPayments ?? []).find(
                              (d) =>
                                d.nomFr === field.value ||
                                d.nomEn === field.value
                            )
                          : null
                      }
                      onChange={(_, newValue) => {
                        field.onChange(
                          newValue
                            ? userState.lang === "FR"
                              ? newValue.nomFr || ""
                              : newValue.nomEn || ""
                            : ""
                        );
                      }}
                      isOptionEqualToValue={(option, value) => {
                        if (!option || !value) return false;
                        return (
                          option.nomFr === value.nomFr ||
                          option.nomEn === value.nomEn
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t("PaymentDiscount")}
                          fullWidth
                          size="small"
                        />
                      )}
                      disabled={!company?.estActif}
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Button
                  variant="contained"
                  type="submit"
                  disabled={!isAdmin && !company?.estActif}
                >
                  {t("Save")}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Paper>

      <CustomAlert
        open={alert.show}
        closeMessage={() => setAlert((prev) => ({ ...prev, show: false }))}
        typeMessage={alert.type}
        message={alert.message}
      />
    </>
  );
}

export default Company;
