import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
  TextField,
  Typography,
  Button,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { useSelector } from "react-redux";

function AddRecipeCategory({ open, onClose, category, isNew }) {
  const [formData, setFormData] = useState({
    nameFr: "",
    nameEn: "",
    active: false,
    uploadedFiles: [], // Includes both new uploads and existing images
  });

  const userState = useSelector((state) => state.user);

  // Populate fields and reset when `category` or `isNew` changes
  useEffect(() => {
    if (isNew) {
      // Reset form for new category
      setFormData({
        id: 0,
        nameFr: "",
        nameEn: "",
        uploadedFiles: [],
      });
    } else if (category) {
      // Populate form for editing existing category
      setFormData({
        id: category.id || 0,
        active: category.estActif || false,
        nameFr: category.textFr || "",
        nameEn: category.textEn || "",
        uploadedFiles: category.image
          ? [{ type: "existing", url: category.image, format: category.format }]
          : [],
      });
    }
  }, [category, isNew]);

  const handleInputChange = (e) => {
    const { name, type, checked, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      // For checkboxes, use the inverse of checked since we're showing "inactive"
      [name]: type === "checkbox" ? !checked : value,
    }));
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const files = Array.from(event.dataTransfer.files || []);
    setFormData((prev) => ({
      ...prev,
      uploadedFiles: [...prev.uploadedFiles, ...files],
    }));
  };

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files || []);
    setFormData((prev) => ({
      ...prev,
      uploadedFiles: [...prev.uploadedFiles, ...files],
    }));
  };

  const handleSave = async () => {
    const endpoint = isNew ? "AddCategory" : "EditCategory";
    const form = new FormData();

    form.append("Id", formData.id || 0);
    form.append("TextFr", formData.nameFr);
    form.append("TextEn", formData.nameEn);
    form.append("EstActif", formData.active ?? true);

    // Add the image file (if any)
    const newFile = formData.uploadedFiles.find(
      (file) => file.type !== "existing"
    );
    if (newFile) {
      form.append("UploadImage", newFile);
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/Recipe/${endpoint}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${userState.token}`,
          },
          credentials: "include",
          body: form,
        }
      );

      if (!response.ok) throw new Error("Failed to save category");

      const result = await response.json();
      console.log("Saved Category:", result);
      onClose();
    } catch (error) {
      console.error("Error saving category:", error);
    }
  };

  const handleRemoveImage = (index) => {
    setFormData((prev) => ({
      ...prev,
      uploadedFiles: prev.uploadedFiles.filter((_, i) => i !== index),
    }));
  };

  console.log(category);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
      <DialogTitle>{isNew ? "Add category" : "Category Editor"}</DialogTitle>
      <DialogContent>
        <Box sx={{ p: 2 }}>
          <Box>
            {/* Checkbox */}
            <FormControlLabel
              control={
                <Checkbox
                  checked={!formData.active}
                  onChange={handleInputChange}
                  name="active"
                />
              }
              label="Est inactive"
            />
            {/* Recipe Name Fields */}
            <TextField
              name="nameFr"
              label="Nom en francais"
              fullWidth
              margin="normal"
              value={formData.nameFr}
              onChange={handleInputChange}
            />
            <TextField
              name="nameEn"
              label="Nom en anglais"
              fullWidth
              margin="normal"
              value={formData.nameEn}
              onChange={handleInputChange}
            />

            {/* Drag-and-Drop Zone */}
            <Box
              sx={{
                border: "2px dashed #ccc",
                borderRadius: 4,
                padding: 4,
                textAlign: "center",
                mt: 2,
                cursor: "pointer",
                backgroundColor: "#f9f9f9",
                position: "relative",
                overflow: "hidden",
              }}
              onDrop={handleDrop}
              onDragOver={(e) => e.preventDefault()}
              onDragEnter={(e) => e.preventDefault()}
            >
              <Typography variant="body1" color="textSecondary">
                Drag and drop a photo here, or click to upload
              </Typography>

              <input
                type="file"
                accept="image/*"
                multiple
                onChange={handleFileChange}
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  opacity: 0,
                  cursor: "pointer",
                }}
              />
            </Box>

            {/* Preview Uploaded Files */}
            {formData.uploadedFiles.length > 0 && (
              <Box mt={2} display="flex" flexWrap="wrap" gap={2}>
                {formData.uploadedFiles.map((file, index) => {
                  const isExisting = file.type === "existing";
                  const fileURL = isExisting
                    ? `data:image/png;base64,${file.url}`
                    : URL.createObjectURL(file);

                  return (
                    <Box
                      key={index}
                      sx={{
                        border: "1px solid #ccc",
                        borderRadius: 4,
                        padding: 1,
                        textAlign: "center",
                        maxWidth: 150,
                      }}
                    >
                      <img
                        src={fileURL}
                        alt={`Preview ${index + 1}`}
                        style={{ maxWidth: "100%", borderRadius: 4 }}
                      />
                      <Typography variant="caption">
                        {isExisting
                          ? `Existing Image`
                          : file.name || `Image ${index + 1}`}
                      </Typography>
                      <Button
                        color="error"
                        size="small"
                        onClick={() => handleRemoveImage(index)}
                      >
                        Remove
                      </Button>
                    </Box>
                  );
                })}
              </Box>
            )}

            {/* Save and Cancel Buttons */}
            <Box
              sx={{
                mt: 3,
                display: "flex",
                justifyContent: "flex-end",
                gap: 2,
              }}
            >
              <Button variant="contained" color="primary" onClick={handleSave}>
                Save
              </Button>
              <Button variant="outlined" color="secondary" onClick={onClose}>
                Cancel
              </Button>
            </Box>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default AddRecipeCategory;
