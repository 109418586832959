import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
  Tabs,
  Tab,
  Autocomplete,
  Button,
  Paper,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import CustomTreeView from "../../Common/Components/CustomTreeView";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

function AddRecipe({
  open,
  onClose,
  recipe,
  categories,
  isNew,
  province,
  images,
}) {
  const [active, setActive] = useState(false);
  const [visibilityActive, setVisibilityActive] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [tabIndex, setTabIndex] = useState(0);
  const [videos, setVideos] = useState([]);
  const [indeterminateCheckbox, setIndeterminateCheckbox] = useState([]);
  const [selectedNodes, setSelectedNodes] = useState([]);
  const [noEmplacement, setNoEmplacement] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [videoFilesEn, setVideoFilesEn] = useState();
  const [videoFilesFr, setVideoFilesFr] = useState();
  const [TreeViewProvince, setTreeViewProvince] = useState();
  const [formData, setFormData] = useState({
    active: false,
    nameFr: "",
    nameEn: "",
    category: null,
    ingredientsFr: "",
    ingredientsEn: "",
    decorationsFr: "",
    decorationsEn: "",
    utensilsFr: "",
    utensilsEn: "",
    reducedPortionFr: "",
    reducedPortionEn: "",
    takeAwayFr: "",
    takeAwayEn: "",
    recipeFr: "",
    recipeEn: "",
    Province: [],
    images: [],
    uploadedVideoFr: "",
    uploadedVideoEn: "",
    videoFr: [],
    videoEn: [],
  });
  const [currentImage, setCurrentImage] = useState(null);
  const [mainImage, setMainImage] = useState(null);
  const [additionalImages, setAdditionalImages] = useState([]);
  const [videoFr, setVideoFr] = useState(null);
  const [videoEn, setVideoEn] = useState(null);
  const { t, i18n } = useTranslation(["Recipes"]);

  const userState = useSelector((state) => state.user);

  useEffect(() => {
    const fetchProvince = async () => {
      try {
        const provinceResponse = await fetch(
          `${process.env.REACT_APP_API_URL}/Localization/GetProvince`,
          {
            method: "GET",
            withCredentials: true,
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userState.token}`,
            },
          }
        );

        const provinceData = await provinceResponse.json();
        TreeProvince(provinceData);
      } catch (e) {}
    };

    fetchProvince();
  }, []);

  // Populate fields when `recipe` changes
  useEffect(() => {
    if (isNew) {
      setFormData({
        nameFr: "",
        nameEn: "",
        category: null,
        ingredientsFr: "",
        ingredientsEn: "",
        decorationsFr: "",
        decorationsEn: "",
        utensilsFr: "",
        utensilsEn: "",
        reducedPortionFr: "",
        reducedPortionEn: "",
        takeAwayFr: "",
        takeAwayEn: "",
        recipeFr: "",
        recipeEn: "",
        province: [],
        images: [],
        uploadedVideoFr: "",
        uploadedVideoEn: "",
        videoFr: [],
        videoEn: [],
      });
      setVisibilityActive(false);
      setStartDate(null);
      setEndDate(null);
      setUploadedFiles([]);
      setSelectedCategory(null);
    } else if (recipe) {
      fetchVideos();
      // Populate form data for editing an existing recipe
      setFormData((prev) => ({
        ...prev,
        active: recipe.estActif || false,
        nameFr: recipe.nomFr || "",
        nameEn: recipe.nomEn || "",
        category: recipe.idRecetteCategorie
          ? categories.find((cat) => cat.id === recipe.idRecetteCategorie)
          : null,
        ingredientsFr: recipe.ingredientFr || "",
        ingredientsEn: recipe.ingredientEn || "",
        decorationsFr: recipe.decorationFr || "",
        decorationsEn: recipe.decorationEn || "",
        utensilsFr: recipe.ustensileFr || "",
        utensilsEn: recipe.ustensileEn || "",
        reducedPortionFr: recipe.portionMinFr || "",
        reducedPortionEn: recipe.portionMinEn || "",
        takeAwayFr: recipe.emporterFr || "",
        takeAwayEn: recipe.emporterEn || "",
        recipeFr: recipe.recetteFr || "",
        recipeEn: recipe.recetteEn || "",
        images: Array.isArray(images)
          ? images.map((imageObj) => ({
              type: "existing",
              url: imageObj.images?.[0] || "", // Access the nested images array
              format: "image/png",
            }))
          : [],
        videoFr: videos?.videoFr
          ? [
              {
                type: "existing",
                url: videos.videoFr,
                format: "video/mp4",
              },
            ]
          : [],
        videoEn: videos?.videoEn
          ? [
              {
                type: "existing",
                url: videos.videoEn,
                format: "video/mp4",
              },
            ]
          : [],
      }));
      setVisibilityActive(recipe.DateActivationDebut ? true : false);
      setStartDate(
        recipe.dateActivationDebut ? dayjs(recipe.dateActivationDebut) : null
      );
      setEndDate(
        recipe.dateActivationFin ? dayjs(recipe.dateActivationFin) : null
      );
      setUploadedFiles(
        recipe.image
          ? [
              {
                type: "existing",
                url: recipe.image,
                format: recipe.formatImage,
              },
            ]
          : []
      );
      setSelectedCategory(
        categories.find((cat) => cat.id === recipe.idRecetteCategorie)
      );
      setMainImage(
        recipe.image
          ? [
              {
                type: "existing",
                url: recipe.image,
                format: recipe.formatImage,
              },
            ]
          : []
      );
      setAdditionalImages(recipe.images);
      setVideoFr(recipe.videoFr);
      setVideoEn(recipe.videoEn);
    }
    if (province) {
      setFormData((prevData) => ({
        ...prevData, // Preserve all existing fields in the state
        province: province, // Update the 'province' field
      }));
    }
  }, [recipe, isNew, categories, images]);

  const fetchVideos = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/Recipe/GetVideoByRecipe/${recipe.id}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${userState.token}`,
          },
        }
      );
      if (!response.ok) {
        throw new Error("Failed to fetch videos");
      }
      const data = await response.json();
      setVideos(data);
    } catch (error) {
      console.error("Error fetching videos:", error);
    }
  };

  useEffect(() => {
    // Populate the TreeViewProvince and match selected nodes with province IDs
    const fetchProvince = async () => {
      try {
        const provinceResponse = await fetch(
          `${process.env.REACT_APP_API_URL}/Localization/GetProvince`,
          {
            method: "GET",
            withCredentials: true,
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userState.token}`,
            },
          }
        );

        const provinceData = await provinceResponse.json();
        const treeData = TreeProvince(provinceData);

        // Match the selected provinces by their IDs
        if (province && Array.isArray(province)) {
          const matchedIds = matchProvinceIds(treeData, province);
          setSelectedNodes(Number(matchedIds));
        }
      } catch (e) {
        console.error("Failed to fetch provinces:", e);
      }
    };

    fetchProvince();
  }, [province]); // Run when `province` or tree changes

  useEffect(() => {
    if (province) {
      console.log("Incoming Province Data:", province);

      // Ensure `TreeViewProvince` is already populated
      if (TreeViewProvince && Array.isArray(TreeViewProvince)) {
        // Match province IDs to TreeView nodes
        const matchedIds = matchProvinceIds(TreeViewProvince, province);

        // Set selected nodes
        setSelectedNodes(matchedIds);
      } else {
        console.warn("TreeViewProvince is not populated yet.");
      }
    }
  }, [province, TreeViewProvince]); // Run only when `province` or `TreeViewProvince` changes

  /**
   * Match selected province IDs to the tree structure
   * @param {Array} tree - Tree data structure
   * @param {Array} ids - Array of province IDs
   * @returns {Array} - Array of matching IDs
   */
  const matchProvinceIds = (tree, ids) => {
    const matchedIds = [];

    const traverseTree = (node) => {
      if (ids.includes(node.id)) {
        matchedIds.push(node.id);
      }
      if (node.children) {
        node.children.forEach(traverseTree);
      }
    };

    tree.forEach(traverseTree);
    return matchedIds;
  };

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const files = Array.from(event.dataTransfer.files || []);
    setFormData((prev) => ({
      ...prev,
      images: [...prev.images, ...files],
    }));
  };

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files || []);
    setFormData((prev) => ({
      ...prev,
      images: [...prev.images, ...files],
    }));
  };

  const handleSave = async () => {
    const form = new FormData();

    // Append RecetteDTO properties
    form.append("Id", recipe?.id || 0); // For EditRecipe, Id must be sent

    form.append("IdRecetteCategorie", selectedCategory?.id || 0);

    form.append("NomFr", formData.nameFr);
    form.append("NomEn", formData.nameEn);
    form.append("IngredientFr", formData.ingredientsFr || null);
    form.append("IngredientEn", formData.ingredientsEn || null);
    form.append("DecorationFr", formData.decorationsFr || null);
    form.append("DecorationEn", formData.decorationsEn || null);
    form.append("UstensileFr", formData.utensilsFr || null);
    form.append("UstensileEn", formData.utensilsEn || null);
    form.append("RecetteFr", formData.recipeFr || null);
    form.append("RecetteEn", formData.recipeEn || null);
    form.append("PortionMinFr", formData.reducedPortionFr || null);
    form.append("PortionMinEn", formData.reducedPortionEn || null);
    form.append("EmporterFr", formData.takeAwayFr || null);
    form.append("EmporterEn", formData.takeAwayEn || null);
    form.append("DateActivationDebut", startDate?.toISOString() || "");
    form.append("DateActivationFin", endDate?.toISOString() || "");
    form.append("EstActif", !active || false);

    // Filter valid provinces and remove duplicates
    const validProvinces = Array.from(
      new Set(selectedNodes.filter((id) => id !== null && Number.isInteger(id)))
    );

    // Append each province ID individually
    if (validProvinces.length > 0) {
      validProvinces.forEach((id) => {
        form.append("Province", id);
      });
    }

    // Add new images
    formData.images.forEach((file, index) => {
      if (file.type !== "existing") {
        form.append(`UploadedImages`, file);
      }
    });

    // Add existing images if needed
    const existingImages = formData.images
      .filter((file) => file.type === "existing")
      .map((file) => file.url);

    /*if (existingImages.length > 0) {
      form.append("Images", JSON.stringify(existingImages));
    }*/

    // Handle the French video file
    if (videoFilesFr) {
      // Convert the file to an array buffer (binary data)
      const arrayBuffer = await videoFilesFr.arrayBuffer();

      // Create a Blob and append it to the form under the correct key
      form.append(
        "UploadedVideoFr",
        new Blob([arrayBuffer]),
        videoFilesFr.name
      );
    }

    // Handle the English video file
    if (videoFilesEn) {
      form.append("UploadedVideoEn", videoFilesEn);
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/Recipe/${
          isNew ? "AddRecipe" : "EditRecipe"
        }`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${userState.token}`,
          },
          credentials: "include",
          body: form,
        }
      );

      if (!response.ok) throw new Error("Failed to save recipe");

      const result = await response.json();
      console.log("Saved Recipe:", result);
      onClose();
    } catch (error) {
      console.error("Error saving recipe:", error);
    }
  };

  const handleRemoveFile = (type, index = null) => {
    switch (type) {
      case "mainImage":
        setMainImage(null);
        break;
      case "additionalImage":
        setAdditionalImages((prev) => prev.filter((_, i) => i !== index));
        break;
      case "videoFr":
        setVideoFr(null);
        break;
      case "videoEn":
        setVideoEn(null);
        break;
    }
  };

  const TreeProvince = (data) => {
    if (!Array.isArray(data)) {
      console.error("Invalid province data:", data);
      return [];
    }

    const tree = [];
    let ids = 0;

    data.forEach((item) => {
      let country = tree.find((p) => p.paysFr === item.paysFr);
      if (!country) {
        country = {
          id: ids++,
          paysFr: item.paysFr,
          paysEn: item.paysEn,
          children: [],
        };
        tree.push(country);
      }

      let province = country.children.find(
        (p) => p.provinceFr === item.provinceFr
      );
      if (!province) {
        province = {
          id: item.noProvince,
          parent: country.id,
          provinceFr: item.provinceFr,
          provinceEn: item.provinceEn,
          children: [],
        };
        country.children.push(province);
      }
    });

    setTreeViewProvince(tree);
    return tree;
  };

  const handleExpandClick = (event) => {
    // prevent the click event from propagating to the checkbox
    event.stopPropagation();
  };

  const handleNodeSelect = (event, nodeId) => {
    event.stopPropagation();

    if (!Array.isArray(selectedNodes)) {
      setSelectedNodes([]); // Ensure it's an array
    }

    const allChild = getAllChild(nodeId);
    const fathers = getAllFathers(nodeId);

    if (selectedNodes.includes(nodeId)) {
      // Deselect node and its children/fathers
      setSelectedNodes((prev) =>
        prev.filter((id) => !allChild.concat(fathers).includes(id))
      );
    } else {
      // Select node and update parents if needed
      setSelectedNodes((prev) => [...prev, nodeId, ...allChild]);
    }
  };

  // Helper function to handle selecting nodes and updating parents
  const handleSelectNode = (nodeId, allChild, fathers) => {
    const ToBeChecked = [...allChild];
    const ToBeIndeterminate = [];

    fathers.forEach((fatherId) => {
      const fatherNode = bfsSearch(TreeViewProvince, fatherId);

      if (isAllChildrenChecked(fatherNode, ToBeChecked)) {
        ToBeChecked.push(fatherId);
      } else if (isAtLeastOneChildIsChecked(fatherNode, ToBeChecked)) {
        ToBeIndeterminate.push(fatherId);
      }
    });

    setSelectedNodes((prev) => prev.concat(ToBeChecked));
    setIndeterminateCheckbox((prev) => prev.concat(ToBeIndeterminate));

    updateNoEmplacementOnSelect(nodeId, allChild);
  };

  // Handle updating noEmplacement when deselecting
  const updateNoEmplacementOnDeselect = async (nodeId, allChild) => {
    const nodeData = bfsSearch(TreeViewProvince, nodeId);

    if (allChild.length > 1) {
      let leafNodes = [];
      getLeafNoEmplacement(leafNodes, nodeData);
      const diff = noEmplacement.filter((x) => !leafNodes.includes(x));
      await setNoEmplacement(diff);
    } else {
      await setNoEmplacement((prev) =>
        prev.filter((p) => p !== nodeData.noEmplacement)
      );
    }
  };

  // Handle updating noEmplacement when selecting
  const updateNoEmplacementOnSelect = async (nodeId, allChild) => {
    const nodeData = bfsSearch(TreeViewProvince, nodeId);

    if (allChild.length > 1) {
      let leafNodes = [];
      getLeafNoEmplacement(leafNodes, nodeData);

      const diff = leafNodes.filter((x) => !noEmplacement.includes(x));
      await setNoEmplacement((prev) => prev.concat(diff));
    } else {
      await setNoEmplacement((prev) => prev.concat(nodeData.noEmplacement));
    }
  };

  function getLeafNoEmplacement(leafNodes, obj) {
    if (obj.children) {
      obj.children.forEach(function (child) {
        getLeafNoEmplacement(leafNodes, child);
      });
    } else {
      leafNodes.push(obj.noEmplacement);
    }
  }

  const bfsSearch = (graph, targetId) => {
    const queue = [...graph];

    while (queue.length > 0) {
      const currNode = queue.shift();
      if (currNode.id === targetId) {
        return currNode;
      }
      if (currNode.children) {
        queue.push(...currNode.children);
      }
    }
    return []; // Target node not found
  };

  const getAllChild = (id) => {
    return getAllIds(bfsSearch(TreeViewProvince, id));
  };

  const getAllFathers = (id, list = []) => {
    const node = bfsSearch(TreeViewProvince, id);
    list.push(node.parent);
    if (node.parent) {
      return getAllFathers(node.parent, list);
    }
    return list;
  };

  function isAllChildrenChecked(node, test) {
    const leafChild = [];
    getLeafNodes(leafChild, node);

    const nodeIdIndex = leafChild.indexOf(node.id);
    leafChild.splice(nodeIdIndex, 1);

    for (let i = 0; i < leafChild.length; i++) {
      if (test.indexOf(leafChild[i].id) === -1) {
        return false;
      }
    }
    return true;
  }

  function isAtLeastOneChildIsChecked(node, list) {
    const allChild = getAllChild(node.id);
    for (let i = 0; i < allChild.length; i++) {
      if (list.includes(allChild[i])) {
        return true;
      }
    }
    return false;
  }

  function getLeafNodes(leafNodes, obj) {
    if (obj.children) {
      obj.children.forEach(function (child) {
        getLeafNodes(leafNodes, child);
      });
    } else {
      leafNodes.push(obj);
    }
  }

  function getAllIds(node, idList = []) {
    idList.push(node.id);
    if (node.children) {
      node.children.forEach((child) => getAllIds(child, idList));
    }
    return idList;
  }

  useEffect(() => {
    if (images) {
      setCurrentImage(images);
    }
  }, [images]);

  const handleRemoveImage = (index) => {
    setFormData((prev) => ({
      ...prev,
      images: prev.images.filter((_, i) => i !== index),
    }));
  };

  const handleReplaceImage = (e) => {
    if (e.target.files && e.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setCurrentImage(e.target.result);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const handleVideoChange = (lang, event) => {
    const files = Array.from(event.target.files || []);
    setFormData((prev) => ({
      ...prev,
      [lang === "FR" ? "videoFr" : "videoEn"]: files.map((file) => ({
        type: "new",
        file,
        url: URL.createObjectURL(file),
      })),
    }));
  };

  const handleRemoveVideo = (lang) => {
    setFormData((prev) => ({
      ...prev,
      [lang === "FR" ? "videoFr" : "videoEn"]: [],
    }));
  };

  // Add reorder function
  const reorderImages = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  // Add drag end handler
  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const reorderedImages = reorderImages(
      formData.images,
      result.source.index,
      result.destination.index
    );

    setFormData((prev) => ({
      ...prev,
      images: reorderedImages,
    }));
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
      <DialogTitle>{isNew ? "Add recipe" : "Recipes Editor"}</DialogTitle>
      <DialogContent>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Box sx={{ p: 2 }}>
            {/* Checkboxes */}
            <Box sx={{ display: "flex", alignItems: "center", gap: 2, mb: 2 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!formData.active}
                    onChange={(e) => setActive(e.target.checked)}
                  />
                }
                label="Est inactive"
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={visibilityActive}
                    onChange={(e) => setVisibilityActive(e.target.checked)}
                  />
                }
                label="Activer la période de visibilité"
              />
            </Box>

            {/* Date Picker Section */}
            {visibilityActive && (
              <Box
                sx={{ display: "flex", alignItems: "center", gap: 2, mb: 2 }}
              >
                <Typography>Du</Typography>
                <DatePicker
                  label={t("StartDate")}
                  value={startDate}
                  onChange={(date) => setStartDate(date)}
                  renderInput={(params) => (
                    <TextField {...params} size="small" />
                  )}
                />
                <Typography>au</Typography>
                <DatePicker
                  label={t("EndDate")}
                  value={endDate}
                  onChange={(date) => setEndDate(date)}
                  renderInput={(params) => (
                    <TextField {...params} size="small" />
                  )}
                />
              </Box>
            )}

            {/* Tabs */}
            <Tabs value={tabIndex} onChange={handleTabChange} sx={{ mb: 2 }}>
              <Tab label="Information" />
              <Tab label="Recette" />
              <Tab label="Portion réduite" />
              <Tab label="Emporter" />
              <Tab label="Ingrédient" />
              <Tab label="Décoration" />
              <Tab label="Vaisselle" />
              <Tab label="Location" />
            </Tabs>

            {/* Tab Content */}
            <Box>
              {tabIndex === 0 && (
                <>
                  <Box>
                    <TextField
                      name="nameFr"
                      label="Nom en français"
                      fullWidth
                      margin="normal"
                      value={formData.nameFr}
                      onChange={handleInputChange}
                    />
                    <TextField
                      name="nameEn"
                      label="Nom en anglais"
                      fullWidth
                      margin="normal"
                      value={formData.nameEn}
                      onChange={handleInputChange}
                    />
                    <Autocomplete
                      options={categories}
                      getOptionLabel={(option) =>
                        userState.lang == "EN" ? option.textEn : option.textFr
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Recipe Category"
                          fullWidth
                          margin="normal"
                        />
                      )}
                      value={selectedCategory}
                      onChange={(event, value) => setSelectedCategory(value)}
                    />
                  </Box>
                  {/* Upload Buttons and Preview Section */}
                  <Box sx={{ mt: 2 }}>
                    {/* Upload Buttons Row */}
                    <Box
                      sx={{
                        display: "flex",
                        gap: 2,
                        mb: 2,
                        alignItems: "center",
                      }}
                    >
                      {/* Image Upload */}
                      <Box
                        sx={{
                          border: "2px dashed #ccc",
                          borderRadius: 2,
                          p: 2,
                          textAlign: "center",
                          backgroundColor: "#fafafa",
                          flex: 1,
                          cursor: "pointer",
                          "&:hover": {
                            backgroundColor: "#f0f0f0",
                          },
                        }}
                        onDrop={handleDrop}
                        onDragOver={(e) => e.preventDefault()}
                        onDragEnter={(e) => e.preventDefault()}
                      >
                        <input
                          type="file"
                          accept="image/*"
                          multiple
                          onChange={handleFileChange}
                          style={{ display: "none" }}
                          id="image-upload-input"
                        />
                        <label
                          htmlFor="image-upload-input"
                          style={{ cursor: "pointer" }}
                        >
                          <Typography variant="body2">
                            Drag and drop photos here, or click to upload
                          </Typography>
                        </label>
                      </Box>

                      {/* Video Upload Buttons */}
                      <Box sx={{ display: "flex", gap: 2 }}>
                        {/* French Video Upload */}
                        <input
                          type="file"
                          accept="video/*"
                          onChange={(e) => handleVideoChange("FR", e)}
                          style={{ display: "none" }}
                          id="video-fr-upload"
                        />
                        <label htmlFor="video-fr-upload">
                          <Button
                            variant="outlined"
                            component="span"
                            startIcon={<CloudUploadIcon />}
                          >
                            Upload Video FR
                          </Button>
                        </label>

                        {/* English Video Upload */}
                        <input
                          type="file"
                          accept="video/*"
                          onChange={(e) => handleVideoChange("EN", e)}
                          style={{ display: "none" }}
                          id="video-en-upload"
                        />
                        <label htmlFor="video-en-upload">
                          <Button
                            variant="outlined"
                            component="span"
                            startIcon={<CloudUploadIcon />}
                          >
                            Upload Video EN
                          </Button>
                        </label>
                      </Box>
                    </Box>

                    {/* Previews Row */}
                    <DragDropContext onDragEnd={onDragEnd}>
                      <Droppable droppableId="droppable" direction="horizontal">
                        {(provided) => (
                          <Box
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              flexWrap: "wrap",
                              gap: 2,
                              alignItems: "flex-start",
                            }}
                          >
                            {/* Images Preview */}
                            {formData.images?.length > 0 &&
                              formData.images.map((file, index) => {
                                const isExisting = file.type === "existing";
                                const fileURL =
                                  isExisting && file.url
                                    ? `data:image/png;base64,${file.url}`
                                    : isExisting
                                    ? ""
                                    : URL.createObjectURL(file);

                                if (!fileURL) return null;

                                return (
                                  <Draggable
                                    key={`image-${index}`}
                                    draggableId={`image-${index}`}
                                    index={index}
                                  >
                                    {(provided, snapshot) => (
                                      <Box
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        sx={{
                                          border: "1px solid #ccc",
                                          borderRadius: 4,
                                          padding: 1,
                                          textAlign: "center",
                                          width: 200,
                                          backgroundColor: snapshot.isDragging
                                            ? "#f5f5f5"
                                            : "white",
                                          "&:hover": {
                                            boxShadow: 2,
                                          },
                                        }}
                                      >
                                        <Box sx={{ position: "relative" }}>
                                          <Typography
                                            variant="caption"
                                            sx={{
                                              position: "absolute",
                                              top: 4,
                                              left: 4,
                                              backgroundColor:
                                                "rgba(0,0,0,0.6)",
                                              color: "white",
                                              padding: "2px 6px",
                                              borderRadius: 1,
                                            }}
                                          >
                                            {index + 1}
                                          </Typography>
                                          <img
                                            src={fileURL}
                                            alt={`Preview ${index + 1}`}
                                            style={{
                                              width: "100%",
                                              height: 150,
                                              objectFit: "contain",
                                              borderRadius: 4,
                                            }}
                                          />
                                        </Box>
                                        <Typography variant="caption">
                                          {isExisting
                                            ? `Image ${index + 1}`
                                            : file.name || `Image ${index + 1}`}
                                        </Typography>
                                        <Button
                                          color="error"
                                          size="small"
                                          onClick={() =>
                                            handleRemoveImage(index)
                                          }
                                          fullWidth
                                          sx={{ mt: 1 }}
                                        >
                                          Remove
                                        </Button>
                                      </Box>
                                    )}
                                  </Draggable>
                                );
                              })}
                            {provided.placeholder}

                            {/* French Video Preview */}
                            {formData.videoFr?.length > 0 &&
                              formData.videoFr.some(
                                (video) =>
                                  (video.type === "existing" && video.url) ||
                                  (video.type === "new" && video.file)
                              ) &&
                              formData.videoFr.map((video, index) => (
                                <Box
                                  key={`video-fr-${index}`}
                                  sx={{
                                    border: "1px solid #ccc",
                                    borderRadius: 4,
                                    padding: 1,
                                    textAlign: "center",
                                    width: 200, // Match image width
                                  }}
                                >
                                  <video
                                    controls
                                    style={{
                                      width: "100%",
                                      height: 150, // Match image height
                                      objectFit: "contain",
                                      borderRadius: 4,
                                    }}
                                    src={
                                      video.type === "existing"
                                        ? `data:video/mp4;base64,${video.url}`
                                        : URL.createObjectURL(video.file)
                                    }
                                  />
                                  <Typography variant="caption">
                                    French Video
                                  </Typography>
                                  <Button
                                    color="error"
                                    size="small"
                                    onClick={() => handleRemoveVideo("FR")}
                                    fullWidth
                                    sx={{ mt: 1 }}
                                  >
                                    Remove
                                  </Button>
                                </Box>
                              ))}

                            {/* English Video Preview */}
                            {formData.videoEn?.length > 0 &&
                              formData.videoEn.some(
                                (video) =>
                                  (video.type === "existing" && video.url) ||
                                  (video.type === "new" && video.file)
                              ) &&
                              formData.videoEn.map((video, index) => (
                                <Box
                                  key={`video-en-${index}`}
                                  sx={{
                                    border: "1px solid #ccc",
                                    borderRadius: 4,
                                    padding: 1,
                                    textAlign: "center",
                                    width: 200, // Match image width
                                  }}
                                >
                                  <video
                                    controls
                                    style={{
                                      width: "100%",
                                      height: 150, // Match image height
                                      objectFit: "contain",
                                      borderRadius: 4,
                                    }}
                                    src={
                                      video.type === "existing"
                                        ? `data:video/mp4;base64,${video.url}`
                                        : URL.createObjectURL(video.file)
                                    }
                                  />
                                  <Typography variant="caption">
                                    English Video
                                  </Typography>
                                  <Button
                                    color="error"
                                    size="small"
                                    onClick={() => handleRemoveVideo("EN")}
                                    fullWidth
                                    sx={{ mt: 1 }}
                                  >
                                    Remove
                                  </Button>
                                </Box>
                              ))}
                          </Box>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </Box>
                </>
              )}
              {tabIndex === 1 && (
                <Box>
                  <Typography variant="body2" sx={{ mb: 1 }}>
                    La recette en francais
                  </Typography>
                  <TextField
                    name="recipeFr"
                    multiline
                    rows={5}
                    fullWidth
                    value={formData.recipeFr}
                    onChange={handleInputChange}
                  />
                  <Typography variant="body2" sx={{ mt: 2, mb: 1 }}>
                    La recette en anglais
                  </Typography>
                  <TextField
                    name="recipeEn"
                    multiline
                    rows={5}
                    fullWidth
                    value={formData.recipeEn}
                    onChange={handleInputChange}
                  />
                </Box>
              )}
              {tabIndex === 2 && (
                <Box>
                  <Typography variant="body2" sx={{ mb: 1 }}>
                    La portion reduite en francais
                  </Typography>
                  <TextField
                    multiline
                    rows={5}
                    name="reducedPortionFr"
                    fullWidth
                    variant="outlined"
                    value={formData.reducedPortionFr}
                    onChange={handleInputChange}
                  />

                  <Typography variant="body2" sx={{ mt: 2, mb: 1 }}>
                    La portion reduite en anglais
                  </Typography>
                  <TextField
                    multiline
                    name="reducedPortionEn"
                    rows={5}
                    fullWidth
                    variant="outlined"
                    value={formData.reducedPortionEn}
                    onChange={handleInputChange}
                  />
                </Box>
              )}
              {tabIndex === 3 && (
                <Box>
                  <Typography variant="body2" sx={{ mb: 1 }}>
                    Emporter en francais
                  </Typography>
                  <TextField
                    multiline
                    name="takeAwayFr"
                    rows={5}
                    fullWidth
                    variant="outlined"
                    value={formData.takeAwayFr}
                    onChange={handleInputChange}
                  />

                  <Typography variant="body2" sx={{ mt: 2, mb: 1 }}>
                    Emporter en anglais
                  </Typography>
                  <TextField
                    multiline
                    name="takeAwayEn"
                    rows={5}
                    fullWidth
                    variant="outlined"
                    value={formData.takeAwayEn}
                    onChange={handleInputChange}
                  />
                </Box>
              )}
              {tabIndex === 4 && (
                <Box>
                  <Typography variant="body2" sx={{ mb: 1 }}>
                    La liste des ingrédients en français (un ingrédient par
                    ligne)
                  </Typography>
                  <TextField
                    multiline
                    rows={5}
                    name="ingredientsFr"
                    fullWidth
                    variant="outlined"
                    value={formData.ingredientsFr}
                    onChange={handleInputChange}
                  />

                  <Typography variant="body2" sx={{ mt: 2, mb: 1 }}>
                    La liste des ingrédients en anglais (un ingrédient par
                    ligne)
                  </Typography>
                  <TextField
                    multiline
                    rows={5}
                    name="ingredientsEn"
                    fullWidth
                    variant="outlined"
                    value={formData.ingredientsEn}
                    onChange={handleInputChange}
                  />
                </Box>
              )}
              {tabIndex === 5 && (
                <Box>
                  <Typography variant="body2" sx={{ mb: 1 }}>
                    La liste des decorations en français (une decoration par
                    ligne)
                  </Typography>
                  <TextField
                    multiline
                    rows={5}
                    name="decorationsFr"
                    fullWidth
                    variant="outlined"
                    value={formData.decorationsFr}
                    onChange={handleInputChange}
                  />

                  <Typography variant="body2" sx={{ mt: 2, mb: 1 }}>
                    La liste des decorations en anglais (une decoration par
                    ligne)
                  </Typography>
                  <TextField
                    multiline
                    rows={5}
                    fullWidth
                    name="decorationsEn"
                    variant="outlined"
                    value={formData.decorationsEn}
                    onChange={handleInputChange}
                  />
                </Box>
              )}
              {tabIndex === 6 && (
                <Box>
                  <Typography variant="body2" sx={{ mb: 1 }}>
                    La liste des vaiselles en français (un item par ligne)
                  </Typography>
                  <TextField
                    multiline
                    rows={5}
                    fullWidth
                    name="utensilsFr"
                    variant="outlined"
                    value={formData.utensilsFr}
                    onChange={handleInputChange}
                  />

                  <Typography variant="body2" sx={{ mt: 2, mb: 1 }}>
                    La liste des vaiselles en anglais (un item par ligne)
                  </Typography>
                  <TextField
                    multiline
                    rows={5}
                    name="utensilsEn"
                    fullWidth
                    variant="outlined"
                    value={formData.utensilsEn}
                    onChange={handleInputChange}
                  />
                </Box>
              )}
              {tabIndex === 7 && (
                <Box>
                  <Typography variant="body2" sx={{ mb: 1 }}>
                    Province
                  </Typography>
                  <Paper sx={{ m: 2 }}>
                    <CustomTreeView
                      TreeViewProvince={TreeViewProvince}
                      handleExpandClick={handleExpandClick}
                      handleNodeSelect={handleNodeSelect}
                      indeterminateCheckbox={indeterminateCheckbox}
                      selectedNodes={
                        Array.isArray(selectedNodes) ? selectedNodes : []
                      }
                      readOnly={false}
                    />
                  </Paper>
                </Box>
              )}
            </Box>
            <Box
              sx={{
                mt: 3,
                display: "flex",
                justifyContent: "flex-end",
                gap: 2,
              }}
            >
              <Button variant="contained" color="primary" onClick={handleSave}>
                Save
              </Button>
              <Button variant="outlined" color="secondary" onClick={onClose}>
                Cancel
              </Button>
            </Box>
          </Box>
        </LocalizationProvider>
      </DialogContent>
    </Dialog>
  );
}

export default AddRecipe;
