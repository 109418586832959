import React from "react";
import TreeView from "@mui/lab/TreeView";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CustomTreeNode from "./CustomTreeNode";

function CustomTreeView({
  TreeViewProvince,
  selectedNodes,
  handleNodeSelect,
  handleExpandClick,
  indeterminateCheckbox,
  readOnly,
}) {
  return (
    <TreeView
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
    >
      {TreeViewProvince.map((pays) => (
        <CustomTreeNode
          key={pays.id}
          node={pays}
          selectedNodes={selectedNodes}
          handleNodeSelect={handleNodeSelect}
          handleExpandClick={handleExpandClick}
          indeterminateCheckbox={indeterminateCheckbox}
          readOnly={readOnly}
        />
      ))}
    </TreeView>
  );
}

export default CustomTreeView;
