import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";

import "../../css/DetailsShareholder.css";
import { useSelector } from "react-redux";

function DetailsShareholder(props) {
  const { t, i18n } = useTranslation(["Articles"]);
  const [ville, setVille] = useState("");
  const [region, setRegion] = useState("");
  const [adresse, setAdresse] = useState("");
  const [codePostal, setCodePostal] = useState("");

  const [regions, setRegions] = useState([]);
  const [country, setCountry] = useState("");
  const [countries, setCountries] = useState([]);
  const [province, setProvince] = useState("");
  const [provinces, setProvinces] = useState([]);

  const userState = useSelector((state) => state.user);

  useEffect(() => {
    GetCountries();
  }, []);

  useEffect(() => {
    if (country) {
      GetProvinces();
    }
  }, [country]);

  useEffect(() => {
    if (province) {
      GetRegions();
    }
  }, [province]);

  useEffect(() => {
    if (props.adresse) {
      setVille(props.adresse.ville);
      setAdresse(props.adresse.adresse);
      setCodePostal(props.adresse.codePostal);
      setRegion(props.adresse.nomRegionFr);
      setProvince(props.adresse.nomProvinceFr);
      setCountry(props.adresse.nomPaysFr);
    }
  }, [props]);

  const GetCountries = async () => {
    fetch(`${process.env.REACT_APP_API_URL}/Localization/GetCountries`, {
      method: "GET",
      withCredentials: true,
      credentials: "include",

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userState.token}`,
      },
    })
      .then((results) => {
        return results.json();
      })
      .then((data) => {
        let paysFr = [];
        let paysEn = [];

        data.forEach((element) => {
          paysFr.push(element.paysFr);
          paysEn.push(element.paysEn);
        });

        var uniquePaysFR = paysFr.filter((x, i) => paysFr.indexOf(x) === i);
        var uniquePaysEN = paysEn.filter((x, i) => paysEn.indexOf(x) === i);
        setCountries(data);
      });
  };

  const GetProvinces = async () => {
    fetch(
      `${process.env.REACT_APP_API_URL}/Localization/GetProvinceByCountry/${country.noPays}`,
      {
        method: "GET",
        withCredentials: true,
        credentials: "include",

        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userState.token}`,
        },
      }
    )
      .then((results) => {
        return results.json();
      })
      .then((data) => {
        let ProvinceFr = [];
        let ProvinceEn = [];

        data.forEach((element) => {
          ProvinceFr.push(element.provinceFr);
          ProvinceEn.push(element.provinceEn);
        });

        var uniqueProvinceEN = ProvinceEn.filter(
          (x, i) => ProvinceEn.indexOf(x) === i
        );
        var uniqueProvinceFR = ProvinceFr.filter(
          (x, i) => ProvinceFr.indexOf(x) === i
        );

        setProvinces(data);
      });
  };

  const GetRegions = async () => {
    fetch(
      `${process.env.REACT_APP_API_URL}/Localization/GetRegionsByProvinces/${province.noProvince}`,
      {
        method: "GET",
        withCredentials: true,
        credentials: "include",

        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userState.token}`,
        },
      }
    )
      .then((results) => {
        return results.json();
      })
      .then((data) => {
        let RegionFr = [];
        let RegionEn = [];

        data.forEach((element) => {
          RegionFr.push(element.regionFr);
          RegionEn.push(element.regionEn);
        });

        var uniqueRegionEN = RegionEn.filter(
          (x, i) => RegionEn.indexOf(x) === i
        );
        var uniqueRegionFR = RegionFr.filter(
          (x, i) => RegionFr.indexOf(x) === i
        );

        setRegions(data);
      });
  };

  return (
    <div className="DetailsShareholder__Modal">
      <h5> Adresse</h5>
      <Box sx={{ padding: 5 }}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={4}>
            <TextField
              id="Nom"
              name="Nom"
              label="adresse"
              fullWidth
              size="small"
              autoComplete="off"
              variant="outlined"
              value={adresse}
              onChange={(e) => setAdresse(e.currentTarget.value)}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              id="lastname"
              name="lastname"
              label="ville"
              fullWidth
              size="small"
              autoComplete="off"
              variant="outlined"
              value={ville}
              onChange={(e) => setVille(e.currentTarget.value)}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField
              id="Username"
              name="Username"
              label="Code Postal"
              fullWidth
              size="small"
              autoComplete="off"
              variant="outlined"
              value={codePostal}
              onChange={(e) => setCodePostal(e.currentTarget.value)}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <InputLabel id="CountryLabel">Country</InputLabel>
              <Select
                id="Country"
                name="Country"
                label="Country"
                fullWidth
                size="small"
                autoComplete="off"
                variant="outlined"
                value={country}
                onChange={(e) => setCountry(e.target.value)}
              >
                {countries.map((c, i) => (
                  <MenuItem key={i} value={c}>
                    {c.nomFr}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <InputLabel id="ProvinceLabel">Province</InputLabel>
              <Select
                id="Province"
                name="Province"
                label="Province"
                fullWidth
                size="small"
                autoComplete="off"
                variant="outlined"
                value={province}
                onChange={(e) => setProvince(e.target.value)}
              >
                {provinces.map((p, i) => (
                  <MenuItem key={i} value={p}>
                    {p.nomFr}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <InputLabel id="RegionLabel">Region</InputLabel>
              <Select
                id="Province"
                name="Province"
                label="Province"
                fullWidth
                size="small"
                autoComplete="off"
                variant="outlined"
                value={region}
                onChange={(e) => setRegion(e.target.value)}
              >
                {regions.map((r, i) => (
                  <MenuItem key={i} value={r}>
                    {r.nomFr}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

export default DetailsShareholder;
