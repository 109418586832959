import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  MenuItem,
  Tabs,
  Tab,
  Box,
  Autocomplete,
} from "@mui/material";
import CustomTreeView from "../../Common/Components/CustomTreeView";
import { useSelector } from "react-redux";

function TabPanel({ children, value, index }) {
  return (
    <div hidden={value !== index} style={{ paddingTop: "20px" }}>
      {value === index && children}
    </div>
  );
}

function AddInternalUser({ open, onClose, onSave, locationTree, Departments }) {
  const [activeTab, setActiveTab] = useState(0);
  const [indeterminateCheckbox, setIndeterminateCheckbox] = useState([]);
  const [userData, setUserData] = useState({
    isInactive: false,
    firstname: "",
    lastName: "",
    username: "",
    email: "",
    noTelephone: "",
    nodooliz: "",
    lang: "",
    department: "",
    title: "",
    selectedLocations: [], // Added for location tracking
  });
  const userState = useSelector((state) => state.user);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const validateForm = () => {
    const errors = {};

    if (!userData.firstname) errors.firstname = "First name is required";
    if (!userData.lastName) errors.lastName = "Last name is required";
    if (!userData.email) errors.email = "Email is required";
    if (!userData.username) errors.username = "Username is required";

    // Email format validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (userData.email && !emailRegex.test(userData.email)) {
      errors.email = "Invalid email format";
    }

    return errors;
  };

  const handleSubmit = async () => {
    const errors = validateForm();

    if (Object.keys(errors).length > 0) {
      // Show errors to user
      console.error("Validation errors:", errors);
      alert("Please fill in all required fields correctly.");
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/Employee/CreateInternalEmploye`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userState.token}`,
          },
          credentials: "include",
          body: JSON.stringify({
            isInactive: userData.isInactive,
            firstname: userData.firstname,
            lastName: userData.lastName,
            username: userData.username,
            email: userData.email,
            noCellulaire: userData.noTelephone,
            nodooliz: userData.nodooliz,
            language: userData.lang,
            department: userData.department, // Send just the department ID
            title: userData.title,
            IdEmplacements: userData.selectedLocations || [], // Send selected location IDs
          }),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.text();

      // Close the modal
      onClose();
    } catch (error) {
      console.error("Error adding internal user:", error);
      // Optionally show error to user via alert/snackbar
      alert("Failed to add user. Please try again.");
    }
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleNodeSelectV2 = (event, nodeId) => {
    event.stopPropagation();

    const allChild = getAllChild(nodeId); // Get all child nodes
    const fathers = getAllFathers(nodeId); // Get all parent nodes

    setUserData((prev) => {
      const isSelected = prev.selectedLocations.includes(nodeId);
      let updatedLocations;

      if (isSelected) {
        // If node is already selected, deselect it along with its children and parents
        updatedLocations = prev.selectedLocations.filter(
          (id) => ![nodeId, ...allChild, ...fathers].includes(id)
        );
      } else {
        // If node is not selected, select it along with its children and parents
        updatedLocations = [
          ...new Set([
            ...prev.selectedLocations,
            nodeId,
            ...allChild,
            ...fathers,
          ]),
        ];
      }

      console.log("Updated Locations:", updatedLocations);

      return {
        ...prev,
        selectedLocations: updatedLocations,
      };
    });

    // Update indeterminate states for parent nodes
    updateIndeterminateCheckbox(fathers, nodeId);
  };

  const updateIndeterminateCheckbox = (fathers, nodeId) => {
    setIndeterminateCheckbox((prev) => {
      const newIndeterminate = [...prev];

      fathers.forEach((fatherId) => {
        const fatherNode = bfsSearch(locationTree, fatherId);

        if (isAllChildrenChecked(fatherNode, userData.selectedLocations)) {
          // Remove from indeterminate if all children are checked
          const index = newIndeterminate.indexOf(fatherId);
          if (index > -1) newIndeterminate.splice(index, 1);
        } else if (
          isAtLeastOneChildIsChecked(fatherNode, userData.selectedLocations)
        ) {
          // Add to indeterminate if at least one child is checked
          if (!newIndeterminate.includes(fatherId)) {
            newIndeterminate.push(fatherId);
          }
        }
      });

      return newIndeterminate;
    });
  };

  const bfsSearch = (graph, targetId) => {
    const queue = [...graph];

    while (queue.length > 0) {
      const currNode = queue.shift();
      if (currNode.id === targetId) {
        return currNode;
      }
      if (currNode.children) {
        queue.push(...currNode.children);
      }
    }
    return []; // Target node not found
  };

  const getAllChild = (id) => {
    return getAllIds(bfsSearch(locationTree, id));
  };

  const getAllFathers = (id, list = []) => {
    const node = bfsSearch(locationTree, id);
    list.push(node.parent);
    if (node.parent) {
      return getAllFathers(node.parent, list);
    }
    return list;
  };

  // Utility to check if all children of a node are selected
  function isAllChildrenChecked(node, selectedLocations) {
    const allChildIds = getAllChild(node.id);
    return allChildIds.every((id) => selectedLocations.includes(id));
  }

  // Utility to check if at least one child of a node is selected
  function isAtLeastOneChildIsChecked(node, selectedLocations) {
    const allChildIds = getAllChild(node.id);
    return allChildIds.some((id) => selectedLocations.includes(id));
  }

  function getAllIds(node, idList = []) {
    idList.push(node.id);
    if (node.children) {
      node.children.forEach((child) => getAllIds(child, idList));
    }
    return idList;
  }

  const handleExpandClick = (event, nodeIds) => {
    // Handle expand/collapse if needed
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Add User</DialogTitle>
      <DialogContent>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={activeTab} onChange={handleTabChange}>
            <Tab label="General Information" />
            <Tab label="Location" />
          </Tabs>
        </Box>

        <TabPanel value={activeTab} index={0}>
          <TextField
            fullWidth
            label="First Name"
            name="firstname"
            value={userData.firstname}
            onChange={handleChange}
            margin="dense"
          />

          <TextField
            fullWidth
            label="Last Name"
            name="lastName"
            value={userData.lastName}
            onChange={handleChange}
            margin="dense"
          />

          <TextField
            fullWidth
            label="Username"
            name="username"
            value={userData.username}
            onChange={handleChange}
            margin="dense"
          />

          <TextField
            fullWidth
            label="Email"
            name="email"
            type="email"
            value={userData.email}
            onChange={handleChange}
            margin="dense"
          />

          <TextField
            fullWidth
            label="No Telephone"
            name="noTelephone"
            value={userData.noTelephone}
            onChange={handleChange}
            margin="dense"
          />

          <TextField
            fullWidth
            label="Nodooliz"
            name="nodooliz"
            value={userData.nodooliz}
            onChange={handleChange}
            margin="dense"
          />

          <TextField
            fullWidth
            select
            label="Language"
            name="lang"
            value={userData.lang}
            onChange={handleChange}
            margin="dense"
          >
            <MenuItem value="EN">English</MenuItem>
            <MenuItem value="FR">French</MenuItem>
          </TextField>

          <Autocomplete
            fullWidth
            options={Departments || []}
            getOptionLabel={(option) =>
              typeof option === "string"
                ? option
                : option?.[userData.lang === "FR" ? "textFr" : "textEn"] || ""
            }
            value={userData.department || null}
            onChange={(event, newValue) => {
              setUserData((prev) => ({
                ...prev,
                department: newValue,
              }));
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Department"
                margin="dense"
                fullWidth
              />
            )}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
          />

          <TextField
            fullWidth
            label="Title"
            name="title"
            value={userData.title}
            onChange={handleChange}
            margin="dense"
          />
        </TabPanel>

        <TabPanel value={activeTab} index={1}>
          <Box sx={{ p: 2 }}>
            <CustomTreeView
              TreeViewProvince={locationTree || []}
              selectedNodes={userData.selectedLocations}
              handleNodeSelect={handleNodeSelectV2}
              handleExpandClick={handleExpandClick}
              indeterminateCheckbox={indeterminateCheckbox}
              readOnly={false}
            />
          </Box>
        </TabPanel>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit} variant="contained" color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddInternalUser;
