import React, { useState, useEffect } from "react";
import {
  Grid,
  Paper,
  Box,
  Typography,
  FormControl,
  Autocomplete,
  TextField,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import CustomersPieChart from "../Widgets/PieChart";
import SalesBarChart from "../Widgets/BarChart";
import GaugeChart from "../Widgets/GaugeChart";

// Import your dashboard components
/*import SalesWidget from "./Widgets/SalesWidget";
import EmployeeWidget from "./Widgets/EmployeeWidget";
import InventoryWidget from "./Widgets/InventoryWidget";
import OrdersWidget from "./Widgets/OrdersWidget"; */

function DashboardView() {
  const { t } = useTranslation(["Dashboard"]);
  const userState = useSelector((state) => state.user);
  const [restaurant, setRestaurant] = useState("");
  const [restaurants, setRestaurants] = useState([]);
  const [dashboardData, setDashboardData] = useState({
    numberOfclientsData: [],
    totalSales: [],
    laborCost: 0,
    foodCost: 0,
  });

  const fetchNumberOfClients = async () => {
    try {
      if (!restaurant) return;

      const selectedRestaurant = restaurants.find(
        (r) => r.nomEmplacement === restaurant
      );
      if (!selectedRestaurant) return;

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/Data/GetNumberClients/${selectedRestaurant.noEmplacement}`,
        {
          method: "GET",
          withCredentials: true,
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userState.token}`,
            "Cache-Control": "no-cache",
            Pragma: "no-cache",
          },
        }
      );

      // Check if response is ok
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      // Log the raw response for debugging
      const rawText = await response.text();

      // Try to parse the response
      let data;
      try {
        data = JSON.parse(rawText);
      } catch (parseError) {
        console.error("Failed to parse JSON:", parseError);
        setDashboardData((prevState) => ({
          ...prevState,
          numberOfclientsData: [],
        }));
        return;
      }

      const formattedData = formatDashboardData(data);
      // Set the data in the correct structure
      setDashboardData((prevState) => ({
        ...prevState,
        numberOfclientsData: formattedData,
      }));
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
      // Set empty data on error
      setDashboardData((prevState) => ({
        ...prevState,
        numberOfclientsData: [],
      }));
    }
  };

  const fetchDataSales = async () => {
    try {
      if (!restaurant) return;

      const selectedRestaurant = restaurants.find(
        (r) => r.nomEmplacement === restaurant
      );
      if (!selectedRestaurant) return;

      // Add query parameters for data limiting
      const currentDate = new Date();
      const lastYear = new Date(currentDate);
      lastYear.setFullYear(currentDate.getFullYear() - 1);

      const params = new URLSearchParams({
        startDate: lastYear.toISOString().split("T")[0],
        endDate: currentDate.toISOString().split("T")[0],
      });

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/Data/GetSales/${
          selectedRestaurant.noEmplacement
        }?${params.toString()}`,
        {
          method: "GET",
          withCredentials: true,
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userState.token}`,
            "Cache-Control": "no-cache",
            Pragma: "no-cache",
          },
        }
      );

      const data = await response.json();
      // Process the data
      const formattedData = formatDashboardData(data);

      // Set the formatted data in state
      setDashboardData((prevState) => ({
        ...prevState,
        totalSales: formattedData,
      }));
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
    }
  };

  const fetchLabordCost = async () => {
    try {
      if (!restaurant) return;

      const selectedRestaurant = restaurants.find(
        (r) => r.nomEmplacement === restaurant
      );
      if (!selectedRestaurant) return;

      // Add query parameters for data limiting
      const currentDate = new Date();
      const lastYear = new Date(currentDate);
      lastYear.setFullYear(currentDate.getFullYear() - 1);

      const params = new URLSearchParams({
        startDate: lastYear.toISOString().split("T")[0],
        endDate: currentDate.toISOString().split("T")[0],
      });

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/Data/GetLaborCostByLocation/${
          selectedRestaurant.noEmplacement
        }?${params.toString()}`,
        {
          method: "GET",
          withCredentials: true,
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userState.token}`,
            "Cache-Control": "no-cache",
            Pragma: "no-cache",
          },
        }
      );

      const data = await response.json();

      // Sort the data by period in descending order to get the most recent period
      const sortedData = data.sort((a, b) => {
        const [yearA, periodA] = a.period.split("-P");
        const [yearB, periodB] = b.period.split("-P");

        // Compare years first
        if (yearA !== yearB) {
          return yearB - yearA;
        }
        // If years are equal, compare periods
        return parseInt(periodB) - parseInt(periodA);
      });

      // Get the most recent labor cost
      //const mostRecentLaborCost = sortedData[0]?.laborCost || 0;

      // Set the formatted data in state
      /*setDashboardData((prevState) => ({
        ...prevState,
        laborCost: mostRecentLaborCost,
      }));*/

      // Optional: You might want to store the full history for other uses
      setDashboardData((prevState) => ({
        ...prevState,
        laborCost: sortedData,
      }));
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
    }
  };

  const fetchFoodCost = async () => {
    try {
      if (!restaurant) return;

      const selectedRestaurant = restaurants.find(
        (r) => r.nomEmplacement === restaurant
      );
      if (!selectedRestaurant) return;

      // Add query parameters for data limiting
      const currentDate = new Date();
      const lastYear = new Date(currentDate);
      lastYear.setFullYear(currentDate.getFullYear() - 1);

      const params = new URLSearchParams({
        startDate: lastYear.toISOString().split("T")[0],
        endDate: currentDate.toISOString().split("T")[0],
      });

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/Data/GetFoodCostByLocation/${
          selectedRestaurant.noEmplacement
        }?${params.toString()}`,
        {
          method: "GET",
          withCredentials: true,
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userState.token}`,
            "Cache-Control": "no-cache",
            Pragma: "no-cache",
          },
        }
      );

      const data = await response.json();
      // Process the data
      //const formattedData = formatDashboardData(data);

      console.log(data);

      // Set the formatted data in state
      setDashboardData((prevState) => ({
        ...prevState,
        foodCost: data,
      }));
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
    }
  };

  const fetchRestaurants = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/Localization/GetRestaurantsByUser`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userState.token}`,
        },
      }
    );
    const data = await response.json();

    const headOffice = {
      noEmplacement: 1,
      nomEmplacement: "Bureau Chef / Head Office",
    };
    setRestaurants([headOffice, ...data]);
  };

  useEffect(() => {
    fetchNumberOfClients();
    fetchDataSales();
    fetchLabordCost();
    fetchFoodCost();
  }, [restaurant]);

  useEffect(() => {
    fetchRestaurants();
    // Set up refresh interval if needed
    const interval = setInterval(fetchNumberOfClients, 300000); // Refresh every 5 minutes
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    console.log("Current dashboard data:", dashboardData);
  }, [dashboardData]);

  const handleChangeRestaurant = (event, newValue) => {
    setRestaurant(newValue);
  };

  const formatDashboardData = (data) => {
    // First, sort data by date in descending order
    const sortedData = [...data].sort(
      (a, b) => new Date(b.date) - new Date(a.date)
    );

    // Group data by company and date
    const grouped = sortedData.reduce((acc, item) => {
      const companyKey = item.idCompagnie;
      const date = new Date(item.date);

      if (!acc[companyKey]) {
        acc[companyKey] = {
          daily: {},
          weekly: {},
          monthly: {},
          yearly: {},
        };
      }

      // Daily data
      const dayKey = date.toISOString().split("T")[0];
      if (!acc[companyKey].daily[dayKey]) {
        acc[companyKey].daily[dayKey] = [];
      }
      acc[companyKey].daily[dayKey].push(item);

      // Weekly data
      const weekKey = getWeekNumber(date);
      if (!acc[companyKey].weekly[weekKey]) {
        acc[companyKey].weekly[weekKey] = [];
      }
      acc[companyKey].weekly[weekKey].push(item);

      // Monthly data
      const monthKey = `${date.getFullYear()}-${String(
        date.getMonth() + 1
      ).padStart(2, "0")}`;
      if (!acc[companyKey].monthly[monthKey]) {
        acc[companyKey].monthly[monthKey] = [];
      }
      acc[companyKey].monthly[monthKey].push(item);

      // Yearly data
      const yearKey = date.getFullYear();
      if (!acc[companyKey].yearly[yearKey]) {
        acc[companyKey].yearly[yearKey] = [];
      }
      acc[companyKey].yearly[yearKey].push(item);

      return acc;
    }, {});

    return grouped;
  };

  // Helper function to get week number
  const getWeekNumber = (date) => {
    const d = new Date(date);
    d.setHours(0, 0, 0, 0);
    d.setDate(d.getDate() + 4 - (d.getDay() || 7));
    const yearStart = new Date(d.getFullYear(), 0, 1);
    const weekNo = Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
    return `${d.getFullYear()}-W${String(weekNo).padStart(2, "0")}`;
  };

  // Helper function for week number
  Date.prototype.getWeek = function () {
    const d = new Date(
      Date.UTC(this.getFullYear(), this.getMonth(), this.getDate())
    );
    const dayNum = d.getUTCDay() || 7;
    d.setUTCDate(d.getUTCDate() + 4 - dayNum);
    const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    return Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
  };

  // Get the selected company's data for the charts
  const selectedCompanyData = dashboardData ? dashboardData[377] : null; // 377 is your companyId

  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      <FormControl fullWidth>
        <Autocomplete
          id={t("Location")}
          options={restaurants.map((resto) => resto.nomEmplacement)}
          onChange={handleChangeRestaurant}
          sx={{ marginRight: "10px", marginTop: "15px" }}
          value={restaurant || ""}
          renderInput={(params) => (
            <TextField {...params} label={t("Location")} />
          )}
        />
      </FormControl>
      <Typography variant="h4" gutterBottom>
        {t("Dashboard")}
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={4}>
          <Paper elevation={3} sx={{ p: 2, height: "500px" }}>
            <CustomersPieChart
              data={dashboardData.numberOfclientsData}
              loading={!dashboardData.numberOfclientsData}
              companyId={Object.keys(dashboardData.numberOfclientsData)[0]}
            />
          </Paper>
        </Grid>

        {/* Add the bar chart */}
        <Grid item xs={12} md={8}>
          <Paper elevation={3} sx={{ p: 2, height: "500px" }}>
            <SalesBarChart
              data={dashboardData.totalSales}
              loading={!dashboardData.totalSales}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 2, height: "500px" }}>
            {/* For Labor Cost*/}
            <GaugeChart
              data={dashboardData.laborCost}
              loading={!dashboardData.laborCost}
              type="labor"
              goal={30}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 2, height: "500px" }}>
            {/* For Food Cost*/}
            <GaugeChart
              data={dashboardData.foodCost}
              loading={!dashboardData.foodCost}
              type="food"
              goal={28}
            />
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}

export default DashboardView;
