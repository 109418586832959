import React, { useEffect, useState } from "react";
import "./css/ArticleProperties.css";
import {
  Button,
  Box,
  Modal,
  Checkbox,
  TextField,
  Autocomplete,
} from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import CustomTreeView from "../../Common/Components/CustomTreeView";
import { useSelector } from "react-redux";

function ArticleProperties({
  open,
  onClose,
  readOnly,
  article,
  setReloadArticle,
}) {
  const { t, i18n } = useTranslation(["Articles"]);
  const [publicationDate, setPublicationDate] = useState("");
  const [endPublicationDate, setEndPublicationDate] = useState("");
  const [externalDepartments, setExternalDepartments] = useState([]);
  const [externalDepartment, setExternalDepartment] = useState([]);
  const [checkedState, setCheckedState] = useState(new Array(8).fill(false));
  const [idsDepartment, setIdsDepartment] = useState([]);
  const [TreeViewProvince, setTreeViewProvince] = useState([]);
  const [indeterminateCheckbox, setIndeterminateCheckbox] = useState([]);
  const [selectedNodes, setSelectedNodes] = useState([]);
  const [noEmplacement, setNoEmplacement] = useState([]);
  const [signataires, setSignataires] = useState([]);
  const [signataire, setSignataire] = useState();
  const [idSignataire, setIdSignataire] = useState(0);
  const [internalDepartments, setInternalDepartments] = useState([]);
  const [internalDepartment, setInternalDepartment] = useState();
  const [idDepartmentInterne, setIdDepartmentInterne] = useState("");
  const [emplacementArticle, setEmplacementArticle] = useState([]);

  const userState = useSelector((state) => state.user);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const provinceResponse = await fetch(
          `${process.env.REACT_APP_API_URL}/Localization/GetProvince`,
          {
            method: "GET",
            withCredentials: true,
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userState.token}`,
            },
          }
        );

        const provinceData = await provinceResponse.json();
        TreeProvince(provinceData);

        const externalDepartmentResponse = await fetch(
          `${process.env.REACT_APP_API_URL}/Localization/GetExternalDepartment`,
          {
            method: "GET",
            withCredentials: true,
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userState.token}`,
            },
          }
        );

        const externalDepartmentData = await externalDepartmentResponse.json();
        console.log(externalDepartmentData);
        setExternalDepartments(externalDepartmentData);

        const initialCheckedState = externalDepartmentData.map(() => false);
        setCheckedState(initialCheckedState);

        const signatairesResponse = await fetch(
          `${process.env.REACT_APP_API_URL}/employee/GetSignataire`,
          {
            method: "GET",
            withCredentials: true,
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userState.token}`,
            },
          }
        );

        const signatairesData = await signatairesResponse.json();
        setSignataires(signatairesData);

        const internalDepartmentsResponse = await fetch(
          `${process.env.REACT_APP_API_URL}/article/GetCategoryArticle`,
          {
            method: "GET",
            withCredentials: true,
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userState.token}`,
            },
          }
        );

        const internalDepartmentsData =
          await internalDepartmentsResponse.json();
        setInternalDepartments(internalDepartmentsData);
      } catch (error) {
        if (error.name === "AbortError") {
          console.log("Fetch aborted");
        } else {
          console.error("Error fetching data:", error);
        }
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (article) {
      setEndPublicationDate(article.endPublicationDate);
      setPublicationDate(article.publicationDate);
      setIdDepartmentInterne(article.category?.id);
      setInternalDepartment(
        userState.lang === "FR"
          ? article.category?.textFr
          : article.category?.textEn
      );
      setSignataire(article.signataire?.userName);
      setEmplacementArticle(article.articleEmplacements);
      let noLocation = [];
      if (article.articleEmplacements) {
        article.articleEmplacements.forEach((element) => {
          noLocation.push(element.idEmplacement);
        });
        setNoEmplacement(noLocation);
      }

      setExternalDepartment(article.articleExternalDepartments);
      let idExternalDepartment = [];
      if (article.articleExternalDepartments) {
        article.articleExternalDepartments.forEach((element) => {
          idExternalDepartment.push(element.idDepartement);
        });
        setIdsDepartment(idExternalDepartment);
      }
    }
  }, [article]);

  useEffect(() => {
    if (
      article &&
      article.articleExternalDepartments &&
      externalDepartments.length > 0
    ) {
      // Initialize checkedState based on article's existing departments
      const articleExternalIds = article.articleExternalDepartments.map(
        (dep) => dep.idDepartement
      );
      const initialCheckedState = externalDepartments.map((dep) =>
        articleExternalIds.includes(dep.id)
      );
      setCheckedState(initialCheckedState);
      setIdsDepartment(articleExternalIds);
    } else {
      // If there are no external departments, reset checkedState to the correct length
      setCheckedState(new Array(externalDepartments.length).fill(false));
    }
  }, [article, externalDepartments]);

  useEffect(() => {
    const initData = async () => {
      try {
        await InitcheckedProvinces();
        await InitializeDepartmentCheckbox();
      } catch (error) {
        console.error("Error during initialization:", error);
      }
    };

    const GetExternalDepartmentResponse = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/Localization/GetExternalDepartment`,
          {
            method: "GET",
            withCredentials: true,
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userState.token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch external departments");
        }

        const externalDepartmentData = await response.json();
        console.log(externalDepartmentData);
        setExternalDepartments(externalDepartmentData);

        const initialCheckedState = externalDepartmentData.map(() => false);
        setCheckedState(initialCheckedState);
      } catch (error) {
        console.error("Error fetching external departments:", error);
      }
    };

    // Call both functions and await their completion
    const fetchData = async () => {
      await Promise.all([GetExternalDepartmentResponse(), initData()]);
    };

    fetchData();
  }, [open]);

  const InitcheckedProvinces = () => {
    const leaves = [];
    getLeafNodes(leaves, bfsSearch(TreeViewProvince, 0));

    const ToBeChecked = [];
    const ToBeIndeterminate = [];

    leaves.forEach((leaf) => {
      // Check if the leaf node's emplacement exists in the article
      if (
        emplacementArticle.some((e) => e.idEmplacement === leaf.noEmplacement)
      ) {
        ToBeChecked.push(leaf.id);

        // Get the fathers of the current leaf
        const fathers = getAllFathers(leaf.id);
        fathers.forEach((father) => {
          const fatherNode = bfsSearch(TreeViewProvince, father);

          if (isAllChildrenChecked(fatherNode, ToBeChecked)) {
            ToBeChecked.push(father);
          } else if (isAtLeastOneChildIsChecked(fatherNode, ToBeChecked)) {
            ToBeIndeterminate.push(father);
          }
        });
      }
    });

    setSelectedNodes(ToBeChecked);
    setIndeterminateCheckbox(ToBeIndeterminate);
  };

  const InitializeDepartmentCheckbox = () => {
    let checkboxstate = [];
    for (let i = 0; i < externalDepartments.length; i++) {
      for (let j = 0; j < externalDepartment.length; j++) {
        if (externalDepartments[i].id == externalDepartment[j].idDepartement) {
          checkboxstate[i] = true;
          break;
        } else {
          checkboxstate[i] = false;
        }
      }
    }
    setCheckedState(checkboxstate);
  };

  const handleChange = (e, position) => {
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item
    );

    const departmentId = Number(e.currentTarget.value); // Convert to number
    console.log(checkedState);
    setCheckedState(updatedCheckedState); // Update checked state

    // Update idsDepartment based on the checkbox state
    if (updatedCheckedState[position]) {
      setIdsDepartment((prevId) => [...prevId, departmentId]); // Add as number
    } else {
      setIdsDepartment(
        (prevId) => prevId.filter((id) => id !== departmentId) // Compare with number
      );
    }
  };

  const handleSave = async () => {
    console.log(idsDepartment);
    console.log(checkedState);
    try {
      // Prepare the data to send to the server
      const data = {
        id: article.id,
        idSignatory: idSignataire,
        idCategory: idDepartmentInterne,
        publicationDate: publicationDate,
        endPublicationDate: endPublicationDate,
        idEmplacement: noEmplacement,
        idExternalDepartment: idsDepartment,
      };

      // Send a POST request to save the article
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/Article/SaveArticleProperties`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userState.token}`,
          },
          body: JSON.stringify(data),
        }
      );

      // Check if the response is OK (status code 2xx)
      if (!response.ok) {
        throw new Error(`Error: ${response.status} ${response.statusText}`);
      }
      // Parse the JSON response
      const result = await response.json();

      // Optionally handle the result here (e.g., display a success message)
      console.log("Article saved successfully:", result);

      onClose();
      setReloadArticle(true);
    } catch (error) {
      // Handle any errors that occur during the request
      console.error("Error saving article:", error);
      // Optionally display an error message to the user
    }
  };

  const handleClearDate = () => {
    setPublicationDate("");
    setEndPublicationDate("");
  };

  const handleExpandClick = (event) => {
    // prevent the click event from propagating to the checkbox
    event.stopPropagation();
  };

  const TreeProvince = (data) => {
    const tree = [];
    let ids = 0;

    data.forEach((item) => {
      // Find or create the country node
      let country = tree.find((p) => p.paysFr === item.paysFr);
      if (!country) {
        country = {
          id: ids++,
          paysFr: item.paysFr,
          paysEn: item.paysEn,
          children: [],
        };
        tree.push(country);
      }

      // Find or create the province node
      let province = country.children.find(
        (p) => p.provinceFr === item.provinceFr
      );
      if (!province) {
        province = {
          id: ids++,
          parent: country.id,
          provinceFr: item.provinceFr,
          provinceEn: item.provinceEn,
          children: [],
        };
        country.children.push(province);
      }

      // Find or create the region node
      let region = province.children.find((r) => r.regionFr === item.regionFr);
      if (!region) {
        region = {
          id: ids++,
          parent: province.id,
          regionFr: item.regionFr,
          regionEn: item.regionEn,
          children: [],
        };
        province.children.push(region);
      }

      // Find or create the emplacement node
      if (
        !region.children.some((e) => e.nomEmplacement === item.nomEmplacement)
      ) {
        region.children.push({
          id: ids++,
          parent: region.id,
          nomEmplacement: item.nomEmplacement,
          noEmplacement: item.noEmplacement,
        });
      }
    });

    setTreeViewProvince(tree);
    return tree;
  };

  const handleNodeSelect = async (event, nodeId) => {
    event.stopPropagation();

    const allChild = getAllChild(nodeId);
    const fathers = getAllFathers(nodeId);

    const selectedChild = selectedNodes
      .map((id) => bfsSearch(TreeViewProvince, id)?.noEmplacement)
      .filter(Boolean); // filter out undefined values

    setNoEmplacement((prev) => [...new Set(prev.concat(selectedChild))]);

    if (selectedNodes.includes(nodeId)) {
      // Deselect node and its children/fathers
      setSelectedNodes((prev) =>
        prev.filter((id) => !allChild.concat(fathers).includes(id))
      );
      updateNoEmplacementOnDeselect(nodeId, allChild);
    } else {
      // Select node and update parents if needed
      handleSelectNode(nodeId, allChild, fathers);
    }
  };

  // Helper function to handle selecting nodes and updating parents
  const handleSelectNode = (nodeId, allChild, fathers) => {
    const ToBeChecked = [...allChild];
    const ToBeIndeterminate = [];

    fathers.forEach((fatherId) => {
      const fatherNode = bfsSearch(TreeViewProvince, fatherId);

      if (isAllChildrenChecked(fatherNode, ToBeChecked)) {
        ToBeChecked.push(fatherId);
      } else if (isAtLeastOneChildIsChecked(fatherNode, ToBeChecked)) {
        ToBeIndeterminate.push(fatherId);
      }
    });

    setSelectedNodes((prev) => prev.concat(ToBeChecked));
    setIndeterminateCheckbox((prev) => prev.concat(ToBeIndeterminate));

    updateNoEmplacementOnSelect(nodeId, allChild);
  };

  // Handle updating noEmplacement when deselecting
  const updateNoEmplacementOnDeselect = async (nodeId, allChild) => {
    const nodeData = bfsSearch(TreeViewProvince, nodeId);

    if (allChild.length > 1) {
      let leafNodes = [];
      getLeafNoEmplacement(leafNodes, nodeData);
      const diff = noEmplacement.filter((x) => !leafNodes.includes(x));
      await setNoEmplacement(diff);
    } else {
      await setNoEmplacement((prev) =>
        prev.filter((p) => p !== nodeData.noEmplacement)
      );
    }
  };

  // Handle updating noEmplacement when selecting
  const updateNoEmplacementOnSelect = async (nodeId, allChild) => {
    const nodeData = bfsSearch(TreeViewProvince, nodeId);

    if (allChild.length > 1) {
      let leafNodes = [];
      getLeafNoEmplacement(leafNodes, nodeData);

      const diff = leafNodes.filter((x) => !noEmplacement.includes(x));
      await setNoEmplacement((prev) => prev.concat(diff));
    } else {
      await setNoEmplacement((prev) => prev.concat(nodeData.noEmplacement));
    }
  };

  function getLeafNoEmplacement(leafNodes, obj) {
    if (obj.children) {
      obj.children.forEach(function (child) {
        getLeafNoEmplacement(leafNodes, child);
      });
    } else {
      leafNodes.push(obj.noEmplacement);
    }
  }

  const bfsSearch = (graph, targetId) => {
    const queue = [...graph];

    while (queue.length > 0) {
      const currNode = queue.shift();
      if (currNode.id === targetId) {
        return currNode;
      }
      if (currNode.children) {
        queue.push(...currNode.children);
      }
    }
    return []; // Target node not found
  };

  const getAllChild = (id) => {
    return getAllIds(bfsSearch(TreeViewProvince, id));
  };

  const getAllFathers = (id, list = []) => {
    const node = bfsSearch(TreeViewProvince, id);
    list.push(node.parent);
    if (node.parent) {
      return getAllFathers(node.parent, list);
    }
    return list;
  };

  function isAllChildrenChecked(node, test) {
    const leafChild = [];
    getLeafNodes(leafChild, node);

    const nodeIdIndex = leafChild.indexOf(node.id);
    leafChild.splice(nodeIdIndex, 1);

    for (let i = 0; i < leafChild.length; i++) {
      if (test.indexOf(leafChild[i].id) === -1) {
        return false;
      }
    }
    return true;
  }

  function isAtLeastOneChildIsChecked(node, list) {
    const allChild = getAllChild(node.id);
    for (let i = 0; i < allChild.length; i++) {
      if (list.includes(allChild[i])) {
        return true;
      }
    }
    return false;
  }

  function getLeafNodes(leafNodes, obj) {
    if (obj.children) {
      obj.children.forEach(function (child) {
        getLeafNodes(leafNodes, child);
      });
    } else {
      leafNodes.push(obj);
    }
  }

  function getAllIds(node, idList = []) {
    idList.push(node.id);
    if (node.children) {
      node.children.forEach((child) => getAllIds(child, idList));
    }
    return idList;
  }

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          maxHeight: "80%",
          overflow: "auto",
          transform: "translate(-50%, -50%)",
          width: "50%",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
        }}
      >
        <div className="ArticleProperties__date">
          <div className="ArticleProperties__date__datePicker">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <label>{t("PublicationDate")}</label>
              <DateTimePicker
                defaultValue={dayjs(publicationDate)}
                value={dayjs(publicationDate)}
                onChange={(newValue) => setPublicationDate(newValue)}
                disabled={readOnly}
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <label>{t("EndPublicationDate")}</label>
              <DateTimePicker
                defaultValue={dayjs(endPublicationDate)}
                value={dayjs(endPublicationDate)}
                onChange={(newValue) => setEndPublicationDate(newValue)}
                disabled={readOnly}
              />
            </LocalizationProvider>
          </div>
          <div className="ArticleProperties__date__button">
            <Button
              variant="contained"
              onClick={handleClearDate}
              disabled={readOnly}
            >
              {t("ClearDate")}
            </Button>
          </div>
        </div>
        <div className="ArticleProperties__Visibility">
          <div className="ArticleProperties__Province">
            <h3>{t("Restaurants")}</h3>
            <CustomTreeView
              TreeViewProvince={TreeViewProvince}
              handleExpandClick={handleExpandClick}
              handleNodeSelect={handleNodeSelect}
              indeterminateCheckbox={indeterminateCheckbox}
              selectedNodes={selectedNodes}
              readOnly={readOnly}
            />
          </div>
          <div className="ArticleProperties__Department">
            <h3>{t("Reader")}</h3>

            {externalDepartments.map((d, index) => (
              <div key={d.id}>
                <Checkbox
                  checked={checkedState[index] || false} // Ensure no undefined values
                  onChange={(e) => handleChange(e, index)}
                  inputProps={{ "aria-label": "controlled" }}
                  value={d.id}
                  disabled={readOnly}
                />
                {userState.lang === "FR" ? (
                  <label>{d.textFr}</label>
                ) : (
                  <label>{d.textEn}</label>
                )}
              </div>
            ))}
          </div>
        </div>
        <Box sx={{ minWidth: 120 }}>
          <h3>{t("Signatory")}</h3>
          <Autocomplete
            disablePortal
            options={signataires}
            getOptionLabel={(s) => `${s.name} ${s.lastName} (${s.userName})`}
            value={signataires.find((s) => s.userName === signataire) || null}
            onChange={(event, newValue) => {
              if (newValue) {
                setIdSignataire(newValue.noEmploye);
                setSignataire(newValue.userName);
              }
            }}
            disabled={readOnly}
            renderInput={(params) => (
              <TextField {...params} label={t("Signatory")} />
            )}
          />
        </Box>
        <Box sx={{ minWidth: 120 }}>
          <h3>{t("CategoryArticle")}</h3>
          <Autocomplete
            disablePortal
            options={internalDepartments}
            getOptionLabel={(department) =>
              userState.lang === "FR" ? department.textFr : department.textEn
            }
            value={
              internalDepartments.find(
                (d) =>
                  d.textFr === internalDepartment ||
                  d.textEn === internalDepartment
              ) || null
            }
            onChange={(event, newValue) => {
              if (newValue) {
                setIdDepartmentInterne(newValue.id);
                setInternalDepartment(newValue.textFr);
              }
            }}
            disabled={readOnly}
            renderInput={(params) => (
              <TextField {...params} label={t("CategoryArticle")} />
            )}
          />
        </Box>
        <Box sx={{ m: 2, display: "flex", justifyContent: "flex-end" }}>
          <Button variant="contained" onClick={handleSave}>
            Save
          </Button>
          <Button onClick={onClose}>Cancel</Button>
        </Box>
      </Box>
    </Modal>
  );
}

export default ArticleProperties;
