import React, { useEffect, useRef, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import FormControl from "@mui/material/FormControl";
import { useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import { useSelector } from "react-redux";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import Button from "@mui/material/Button";
import {
  Select,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import "../../../css/ComplainForm.css";
import { IconButton } from "@mui/material";
import { Delete as DeleteIcon } from "@mui/icons-material";

function ComplainForm() {
  const [restaurant, setRestaurant] = useState();
  const [restaurants, setRestaurants] = useState([]);
  const [item, setItem] = useState();
  const [items, setItems] = useState([]);
  const [order, setOrder] = useState();
  const [orders, setOrders] = useState([]);
  const { t } = useTranslation(["Casserole"], ["Common"]);
  const [noEmplacement, setNoEmplacement] = useState();
  const [idOrder, setIdOrder] = useState();
  const [textComplaint, setTextComplaint] = useState("");
  const [phoneNumber, setPhoneNumber] = useState();
  const [quantity, setQuantity] = useState(0);
  const [typeQuantity, setTypeQuantity] = useState();
  const userState = useSelector((state) => state.user);
  const userLang = useSelector((state) => state.user.lang);
  const [preciseItem, setPreciseItem] = useState(false);
  const [customItem, setCustomItem] = useState();
  const [batchNumber, setBatchNumber] = useState();
  const [productionDateDisabled, setProductionDateDisabled] = useState(true);
  const [expirationDateDisabled, setExpirationDateDisabled] = useState(true);
  const [batchNumberDisabled, setBatchNumberDisabled] = useState(true);
  const [productionDate, setProductionDate] = useState(null);
  const [expirationDate, setExpirationDate] = useState();

  const [files, setFiles] = useState([]);
  const [status, setStatus] = useState("initial");
  const [error, setError] = useState("");
  const [openErrorModal, setOpenErrorModal] = useState(false);

  const [complaintType, setComplaintType] = useState("");
  const [isTransportComplaint, setIsTransportComplaint] = useState(false);
  const [isServiceComplaint, setIsServiceComplaint] = useState(false);
  const [isProductComplaint, setIsProductComplaint] = useState(false);
  const [personInCharge, setPersonInCharge] = useState("");

  const handleFileChange = (e) => {
    if (e.target.files) {
      setStatus("initial");
      const newFiles = Array.from(e.target.files);
      setFiles((prevFiles) => [...prevFiles, ...newFiles]);
    }
  };

  const handleRemoveFile = (indexToRemove) => {
    setFiles((prevFiles) =>
      prevFiles.filter((_, index) => index !== indexToRemove)
    );
  };

  const handleChangeRestaurant = (e) => {
    setRestaurant(e.target.textContent);
    if (e.target.textContent) {
      setNoEmplacement(
        restaurants.filter((r) => r.nomEmplacement === e.target.textContent)[0]
          .noEmplacement
      );
    }
  };

  const handleChangeOrder = (e) => {
    setOrder(e.target.textContent);
    if (e.target.textContent) {
      console.log(e.target.textContent);
      setIdOrder(
        orders.filter((o) =>
          userLang === "EN"
            ? e.target.textContent.includes(
                o.dateCreation + ", " + o.nomEnCategorie
              )
            : e.target.textContent.includes(
                o.dateCreation + ", " + o.nomFrCategorie
              )
        )[0].id
      );
    }
  };

  const handleChangeItem = (e) => {
    setBatchNumberDisabled(true);
    setExpirationDateDisabled(true);
    setProductionDateDisabled(true);
    setItem(e.target.textContent);
    if (e.target.textContent) {
      let item = items.find((i) =>
        e.target.textContent.includes(i.idItemAccPac)
      );
      if (
        e.target.textContent === "Other" ||
        e.target.textContent === "Autre"
      ) {
        setPreciseItem(true);
      } else {
        setPreciseItem(false);
      }
      if (item) {
        if (item.plainteInfo === 1) {
          setBatchNumberDisabled(false);
        } else if (item.plainteInfo === 2) {
          setProductionDateDisabled(false);
        } else if (item.plainteInfo === 4) {
          setExpirationDateDisabled(false);
        }
      }
    }
  };

  const GetRestaurants = async () => {
    fetch(
      `${process.env.REACT_APP_API_URL}/Localization/GetRestaurantsByUser`,
      {
        method: "GET",
        withCredentials: true,
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userState.token}`,
        },
      }
    )
      .then((results) => {
        return results.json();
      })
      .then((data) => {
        setRestaurants(data);
      });
  };

  const GetOrders = async () => {
    fetch(
      `${process.env.REACT_APP_API_URL}/Casserole/GetOrdersByLocation/${noEmplacement}`,
      {
        method: "GET",
        withCredentials: true,
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userState.token}`,
        },
      }
    )
      .then((results) => {
        return results.json();
      })
      .then((data) => {
        setOrders(data);
      });
  };

  const GetItems = async () => {
    fetch(
      `${process.env.REACT_APP_API_URL}/Casserole/GetItemsByOrders/${idOrder}`,
      {
        method: "GET",
        withCredentials: true,
        credentials: "include",

        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userState.token}`,
        },
      }
    )
      .then((results) => {
        return results.json();
      })
      .then((data) => {
        let otherItem = {
          nomFr: "Autre",
          nomEn: "Other",
        };
        data.unshift(otherItem);
        setItems(data);
      });
  };

  useEffect(() => {
    GetRestaurants();
  }, []);

  useEffect(() => {
    if (noEmplacement) {
      GetOrders();
    }
  }, [noEmplacement]);

  useEffect(() => {
    if (idOrder) {
      GetItems();
    }
  }, [idOrder]);

  const handleSubmitComplaint = async () => {
    // Validation
    if (!restaurant) {
      setError("Restaurant must not be null");
      setOpenErrorModal(true);
      return;
    }
    if (!order) {
      setError("Order must not be null");
      setOpenErrorModal(true);
      return;
    }
    if (!textComplaint) {
      setError("Details of the complaint must not be null");
      setOpenErrorModal(true);
      return;
    }

    if (isProductComplaint) {
      if (!item) {
        setError("Item must not be null");
        setOpenErrorModal(true);
        return;
      }
      if (quantity <= 0) {
        setError("Quantity must be greater than 0");
        setOpenErrorModal(true);
        return;
      }
      if (!typeQuantity) {
        setError("Unit must not be null");
        setOpenErrorModal(true);
        return;
      }

      if (files.length === 0) {
        setError("At least one photo is required");
        setOpenErrorModal(true);
        return;
      }
      if (!batchNumberDisabled && !batchNumber) {
        setError("Batch number must not be null");
        setOpenErrorModal(true);
        return;
      }
      if (!productionDateDisabled && !productionDate) {
        setError("Production date must not be null");
        setOpenErrorModal(true);
        return;
      }
      if (!expirationDateDisabled && !expirationDate) {
        setError("Expiration date must not be null");
        setOpenErrorModal(true);
        return;
      }
    }

    const formData = new FormData();
    formData.append("IdRestaurant", noEmplacement);
    formData.append("IdOrder", idOrder);
    formData.append("Item", item);
    formData.append("CustomItem", customItem || "");
    formData.append("BatchNumber", batchNumber || "");
    formData.append(
      "ProductionDate",
      productionDate ? productionDate.toISOString() : ""
    );
    formData.append(
      "ExpirationDate",
      expirationDate ? expirationDate.toISOString() : ""
    );
    formData.append("PhoneNumber", phoneNumber || "");
    formData.append("Quantity", quantity);
    formData.append("TypeQuantity", typeQuantity);
    formData.append("TextComplaint", `[${complaintType}] ${textComplaint}`);
    formData.append("BatchNumberDisabled", batchNumberDisabled);
    formData.append("ProductionDateDisabled", productionDateDisabled);
    formData.append("ExpirationDateDisabled", expirationDateDisabled);
    formData.append("NomResponsable", personInCharge);

    files.forEach((file, index) => {
      formData.append(`Files`, file);
    });

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/Casserole/SubmitComplaint`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${userState.token}`,
          },
          body: formData,
        }
      );

      const data = await response.json();

      if (response.ok) {
        // Handle successful response
        console.log("Complaint submitted successfully:", data);
        alert(
          "Complaint submitted successfully: no complaint : ",
          data.complaint.id
        );
        // Clear form fields or show a success message
      } else {
        // Handle error response
        setError(data.message || "Error submitting complaint");
        setOpenErrorModal(true);
      }
    } catch (error) {
      console.error("Error submitting complaint:", error);
      setError("Error submitting complaint");
      setOpenErrorModal(true);
    }
  };

  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  return (
    <div>
      <div className="ComplainForm__Header">
        <FormControl sx={{ width: "90%", ml: 2 }}>
          <Autocomplete
            id={t("Restaurants")}
            options={restaurants.map((r) => r.nomEmplacement)}
            onChange={(e) => handleChangeRestaurant(e)}
            sx={{ marginTop: "10px", marginBottom: "10px" }}
            value={restaurant ?? ""}
            renderInput={(params) => (
              <TextField {...params} label={t("Restaurants")} />
            )}
          />
        </FormControl>
        <FormControl sx={{ width: "90%", ml: 2 }}>
          <Autocomplete
            id={t("Orders")}
            options={orders.map((o) =>
              userLang == "EN"
                ? o.dateCreation + ", " + o.nomEnCategorie
                : o.dateCreation + ", " + o.nomFrCategorie
            )}
            onChange={(e) => handleChangeOrder(e)}
            sx={{ marginBottom: "10px" }}
            value={order ?? ""}
            renderInput={(params) => (
              <TextField {...params} label={t("Orders")} />
            )}
          />
        </FormControl>

        <FormControl sx={{ width: "90%", ml: 2 }}>
          <Autocomplete
            options={[t("Transports"), t("Services"), t("Products")]}
            value={complaintType}
            sx={{ marginBottom: "10px" }}
            onChange={(e, value) => {
              setComplaintType(value);
              setIsTransportComplaint(value === "Transports");
              setIsServiceComplaint(value === "Services");
              setIsProductComplaint(value === "Products");
            }}
            renderInput={(params) => (
              <TextField {...params} label={t("ComplaintType")} />
            )}
            disableClearable
          />
        </FormControl>

        {isProductComplaint && (
          <FormControl sx={{ width: "90%", ml: 2 }}>
            <Autocomplete
              id={t("Items")}
              options={items.map((i) =>
                i.nomEn === "Other" || i.nomFr === "Autre"
                  ? userLang === "EN"
                    ? i.nomEn
                    : i.nomFr
                  : userLang == "EN"
                  ? i.idItemAccPac + " | " + i.nomEn + " | " + i.format
                  : i.idItemAccPac + " | " + i.nomFr + " | " + i.format
              )}
              onChange={(e) => handleChangeItem(e)}
              sx={{ marginBottom: "10px" }}
              value={item ?? ""}
              renderInput={(params) => (
                <TextField {...params} label={t("Items")} />
              )}
            />
          </FormControl>
        )}

        {isProductComplaint && preciseItem && (
          <TextField
            id="preciseItem"
            name="preciseItem"
            label={t("PreciseItem")}
            autoComplete="off"
            variant="outlined"
            value={customItem}
            onChange={(e) => setCustomItem(e.target.value)}
            sx={{ ml: 2 }}
          />
        )}

        {isProductComplaint && !batchNumberDisabled && (
          <TextField
            id="batchNumber"
            name="batchNumber"
            label={t("BatchNumber")}
            autoComplete="off"
            variant="outlined"
            value={batchNumber}
            onChange={(e) => setBatchNumber(e.target.value)}
            sx={{ ml: 2 }}
          />
        )}
        {isProductComplaint && !expirationDateDisabled && (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              value={expirationDate}
              label={t("ExpirationDate")}
              slotProps={{ textField: { size: "small" } }}
              onChange={(newValue) => setExpirationDate(newValue)}
              sx={{ ml: 2 }}
            />
          </LocalizationProvider>
        )}
        {isProductComplaint && !productionDateDisabled && (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              value={productionDate}
              label={t("ProductionDate")}
              slotProps={{
                textField: {
                  size: "small",
                  sx: { ml: 2 },
                },
              }}
              onChange={(date) => setProductionDate(date)}
            />
          </LocalizationProvider>
        )}
      </div>

      <div className="ComplainForm__TextField">
        <TextField
          id="Responsable"
          name="Responsable"
          label={t("PersonInCharge")}
          size="small"
          autoComplete="off"
          variant="outlined"
          value={personInCharge || ""}
          onChange={(e) => setPersonInCharge(e.target.value)}
        />

        <TextField
          id="Telephone"
          name="Telephone"
          label={t("PhoneNumber")}
          size="small"
          autoComplete="off"
          variant="outlined"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
        />

        {isProductComplaint && (
          <>
            <TextField
              type="number"
              id="Quantity"
              name="Quantity"
              label={t("Quantity")}
              size="small"
              autoComplete="off"
              variant="outlined"
              inputProps={{ min: 0, max: 100 }}
              value={quantity}
              onChange={(e) => setQuantity(e.target.value)}
            />

            <Select
              labelId="TypeQuantity"
              value={typeQuantity}
              label={t("TypeQuantity")}
              onChange={(e) => setTypeQuantity(e.target.value)}
              size="small"
              style={{ width: 100 }}
            >
              <MenuItem value="unite">Unite</MenuItem>
              <MenuItem value="caisse">Caisse</MenuItem>
            </Select>
          </>
        )}

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker
            value={items[0] ? dayjs(items[0].DeliveryDate) : ""}
            label={t("DeliveryDate")}
            slotProps={{ textField: { size: "small" } }}
            disabled
          />
        </LocalizationProvider>
      </div>
      <div className="ComplainForm__Editor">
        <TextField
          value={textComplaint}
          onChange={(event) => setTextComplaint(event.target.value)}
          multiline
          rows={4}
          label={t("ComplaintDetail")}
          sx={{ width: "100%" }}
        />
      </div>
      <div className="ComplainForm__UploadFiles">
        <input
          id="file"
          type="file"
          multiple
          accept="image/*" // Accepts only image files
          onChange={handleFileChange}
        />
        <div className="ComplainForm__UploadedFiles">
          {files &&
            [...files].map((file, index) => {
              const fileURL = URL.createObjectURL(file);
              return (
                <div key={index} className="ComplainForm__UploadedFileItem">
                  <img src={fileURL} alt={`Preview of ${file.name}`} />
                  <IconButton
                    className="ComplainForm__RemoveIcon"
                    onClick={() => handleRemoveFile(index)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </div>
              );
            })}
        </div>
      </div>
      {error && (
        <Dialog
          open={openErrorModal}
          onClose={handleCloseErrorModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Error"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {error}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseErrorModal} color="primary">
              OK
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <Button variant="contained" onClick={handleSubmitComplaint}>
        {t("Submit")}
      </Button>
    </div>
  );
}

export default ComplainForm;
