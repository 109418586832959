import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Modal,
  FormControl,
  TextField,
  Tabs,
  Tab,
  Paper,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { useTranslation } from "react-i18next";
import CustomAlert from "../Common/Components/CustomAlert";
import CreateCompany from "./Modal/CreateCompany";
import { useSelector } from "react-redux";
import Company from "./Tabs/Company";
import ShareholderList from "./Tabs/ShareholderList";
import "./css/CompanyView.css";
import { FormatDateTime } from "../../utils/formateDate";

// TabPanel component definition
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`company-tabpanel-${index}`}
      aria-labelledby={`company-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: 800,
  width: 800,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function CompanyView() {
  const { t } = useTranslation(["Company"], ["Common"]);
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(0);
  const [restaurants, setRestaurants] = useState([]);
  const [restaurant, setRestaurant] = useState();
  const [company, setCompany] = useState(null);
  const [companies, setCompanies] = useState([]);
  const [currentNoEmplacement, setCurrentNoEmplacement] = useState();
  const [shareHoldersByEmplacement, setShareHoldersByEmplacement] = useState(
    []
  );
  const [isAdmin, setIsAdmin] = useState(false);

  const [showAlert, setShowAlert] = useState(false);
  const [typeMessage, setTypeMessage] = useState("");
  const [message, setMessage] = useState("");

  const userState = useSelector((state) => state.user);

  useEffect(() => {
    // Check if user has admin roles
    const userRoles = userState.roles || [];
    const isUserAdmin = userRoles.some(
      (role) => role === "CompanyAdmin" || role === "AdminTI"
    );
    setIsAdmin(isUserAdmin);
  }, [userState.roles]);

  const handleOpen = async () => {
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  useEffect(() => {
    GetCompanies();
    GetEmplacement();
  }, []);

  useEffect(() => {
    GetCompagniesByEmplacement();
  }, [currentNoEmplacement]);

  useEffect(() => {
    GetShareHoldersByEmplacement();
  }, [company]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const GetEmplacement = async () => {
    fetch(`${process.env.REACT_APP_API_URL}/Localization/GetRestaurants`, {
      method: "GET",
      withCredentials: true,
      credentials: "include",

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userState.token}`,
      },
    })
      .then((results) => {
        return results.json();
      })
      .then((data) => {
        var restoWithoutName = {
          noEmplacement: -1,
          nomEmplacement: "Sans Emplacement",
          paysEn: null,
          paysFr: null,
          provinceEn: null,
          provinceFr: null,
          regionEn: null,
          regionFr: null,
        };
        data.unshift(restoWithoutName);
        setRestaurants(data);
      });
  };

  const GetCompanies = async () => {
    fetch(`${process.env.REACT_APP_API_URL}/company/GetCompanies`, {
      method: "GET",
      withCredentials: true,
      credentials: "include",

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userState.token}`,
      },
    })
      .then((results) => {
        return results.json();
      })
      .then((data) => {
        setCompanies(data);
      });
  };

  const GetCompaniesWithoutEmplacement = async () => {
    fetch(
      `${process.env.REACT_APP_API_URL}/company/GetCompaniesWithoutEmplacement`,
      {
        method: "GET",
        withCredentials: true,
        credentials: "include",

        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userState.token}`,
        },
      }
    )
      .then((results) => {
        return results.json();
      })
      .then((data) => {
        setCompanies(data);
      });
  };

  const GetCompagniesByEmplacement = async () => {
    if (!currentNoEmplacement) {
      console.warn("No emplacement number provided");
      setCompanies([]);
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/company/GetCompaniesByEmplacement/${currentNoEmplacement}`,
        {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userState.token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setCompanies(Array.isArray(data) ? data : []);
    } catch (error) {
      console.error("Error fetching companies:", error);
      setCompanies([]);
      setTypeMessage && setTypeMessage("echec");
      setMessage && setMessage("Failed to fetch companies");
      setShowAlert && setShowAlert(true);
    }
  };

  const GetShareHoldersByEmplacement = async () => {
    if (company && company.noCompagnie) {
      fetch(
        `${process.env.REACT_APP_API_URL}/employee/GetShareHoldersByEmplacement/${company.noCompagnie}`,
        {
          method: "GET",
          withCredentials: true,
          credentials: "include",

          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userState.token}`,
          },
        }
      )
        .then((results) => {
          return results.json();
        })
        .then((data) => {
          setShareHoldersByEmplacement(data);
          console.log(data);
        });
    }
  };

  const handleChangeEmplacement = (event) => {
    // Reset states
    setCompany(null);
    setShareHoldersByEmplacement(null);

    // Early return if no event or textContent
    if (!event?.target?.textContent) {
      setRestaurant("");
      setCurrentNoEmplacement(null);
      GetCompanies();
      return;
    }

    const selectedLocation = event.target.textContent;
    setRestaurant(selectedLocation);
    // Handle different location scenarios
    switch (selectedLocation) {
      case "Sans Emplacement":
        setCurrentNoEmplacement(null);
        GetCompaniesWithoutEmplacement();
        break;

      case "":
        setCurrentNoEmplacement(null);
        GetCompanies();
        break;

      default:
        if (restaurants?.length) {
          const selectedRestaurant = restaurants.find(
            (r) => r.nomEmplacement === selectedLocation
          );
          if (selectedRestaurant) {
            setCurrentNoEmplacement(selectedRestaurant.noEmplacement);
          } else {
            console.warn(
              `Restaurant not found for location: ${selectedLocation}`
            );
            setCurrentNoEmplacement(null);
            GetCompanies();
          }
        } else {
          console.warn("No restaurants data available");
          setCurrentNoEmplacement(null);
          GetCompanies();
        }
        break;
    }
  };

  const handleChangeCompany = (e, newValue, source) => {
    setShareHoldersByEmplacement();

    if (!newValue) {
      setCompany(null);
      setRestaurant("");
      setCurrentNoEmplacement(null);
      return;
    }

    if (companies && companies.length > 0) {
      let selectedCompany;

      // Handle selection from either company name or store number dropdown
      if (source === "storeNumber") {
        // When selecting by store number, find exact match
        selectedCompany = companies.find(
          (c) => String(c.noAccpac) === String(newValue.noAccpac)
        );
      } else {
        // When selecting by company name, find exact match by name and date
        selectedCompany = companies.find(
          (c) =>
            c.nom === newValue.nom && c.dateOuverture === newValue.dateOuverture
        );
      }

      if (selectedCompany) {
        // Ensure we're setting the complete company object from our companies array
        const completeCompany = companies.find(
          (c) => c.noCompagnie === selectedCompany.noCompagnie
        );

        // Force a new object to trigger re-render
        setCompany({ ...completeCompany });

        // Update location dropdown based on company's location
        if (!completeCompany.nomEmplacement || !completeCompany.noEmplacement) {
          setRestaurant("Sans Emplacement");
          setCurrentNoEmplacement(null);
        } else {
          setRestaurant(completeCompany.nomEmplacement);
          setCurrentNoEmplacement(completeCompany.noEmplacement);
        }
      }
    }
  };

  const handleCompanyUpdate = async () => {
    try {
      // Fetch updated company data
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/company/GetCompanyById/${company.noCompagnie}`,
        {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userState.token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch updated company");
      }

      const updatedCompanyArray = await response.json();
      // Take the first company from the array since GetCompanyById returns an array
      const updatedCompany = updatedCompanyArray[0] || null;

      // Ensure we're setting a new object to trigger re-render
      setCompany(updatedCompany ? { ...updatedCompany } : null);

      // Also update the companies list
      if (currentNoEmplacement) {
        await GetCompagniesByEmplacement();
      } else if (restaurant === "Sans Emplacement") {
        await GetCompaniesWithoutEmplacement();
      } else {
        await GetCompanies();
      }

      // After updating lists, also update shareholders
      await GetShareHoldersByEmplacement();
    } catch (error) {
      console.error("Error fetching updated company:", error);
    }
  };

  return (
    <Paper elevation={3} sx={{ p: 3, m: 2 }}>
      <Box sx={{ mb: 3 }}>
        <Button
          onClick={handleOpen}
          variant="contained"
          sx={{
            textTransform: "none",
            fontWeight: "medium",
          }}
        >
          {t("NewCompany")}
        </Button>
      </Box>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="CompanyManagement__Modal">
          {<CreateCompany />}
        </Box>
      </Modal>

      <Box sx={{ minWidth: 120, mb: 4 }}>
        <div className="APIKEY__filter">
          <FormControl fullWidth>
            <Autocomplete
              id={t("Emplacement")}
              options={restaurants.map((resto) => resto.nomEmplacement)}
              onChange={(e) => handleChangeEmplacement(e)}
              sx={{ marginRight: "10px" }}
              value={restaurant ?? ""}
              renderInput={(params) => (
                <TextField {...params} label={t("Location")} />
              )}
            />
          </FormControl>
          <FormControl fullWidth>
            <Autocomplete
              value={company || null}
              onChange={(e, newValue) =>
                handleChangeCompany(e, newValue, "companyName")
              }
              options={companies || []}
              getOptionLabel={(option) =>
                option
                  ? `${option.nom} - ${FormatDateTime(option.dateOuverture)}`
                  : ""
              }
              sx={{ marginRight: "10px" }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("Company")}
                  variant="outlined"
                />
              )}
              isOptionEqualToValue={(option, value) =>
                option?.noCompagnie === value?.noCompagnie
              }
            />
          </FormControl>
          <FormControl fullWidth>
            <Autocomplete
              value={company || null}
              onChange={(e, newValue) =>
                handleChangeCompany(e, newValue, "storeNumber")
              }
              options={companies || []}
              getOptionLabel={(option) =>
                option ? option.noAccpac?.toString() : ""
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("StoreNumber")}
                  variant="outlined"
                />
              )}
              isOptionEqualToValue={(option, value) =>
                option?.noCompagnie === value?.noCompagnie
              }
            />
          </FormControl>
        </div>
      </Box>

      <Box sx={{ width: "100%", height: 600 }}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            mb: 3,
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="company management tabs"
            variant="fullWidth"
            sx={{
              "& .MuiTab-root": {
                textTransform: "none",
                fontWeight: "medium",
                "&.Mui-selected": {
                  fontWeight: "bold",
                },
              },
            }}
          >
            <Tab
              label={t("Company")}
              id="company-tab-0"
              aria-controls="company-tabpanel-0"
            />
            <Tab
              label={t("Shareholders")}
              id="company-tab-1"
              aria-controls="company-tabpanel-1"
            />
          </Tabs>
        </Box>

        <TabPanel value={value} index={0}>
          <Company
            isAdmin={isAdmin}
            company={company}
            emplacements={restaurants}
            shareHoldersByEmplacement={shareHoldersByEmplacement}
            onCompanyUpdate={handleCompanyUpdate}
          />
        </TabPanel>

        <TabPanel value={value} index={1}>
          <ShareholderList
            isAdmin={isAdmin}
            company={company}
            shareHoldersByEmplacement={shareHoldersByEmplacement}
          />
        </TabPanel>
      </Box>

      <CustomAlert
        open={showAlert}
        closeMessage={setShowAlert}
        typeMessage={typeMessage}
        message={message}
      />
    </Paper>
  );
}

export default CompanyView;
