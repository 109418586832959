import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import {
  GridRowModes,
  GridToolbarContainer,
  GridActionsCellItem,
  GridRowEditStopReasons,
} from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";
import { DataGrid, GridToolbar, GridColDef } from "@mui/x-data-grid";
import { Checkbox } from "@mui/material";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import DetailsShareholder from "../DetailsShareholder";
import FormControlLabel from "@mui/material/FormControlLabel";
import CustomAlert from "../../Common/Components/CustomAlert";
import { useFieldArray } from "react-hook-form";

import "./css/CreateShareholder.css";
import { useSelector } from "react-redux";

function CreateShareholder({ control, name, setShareholders }) {
  const { t, i18n } = useTranslation(["Company"]);
  const [firstname, setfirstname] = useState();
  const [lastname, setLastname] = useState();
  const [username, setUsername] = useState();
  const [language, setLanguage] = useState();
  const [rows, setRows] = useState([]);
  const [idCurrentShareholder, setIdCurrentShareholder] = useState(0);
  const [rowModesModel, setRowModesModel] = React.useState({});
  const [languages, setLanguages] = useState(["FR", "EN"]);
  const [openShareholderSelect, setOpenShareholderSelect] = useState(false);
  const [country, setCountry] = useState();
  const [countries, setCountries] = useState([]);
  const [countriesData, setCountriesData] = useState([]);
  const [province, setProvince] = useState();
  const [provinces, setProvinces] = useState([]);
  const [provincesData, setProvincesData] = useState([]);
  const [regions, setRegions] = useState([]);
  const [region, setRegion] = useState();
  const [email, setEmail] = useState();
  const [cellulaire, setCellulaire] = useState();
  const [adress, setAdress] = useState();
  const [codePostal, setCodePostal] = useState();
  const [pourcentageAction, setPourcentageAction] = useState();
  const [city, setCity] = useState();
  const [isExploitant, setIsExploitant] = useState(false);

  const [openModalShareholder, setOpenModalShareholder] = useState(false);
  const [openModalAdresse, setOpenModalAdresse] = useState(false);

  const [detailAdress, setDetailAdress] = useState();

  const userState = useSelector((state) => state.user);

  const { fields, append, remove } = useFieldArray({
    control,
    name: name,
  });

  // Update parent component when fields change
  useEffect(() => {
    setShareholders(fields);
  }, [fields, setShareholders]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    height: 800,
    width: 800,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const columns = [
    {
      field: "firstName",
      headerName: "Prenom",
      width: 130,
      editable: true,
    },
    { field: "lastName", headerName: "Nom", width: 130, editable: true },
    { field: "username", headerName: "Username", width: 130, editable: true },
    { field: "email", headerName: "Email", width: 250, editable: true },
    {
      field: "noCellulaire",
      headerName: "No Cellulaire",
      width: 130,
      editable: true,
    },
    {
      field: "pourcentageAction",
      headerName: "% Action",
      width: 80,
      editable: true,
    },
    {
      field: "langue",
      headerName: "Langue",
      width: 80,
      editable: true,
      type: "singleSelect",
      valueOptions: ["FR", "EN"],
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<ManageAccountsIcon />}
            label="Details"
            onClick={() => handleOpenDetailsShareholder(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];
  const handleCloseModalShareholder = () => {
    setOpenModalShareholder(false);
  };

  const handleOpenDetailsShareholder = (id) => {
    const row = rows.filter((row) => row.id == id)[0];
    GetAdresse(row);
    handleOpenModalAdresse();
  };

  const GetAdresse = (row) => {
    let adresse = {
      nomPaysFr: row.country,
      nomProvinceFr: row.province,
      nomRegionFr: row.region,
      adresse: adress,
      ville: row.ville,
      codePostal: codePostal,
    };

    setDetailAdress(adresse);
  };
  const handleOpenModalAdresse = () => setOpenModalAdresse(true);
  const handleCloseModalAdresse = () => setOpenModalAdresse(false);

  const getCountries = async () => {
    fetch(`${process.env.REACT_APP_API_URL}/Localization/GetCountries`, {
      method: "GET",
      withCredentials: true,
      credentials: "include",

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userState.token}`,
      },
    })
      .then((results) => {
        return results.json();
      })
      .then((data) => {
        let countriesFR = [];
        let countriesEN = [];
        setCountriesData(data);
        data.forEach((pays) => {
          countriesFR.push(pays.nomFr);
          countriesEN.push(pays.nomEn);
        });
        setCountries(countriesFR);
      });
  };

  const GetProvinceByCountry = async () => {
    if (!country || !countriesData || countriesData.length === 0) {
      return;
    }

    const selectedCountry = countriesData.find((c) => c.nomFr === country);
    if (!selectedCountry || !selectedCountry.noPays) {
      console.warn("Country not found or invalid country data");
      return;
    }

    fetch(
      `${process.env.REACT_APP_API_URL}/Localization/GetProvinceByCountry/${selectedCountry.noPays}`,
      {
        method: "GET",
        withCredentials: true,
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userState.token}`,
        },
      }
    )
      .then((results) => {
        return results.json();
      })
      .then((data) => {
        let provinceFR = [];
        let provinceEN = [];
        data.forEach((province) => {
          provinceFR.push(province.nomFr);
          provinceEN.push(province.nomEn);
        });
        setProvinces(provinceFR);
        setProvincesData(data);
      })
      .catch((error) => {
        console.error("Error fetching provinces:", error);
        setProvinces([]);
        setProvincesData([]);
      });
  };

  const GetRegionsByProvinces = async () => {
    if (!province || !provincesData || provincesData.length === 0) {
      return;
    }

    const selectedProvince = provincesData.find((p) => p.nomFr === province);
    if (!selectedProvince || !selectedProvince.noProvince) {
      console.warn("Province not found or invalid province data");
      return;
    }

    fetch(
      `${process.env.REACT_APP_API_URL}/Localization/GetRegionsByProvinces/${selectedProvince.noProvince}`,
      {
        method: "GET",
        withCredentials: true,
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userState.token}`,
        },
      }
    )
      .then((results) => {
        return results.json();
      })
      .then((data) => {
        let regionsFR = [];
        let regionsEN = [];
        data.forEach((region) => {
          regionsFR.push(region.nomFr);
          regionsEN.push(region.nomEn);
        });
        setRegions(regionsFR);
      })
      .catch((error) => {
        console.error("Error fetching regions:", error);
        setRegions([]);
      });
  };

  useEffect(() => {
    getCountries();
  }, []);

  useEffect(() => {
    GetProvinceByCountry();
  }, [country]);
  useEffect(() => {
    GetRegionsByProvinces();
  }, [province]);
  useEffect(() => {
    if (firstname && lastname) {
      GenerateUsername(firstname, lastname);
    }
  }, [firstname, lastname]);

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => () => {
    setRows(rows.filter((row) => row.id !== id));
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = async (newRow) => {
    if (newRow && newRow.Pays) {
      setCountry(countries.filter((p) => p.nomFr == newRow.Pays));
    }
    if (newRow && newRow.Province) {
      setProvince(provinces.filter((p) => p.nomFr == newRow.Province));
    }
    if (newRow && newRow.Region) {
      setRegion(regions.filter((r) => r.nomFr == newRow.Region));
    }
    if (newRow && newRow.firstName && newRow.lastName) {
      newRow.username = await GenerateUsername(
        newRow.firstName,
        newRow.lastName
      );
    }
    if (
      newRow &&
      newRow.firstName &&
      newRow.lastName &&
      newRow.email &&
      newRow.langue
    ) {
      const updatedRow = { ...newRow, isNew: false };
      setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
      return updatedRow;
    } else {
      alert("invalid data");
    }
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleCreateShareholder = () => {
    setOpenModalShareholder(false);
    setRows((oldRows) =>
      oldRows.concat([
        {
          id: idCurrentShareholder,
          exploitant: isExploitant,
          firstName: firstname,
          lastName: lastname,
          username: username,
          email: email,
          noCellulaire: cellulaire,
          langue: language,
          country: country,
          province: province,
          region: region,
          ville: city,
          adresse: adress,
          codePostal: codePostal,
          pourcentageAction: pourcentageAction,
        },
      ])
    );

    setIdCurrentShareholder((prev) => prev + 1);
    setShareholders(
      rows.concat([
        {
          id: idCurrentShareholder,
          exploitant: isExploitant,
          firstName: firstname,
          lastName: lastname,
          username: username,
          email: email,
          noCellulaire: cellulaire,
          langue: language,
          country: country,
          province: province,
          region: region,
          ville: city,
          adresse: adress,
          codePostal: codePostal,
          pourcentageAction: pourcentageAction,
        },
      ])
    );
    setIsExploitant(false);
    setfirstname();
    setLastname();
    setUsername();
    setEmail();
    setCellulaire();
    setLanguage();
    setCountry();
    setProvince();
    setRegion();
    setCity();
    setPourcentageAction();
  };

  function EditToolbar() {
    const handleClick = () => {
      setIdCurrentShareholder((prev) => prev + 1);
      setOpenModalShareholder(true);
    };

    const handleClickExistingShareholder = (e) => {
      if (fields) {
        let shareholder = fields.filter((s) => s.username == e.target.value)[0];
        shareholder.id = idCurrentShareholder;
        setIdCurrentShareholder((prev) => prev + 1);
        append({
          id: idCurrentShareholder,
          firstName: shareholder.lastName,
          lastName: shareholder.name,
          username: shareholder.username,
          email: shareholder.email,

          noCellulaire: shareholder.noCellulaire,
          langue: shareholder.language,
        });
      }
    };
    return (
      <GridToolbarContainer>
        <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
          {t("AddNewShareholder")}
        </Button>
        <Button color="primary">
          {t("AddExistingShareholder")}
          <Select
            id="select-shareHolders"
            onClick={() => setOpenShareholderSelect(!openShareholderSelect)}
            open={openShareholderSelect}
            onChange={(e) => handleClickExistingShareholder(e)}
          >
            {fields &&
              fields.map((s) => {
                return (
                  <MenuItem key={s.username} value={s.username}>
                    {s.lastName + " " + s.name + " (" + s.username + ")"}
                  </MenuItem>
                );
              })}
          </Select>
        </Button>
      </GridToolbarContainer>
    );
  }

  const handleSelectionEdit = async (selection) => {
    if (
      !selection ||
      !selection.editRows ||
      !selection.tabIndex ||
      !selection.tabIndex.cell
    ) {
      return;
    }

    const rowId = selection.tabIndex.cell.id;
    if (!rowId || !selection.editRows[rowId]) {
      return;
    }

    const editRow = selection.editRows[rowId];

    if (editRow.Pays?.value) {
      setCountry(editRow.Pays.value);
    }

    if (editRow.Province?.value) {
      setProvince(editRow.Province.value);
    }

    if (editRow.Region?.value) {
      setRegion(editRow.Region.value);
    }

    if (editRow.firstName?.value && editRow.lastName?.value) {
      try {
        const username = await GenerateUsername(
          editRow.firstName.value,
          editRow.lastName.value
        );
        setUsername(username);

        if (editRow.username) {
          editRow.username.value = username;
        }
      } catch (error) {
        console.error("Error generating username:", error);
      }
    }
  };

  const GenerateUsername = async (name, lastname) => {
    var userInfo = {
      firstname: firstname,
      lastname: lastname,
    };
    return fetch(`${process.env.REACT_APP_API_URL}/employee/GenerateUsername`, {
      method: "POST",
      withCredentials: true,
      credentials: "include",

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userState.token}`,
      },
      body: JSON.stringify(userInfo),
    })
      .then((results) => {
        return results.text();
      })
      .then((data) => {
        setUsername(data);
      });
  };

  return (
    <>
      <Modal
        open={openModalShareholder}
        onClose={handleCloseModalShareholder}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div>
          <Box sx={style} className="CreateShareholder__Modal">
            <h5>General Information</h5>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={<Checkbox />}
                  label="Exploitant"
                  value={isExploitant}
                  onChange={() => {
                    setIsExploitant(!isExploitant);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="lastname"
                  name="lastname"
                  label="nom"
                  fullWidth
                  size="small"
                  autoComplete="off"
                  variant="outlined"
                  value={lastname}
                  onChange={(e) => setLastname(e.currentTarget.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="firstname"
                  name="firstname"
                  label="prenom"
                  fullWidth
                  size="small"
                  autoComplete="off"
                  variant="outlined"
                  value={firstname}
                  onChange={(e) => setfirstname(e.currentTarget.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  key={username}
                  id="Username"
                  name="Username"
                  label="Username"
                  fullWidth
                  size="small"
                  autoComplete="off"
                  variant="outlined"
                  value={username}
                  onChange={(e) => setUsername(e.currentTarget.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="Email"
                  name="Email"
                  label="Email"
                  fullWidth
                  size="small"
                  autoComplete="off"
                  variant="outlined"
                  value={email}
                  onChange={(e) => setEmail(e.currentTarget.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="Nom"
                  name="Nom"
                  label="No Cellulaire"
                  fullWidth
                  size="small"
                  autoComplete="off"
                  variant="outlined"
                  value={cellulaire}
                  onChange={(e) => setCellulaire(e.currentTarget.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="Nom"
                  name="Nom"
                  label="% actions"
                  fullWidth
                  size="small"
                  autoComplete="off"
                  variant="outlined"
                  value={pourcentageAction}
                  onChange={(e) => setPourcentageAction(e.currentTarget.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel id="LangueLabel">Langue</InputLabel>
                  <Select
                    labelId="LangueLabel"
                    aria-label="langue"
                    id="demo-simple-select"
                    value={language}
                    label="Langue"
                    onChange={(e) => setLanguage(e.target.value)}
                  >
                    {languages.map((l, i) => (
                      <MenuItem key={i} value={l}>
                        {l}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="Nom"
                  name="Nom"
                  label="Adresse"
                  fullWidth
                  size="small"
                  autoComplete="off"
                  variant="outlined"
                  value={adress}
                  onChange={(e) => setAdress(e.currentTarget.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="Nom"
                  name="Nom"
                  label="City"
                  fullWidth
                  size="small"
                  autoComplete="off"
                  variant="outlined"
                  value={city}
                  onChange={(e) => setCity(e.currentTarget.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="Nom"
                  name="Nom"
                  label="Code Postal"
                  fullWidth
                  size="small"
                  autoComplete="off"
                  variant="outlined"
                  value={codePostal}
                  onChange={(e) => setCodePostal(e.currentTarget.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel id="paysLabel">Pays</InputLabel>
                  <Select
                    labelId="paysLabel"
                    aria-label="Pays"
                    id="demo-simple-select"
                    value={country}
                    label="Pays"
                    onChange={(e) => setCountry(e.target.value)}
                  >
                    {countries.map((d, i) => (
                      <MenuItem key={i} value={d}>
                        {d}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel id="provinceLabel">Province</InputLabel>
                  <Select
                    labelId="provinceLabel"
                    id="demo-simple-select"
                    value={province}
                    label="Province"
                    onChange={(e) => setProvince(e.target.value)}
                  >
                    {provinces.map((d, i) => (
                      <MenuItem key={i} value={d}>
                        {d}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel id="regionLabel">Region</InputLabel>
                  <Select
                    labelId="regionLabel"
                    label="Region"
                    id="demo-simple-select"
                    value={region}
                    onChange={(e) => setRegion(e.target.value)}
                  >
                    {regions.map((d, i) => (
                      <MenuItem key={i} value={d}>
                        {d}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Button onClick={handleCreateShareholder}>
              {t("AddSharholder")}
            </Button>
          </Box>
        </div>
      </Modal>
      <DataGrid
        editMode="row"
        rows={rows}
        columns={columns}
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        onStateChange={(selection) => handleSelectionEdit(selection)}
        slots={{
          toolbar: EditToolbar,
        }}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 100 },
          },
        }}
        pageSizeOptions={[5, 10, 50, 100]}
        sx={{ m: 2, height: "80%" }}
      />
      <Modal
        open={openModalAdresse}
        onClose={handleCloseModalAdresse}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="CompanyManagement__Modal">
          <DetailsShareholder adresse={detailAdress} />
        </Box>
      </Modal>
    </>
  );
}

export default CreateShareholder;
