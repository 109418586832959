import React, { useState, useEffect } from "react";
import {
  DataGrid,
  GridRowModes,
  GridToolbar,
  GridActionsCellItem,
  GridRowEditStopReasons,
} from "@mui/x-data-grid";
import { Box, Button, Modal } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import PersonIcon from "@mui/icons-material/Person";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import CreateShareholder from "../Modal/CreateShareholder";

function ShareholderList({ company, shareHoldersByEmplacement }) {
  const [rows, setRows] = useState([]);
  const userState = useSelector((state) => state.user);
  const { t, i18n } = useTranslation(["Company"], ["Common"]);
  const [shareHolders, setShareHolders] = useState([]);
  const [rowModesModel, setRowModesModel] = React.useState({});
  const [currrentShareHoldersList, setCurrentShareHoldersList] = useState([]);
  const [openShareholder, setOpenShareholder] = useState();
  const [openModalAdresse, setOpenModalAdresse] = useState(false);
  const [idCurrentShareholder, setIdCurrentShareholder] = useState(0);
  const [adresseShareholder, setAdresseShareholder] = useState();

  const handleOpenAddShareHolder = async () => {
    setOpenShareholder(true);
    setIdCurrentShareholder((prev) => prev + 1);
  };

  const handleCloseAddShareHolder = () => setOpenShareholder(false);
  const handleOpenModalAdresse = () => setOpenModalAdresse(true);

  useEffect(() => {
    GetShareHolders();
  }, []);

  useEffect(() => {
    BuildRows();
  }, [shareHoldersByEmplacement]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    height: 800,
    width: 800,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const BuildRows = () => {
    if (shareHoldersByEmplacement && shareHoldersByEmplacement.length > 0) {
      let rows = [];
      let i = 0;
      shareHoldersByEmplacement.forEach((e) => {
        rows.push({
          id: i++,
          isActive: e.estInactif,
          firstName: e.firstname,
          lastName: e.lastName,
          username: e.username,
          email: e.email,
          NoCellulaire: e.noCellulaire,
          langue: e.language,
          department: e.department.textEn,
          lastLogin: e.lastLogin,
          PourcentageAction: e.pourcentageAction,
          action: "",
        });
      });

      setRows(rows);
    } else {
      setRows([]);
    }
  };

  const columns = [
    { field: "isActive", headerName: "Inactive", width: 50 },
    { field: "firstName", headerName: "Prenom", width: 130, editable: true },
    { field: "lastName", headerName: "Nom", width: 130, editable: true },
    {
      field: "username",
      headerName: "Username",
      width: 130,
      editable: false,
    },
    { field: "email", headerName: "Email", width: 250, editable: true },
    {
      field: "NoCellulaire",
      headerName: "No Cellulaire",
      width: 130,
      editable: true,
    },
    { field: "langue", headerName: "Langue", width: 80, editable: true },
    {
      field: "department",
      headerName: "Departement",
      width: 130,
      editable: true,
    },
    {
      field: "PourcentageAction",
      headerName: "% Action",
      width: 80,
      editable: true,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        const findRow = rows.find((row) => row.id == id);

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }
        if (findRow.isActive == false) {
          return [
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              className="textPrimary"
              onClick={handleEditClick(id)}
              color="inherit"
            />,
            <GridActionsCellItem
              icon={<ManageAccountsIcon />}
              label="Details"
              onClick={() => handleOpenDetailsShareholder(id)}
              color="inherit"
            />,
            <GridActionsCellItem
              icon={<PersonOffIcon />}
              label="Delete"
              onClick={handleDeactivateClick(id)}
              color="inherit"
            />,
          ];
        } else {
          return [
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              className="textPrimary"
              onClick={handleEditClick(id)}
              color="inherit"
            />,
            <GridActionsCellItem
              icon={<ManageAccountsIcon />}
              label="Delete"
              onClick={handleDeactivateClick(id)}
              color="inherit"
            />,
            <GridActionsCellItem
              icon={<PersonIcon />}
              label="Delete"
              onClick={handleActivateClick(id)}
              color="inherit"
            />,
          ];
        }
      },
    },
  ];

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    processRowUpdate(rows.filter((row) => row.id == id)[0]);
  };

  const handleDeactivateClick = (id) => () => {
    rows.filter((row) => row.id == id)[0].isInactive = true;
    processRowUpdate(rows.filter((row) => row.id == id)[0]);
  };

  const handleActivateClick = (id) => () => {
    rows.filter((row) => row.id == id)[0].isInactive = false;
    processRowUpdate(rows.filter((row) => row.id == id)[0]);
  };

  const GetShareHolders = async () => {
    fetch(`employe/GetShareHolders`, {
      method: "GET",
      withCredentials: true,
      credentials: "include",

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userState.token}`,
      },
    })
      .then((results) => {
        return results.json();
      })
      .then((data) => {
        setShareHolders(data);
      });
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };
  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));

    /**********************/
    handleSaveShareholder(updatedRow);
    /**********************/
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleNewShareholder = async () => {
    if (company.noCompagnie) {
      var dataShareholders = {
        shareholders: currrentShareHoldersList,
        noCompany: company.noCompagnie,
      };

      fetch(`${process.env.REACT_APP_API_URL}/company/CreateShareholders`, {
        method: "POST",
        withCredentials: true,
        credentials: "include",

        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userState.token}`,
        },
        body: JSON.stringify(dataShareholders),
      }).then((results) => {
        return results.json();
      });
    }
  };

  const handleSaveShareholder = async (shareholder) => {
    var data = {
      shareholder: shareholder,
      noCompany: company.noCompagnie,
    };

    fetch(`${process.env.REACT_APP_API_URL}/employe/SaveShareholder`, {
      method: "POST",
      withCredentials: true,
      credentials: "include",

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userState.token}`,
      },
      body: JSON.stringify(data),
    }).then((results) => {
      return results.json();
    });
  };
  const handleOpenDetailsShareholder = (id) => {
    const row = rows.filter((row) => row.id == id)[0];
    GetAdresse(row);
    handleOpenModalAdresse();
  };

  const GetAdresse = async (row) => {
    var dataShareholder = {
      username: row.username,
    };

    fetch(`employe/GetAdresse`, {
      method: "POST",
      withCredentials: true,
      credentials: "include",

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userState.token}`,
      },
      body: JSON.stringify(dataShareholder),
    })
      .then((results) => {
        return results.json();
      })
      .then((data) => {
        setAdresseShareholder(data);
      });
  };

  return (
    <>
      {company ? (
        <Button onClick={handleOpenAddShareHolder}>Add shareholder</Button>
      ) : (
        ""
      )}

      <Modal
        open={openShareholder}
        onClose={handleCloseAddShareHolder}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="CompanyManagement__Modal">
          {
            <CreateShareholder
              shareholders={shareHolders}
              shareholdersList={setCurrentShareHoldersList}
            />
          }
          <Button onClick={handleNewShareholder}>{t("Save")}</Button>
        </Box>
      </Modal>
      <DataGrid
        editMode="row"
        rows={rows}
        columns={columns}
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        slots={{
          toolbar: GridToolbar,
        }}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
          filter: {
            filterModel: {
              items: [
                {
                  columnField: "isActive",
                  operator: "is",
                  value: "false",
                },
              ],
            },
          },
          columns: {
            columnVisibilityModel: {
              isActive: false,
            },
          },
        }}
        pageSizeOptions={[5, 10, 50, 100]}
        sx={{ m: 2 }}
      />
    </>
  );
}

export default ShareholderList;
