import React, { useEffect, useRef, useState } from "react";
import {
  Modal,
  Box,
  TextField,
  Button,
  Typography,
  Autocomplete,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { FormatDateTimeAnneeMoisJour } from "../../../../../utils/formateDate";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { IconButton } from "@mui/material";
import {
  Delete as DeleteIcon,
  Download as DownloadIcon,
} from "@mui/icons-material";

function ModifyComplaint({
  open,
  onClose,
  complaintData,
  setAlertMessage,
  setAlertType,
  setAlertOpen,
}) {
  const style = {
    overflow: "auto",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    height: 800,
    width: 800,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    overflow: "auto",
  };

  const [complaint, setComplaint] = useState(null);
  const [images, setImages] = useState([]);
  const [formValues, setFormValues] = useState({});
  const userState = useSelector((state) => state.user);
  const [statusses, setStatusses] = useState([]);
  const [creditTypes, setCreditTypes] = useState([]);
  const modalContentRef = useRef(); // Add a ref for the modal content
  const { t } = useTranslation(["Casserole"], ["Common"]);
  const [files, setFiles] = useState([]);

  const handleFileChange = (e) => {
    if (e.target.files) {
      const newFiles = Array.from(e.target.files);
      setFiles((prevFiles) => [...prevFiles, ...newFiles]);
    }
  };

  const handleRemoveFile = (indexToRemove) => {
    setFiles((prevFiles) =>
      prevFiles.filter((_, index) => index !== indexToRemove)
    );
  };

  useEffect(() => {
    const fetchComplaintById = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/Casserole/GetComplaintById/${complaintData.id}`,
          {
            method: "GET",
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userState.token}`,
            },
          }
        );
        if (!response.ok) throw new Error("Failed to fetch complaint");
        const data = await response.json();
        const formattedData = {
          id: data.id,
          date: FormatDateTimeAnneeMoisJour(data.dateCreation),
          restaurant: data.nomEmplacement || "",
          phoneNumber: data.noTel || "",
          personInCharge: data.nomResponsable || "",
          product:
            userState.lang === "EN"
              ? `${data.idItemAccPac} | ${data.nomEn} | ${data.format}`
              : `${data.idItemAccPac} | ${data.nomFr} | ${data.format}` || "",
          problem: data.probleme || "",
          quantity: data.quantite || "",
          comment: data.comment || "",
          unit: data.unit || "",
          supplier: data.fournisseur || "",
          typeCredit: data.idCrediteur,
          credit: data.credit,
          transporteur: data.transporteur || "",
          status: userState.lang === "EN" ? data.statutEn : data.statutFr || "",
          noLot: data.noLot || "",
          productionDate: data.dateProduction || "",
          expirationDate: data.dateExpiration || "",
        };
        setComplaint(formattedData);

        // Find the matching status option
        const initialStatus = statusses.find(
          (status) =>
            (userState.lang === "EN" ? status.textEn : status.textFr) ===
            formattedData.status
        );

        const initialCreditType = creditTypes.find(
          (creditType) => creditType.id === formattedData.typeCredit
        );
        setFormValues({
          id: complaintData.id,
          status: initialStatus || null,
          quantity: formattedData.quantity || "",
          unit: formattedData.unit || "",
          comment: `${formattedData.comment || ""}\n[${new Date(
            Date.now()
          ).toLocaleString()}] [${userState.username}] : `,
          credit: formattedData.credit,
          typeCredit: initialCreditType || null,
        });
      } catch (error) {
        console.error("Error fetching complaint:", error);
        alert("Unable to fetch complaint. Please try again later.");
      }
    };

    const fetchComplaintImageById = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/Casserole/GetImagesComplaintById/${complaintData.id}`,
          {
            method: "GET",
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userState.token}`,
            },
          }
        );

        // Handle different response statuses
        if (response.status === 204) {
          console.log("No images or PDFs available for this complaint.");
          setImages([]);
          return;
        }

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        // Check if response is empty
        const text = await response.text();
        if (!text) {
          console.log("Empty response received");
          setImages([]);
          return;
        }

        // Try to parse JSON only if we have content
        let data;
        try {
          data = JSON.parse(text);
        } catch (e) {
          console.log("Response is not JSON:", text);
          throw new Error("Invalid JSON response");
        }

        if (!Array.isArray(data)) {
          console.log("Response is not an array:", data);
          setImages([]);
          return;
        }

        if (data.length === 0) {
          console.log("No files found for this complaint.");
          setImages([]);
          return;
        }

        // Process the files
        const formattedFiles = data
          .map((byteArray) => {
            if (!byteArray) {
              console.log("Skipping null/undefined byte array");
              return null;
            }

            try {
              // Check if the byteArray is a string and contains base64 data
              if (typeof byteArray !== "string") {
                console.log("Invalid byte array format:", byteArray);
                return null;
              }

              // Detect file type
              if (byteArray.startsWith("JVBER")) {
                return {
                  type: "pdf",
                  url: `data:application/pdf;base64,${byteArray}`,
                };
              } else {
                return {
                  type: "image",
                  url: `data:image/png;base64,${byteArray}`,
                };
              }
            } catch (e) {
              console.error("Error processing file:", e);
              return null;
            }
          })
          .filter(Boolean); // Remove null entries

        setImages(formattedFiles);
        console.log("Processed files:", formattedFiles);
      } catch (error) {
        console.error("Error fetching complaint files:", error);
        console.error("Error details:", {
          message: error.message,
          stack: error.stack,
        });
        setImages([]);
        // Use a more user-friendly error message
        setAlertMessage("Unable to load complaint files. Please try again.");
        setAlertType("error");
        setAlertOpen(true);
      }
    };
    if (complaintData) {
      fetchComplaintById();
      fetchComplaintImageById();
    }
  }, [complaintData, userState.token, statusses, creditTypes]);

  // Fetch complaint statuses only once
  useEffect(() => {
    const fetchComplaintStatus = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/Casserole/GetComplaintStatus`,
          {
            method: "GET",
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userState.token}`,
            },
          }
        );
        if (!response.ok) throw new Error("Failed to fetch complaint status");
        const data = await response.json();
        setStatusses(data);
      } catch (error) {
        console.error("Error fetching complaint:", error);
        alert("Unable to fetch complaint. Please try again later.");
      }
    };

    const fetchComplaintCreditTypes = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/Casserole/GetComplaintCreditTypes`,
          {
            method: "GET",
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userState.token}`,
            },
          }
        );
        if (!response.ok)
          throw new Error("Failed to fetch complaint credit types");
        const data = await response.json();
        setCreditTypes(data);
      } catch (error) {
        console.error("Error fetching complaint credit types:", error);
        alert(
          "Unable to fetch complaint credit types. Please try again later."
        );
      }
    };

    fetchComplaintStatus();
    fetchComplaintCreditTypes();
  }, [userState.token]); // Fetch statuses once when the component mounts or when the token changes

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async () => {
    console.log("Updated Complaint:", formValues);

    const formData = new FormData();
    formData.append("Id", formValues.id);
    formData.append("IdStatut", formValues.status?.id || 0);
    formData.append("Unit", formValues.unit || null);
    formData.append("Comment", formValues.comment || "");
    formData.append("Credit", formValues.credit || 0);
    formData.append("IdCrediteur", formValues.typeCredit?.id || 0);
    formData.append("Quantity", formValues.quantity || 0);

    files.forEach((file, index) => {
      formData.append(`Files`, file);
    });

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/Casserole/UpdateComplaintById`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${userState.token}`, // Include the token in the headers
          },
          body: formData,
        }
      );

      if (response.ok) {
        const data = await response.json();
        console.log("Complaint updated:", data);

        // Show success alert
        setAlertMessage("Complaint updated successfully!");
        setAlertType("success");
        setAlertOpen(true);
        onClose(); // Close the modal
      } else {
        console.error("Failed to update complaint:", response);
        setAlertMessage("Failed to update complaint. Please try again.");
        setAlertType("error");
        setAlertOpen(true);
      }
    } catch (error) {
      console.error("Error updating complaint:", error);
      setAlertMessage("An error occurred while updating the complaint.");
      setAlertType("error");
      setAlertOpen(true);
    }
  };

  const handlePrint = () => {
    if (!modalContentRef.current) {
      alert("Nothing to print. Please check the content.");
      return;
    }

    const printableContent = `
  <html>
    <head>
      <title>Print Complaint</title>
      <style>
        body {
          font-family: Arial, sans-serif;
          margin: 0;
          padding: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #f4f4f4;
        }
        .print-box {
          width: 90%;
          max-width: 800px;
          padding: 20px;
          background: white;
          border-radius: 10px;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        }
        h2 {
          text-align: center;
          color: #333;
        }
        .section {
          border: 1px solid #ccc;
          border-radius: 8px;
          padding: 15px;
          margin-bottom: 20px;
        }
        .field {
          margin-bottom: 10px;
        }
        .field-label {
          font-weight: bold;
          color: #555;
        }
        .field-value {
          margin: 0;
          color: #333;
        }
        img {
          max-width: 100%;
          height: auto;
          margin-top: 10px;
          border-radius: 5px;
        }
        @media print {
          body {
            background-color: white;
          }
          .no-print {
            display: none;
          }
        }
      </style>
    </head>
    <body>
      <div class="print-box">
        <h2>Complaint no : ${complaint.id}</h2>

        <!-- Complaint Details -->
        <div class="section">
          <div class="field">
            <span class="field-label">ID:</span>
            <span class="field-value">${complaint.id}</span>
          </div>
          <div class="field">
            <span class="field-label">Date:</span>
            <span class="field-value">${complaint.date}</span>
          </div>
          <div class="field">
            <span class="field-label">Restaurant:</span>
            <span class="field-value">${complaint.restaurant}</span>
          </div>
          <div class="field">
            <span class="field-label">Item:</span>
            <span class="field-value">${complaint.product}</span>
          </div>
          <div class="field">
            <span class="field-label">Supplier:</span>
            <span class="field-value">${complaint.supplier}</span>
          </div>
          <div class="field">
            <span class="field-label">Transporteur:</span>
            <span class="field-value">${complaint.transporteur}</span>
          </div>
        </div>
        <div class="section">
        <div class="field">
            <span class="field-label">Problem:</span>
            <span class="field-value">${complaint.problem}</span>
          </div>
        </div>

        <!-- Additional Information -->
        <div class="section">
          <div class="field">
            <span class="field-label">Comment:</span>
            <p class="field-value">${formValues.comment.replace(
              /\n/g,
              "<br>"
            )}</p>
          </div>
          <div class="field">
            <span class="field-label">Quantity:</span>
            <span class="field-value">${formValues.quantity}</span>
          </div>
          <div class="field">
            <span class="field-label">Unit:</span>
            <span class="field-value">${formValues.unit}</span>
          </div>
          <div class="field">
            <span class="field-label">Type de credit:</span>
            <span class="field-value">${
              formValues.typeCredit?.textEn || "N/A"
            }</span>
          </div>
          <div class="field">
            <span class="field-label">Credit:</span>
            <span class="field-value">${formValues.credit}</span>
          </div>
        </div>

        
      </div>
    </body>
  </html>
`;

    const printWindow = window.open("", "_blank");
    if (printWindow) {
      printWindow.document.write(printableContent);
      printWindow.document.close();
      printWindow.focus();
      printWindow.print();
      printWindow.close();
    }
  };

  const handleAddDocuments = () => {};

  if (!complaint) {
    return null; // Show nothing until the complaint data is loaded
  }

  const handleOpenPDF = (base64String) => {
    // Convert base64 to Blob
    const byteCharacters = atob(base64String.split(",")[1]);
    const byteArrays = [];
    for (let i = 0; i < byteCharacters.length; i++) {
      byteArrays.push(byteCharacters.charCodeAt(i));
    }
    const byteArray = new Uint8Array(byteArrays);
    const blob = new Blob([byteArray], { type: "application/pdf" });

    // Create a temporary blob URL
    const blobURL = URL.createObjectURL(blob);

    // Open the PDF in a new tab
    window.open(blobURL, "_blank");

    // Revoke the object URL after some time to free up memory
    setTimeout(() => URL.revokeObjectURL(blobURL), 10000);
  };

  const handleDownload = (file, index) => {
    const link = document.createElement("a");
    link.href = file.url; // Use the file's URL for the download
    link.download = `image-preview-${index}.jpg`; // Set a default download name
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  console.log(complaint);

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={style} ref={modalContentRef}>
        <Typography variant="h6" mb={2}>
          Modify Complaint
        </Typography>
        <Box
          sx={{
            m: 2,
            display: "flex", // Make the Box a flex container
            justifyContent: "space-between", // Space out children
            alignItems: "center", // Align items vertically in the center
          }}
        >
          <Button variant="contained" color="primary" onClick={handlePrint}>
            Print
          </Button>

          <Button
            variant="contained"
            color="primary"
            component="label" // This makes the button label the file input
          >
            Add Documents
            <input
              id="file"
              type="file"
              multiple
              accept="*" // Accept all files, or change to "image/*" for only images
              onChange={handleFileChange}
              style={{ display: "none" }} // Keep the input hidden
            />
          </Button>
        </Box>

        {/* Centered Typography fields with outline */}
        <Box
          sx={{
            border: "1px solid #ccc",
            borderRadius: 2,
            padding: 2,
            textAlign: "center",
            marginBottom: 3,
          }}
        >
          <Typography>
            <strong>ID:</strong> {complaint.id}
          </Typography>
          <Typography>
            <strong>Date:</strong> {complaint.date}
          </Typography>
          <Typography>
            <strong>Restaurant:</strong> {complaint.restaurant}
          </Typography>
          <Typography>
            <strong>Item:</strong> {complaint.product}
          </Typography>
          <Typography>
            <strong>Supplier:</strong> {complaint.supplier}
          </Typography>
          <Typography>
            <strong>Transporteur:</strong> {complaint.transporteur}
          </Typography>
          {complaint.noLot && complaint.noLot.trim() !== "" && (
            <Typography>
              <strong>Numéro de lot:</strong> {complaint.noLot}
            </Typography>
          )}
          {complaint.productionDate && (
            <Typography>
              <strong>Date de production:</strong> {complaint.productionDate}
            </Typography>
          )}
          {complaint.expirationDate && (
            <Typography>
              <strong>Date d'expiration:</strong> {complaint.expirationDate}
            </Typography>
          )}
        </Box>
        <Box
          sx={{
            border: "1px solid #ccc",
            borderRadius: 2,
            padding: 2,
            textAlign: "center",
            marginBottom: 3,
          }}
        >
          <Typography>
            <strong>Problem:</strong> {complaint.problem}
          </Typography>
        </Box>

        {/* Editable Fields */}
        <Box mt={2}>
          <Autocomplete
            options={statusses}
            getOptionLabel={(option) =>
              userState.lang === "EN" ? option.textEn : option.textFr
            }
            value={formValues.status}
            onChange={(event, newValue) => {
              setFormValues((prev) => ({ ...prev, status: newValue }));
            }}
            renderInput={(params) => (
              <TextField {...params} label="Status" fullWidth margin="normal" />
            )}
          />

          <TextField
            label="Comment"
            name="comment"
            value={formValues.comment}
            onChange={handleInputChange}
            multiline
            rows={3}
            fullWidth
            margin="normal"
          />
          <Box display="flex" justifyContent="space-between" gap={2}>
            <TextField
              label="Quantity"
              name="quantity"
              type="number"
              value={formValues.quantity}
              onChange={handleInputChange}
              fullWidth
            />
            <FormControl sx={{ minWidth: 100 }}>
              <InputLabel id="unit-select-label">
                {t("TypeQuantity")}
              </InputLabel>
              <Select
                labelId="unit-select-label"
                id="unit-select"
                name="unit"
                value={formValues.unit || ""}
                label={t("TypeQuantity")}
                onChange={(e) => {
                  setFormValues((prev) => ({
                    ...prev,
                    unit: e.target.value,
                  }));
                }}
              >
                <MenuItem value="unite">{t("Unite")}</MenuItem>
                <MenuItem value="caisse">{t("Caisse")}</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box display="flex" gap={2} mt={2}>
            <Autocomplete
              options={creditTypes}
              getOptionLabel={(option) =>
                userState.lang === "EN" ? option.textEn : option.textFr || ""
              }
              value={formValues.typeCredit}
              onChange={(event, newValue) => {
                setFormValues((prev) => ({ ...prev, typeCredit: newValue }));
              }}
              renderInput={(params) => (
                <TextField {...params} label="Type de credit" fullWidth />
              )}
              sx={{ flex: 1 }} // Ensures equal width
            />
            <TextField
              label="Credit"
              name="credit"
              type="number"
              value={formValues.credit}
              onChange={handleInputChange}
              fullWidth
              sx={{ flex: 1 }} // Ensures equal width
            />
          </Box>
        </Box>

        {files.length > 0 && (
          <Box mt={2} display="flex" flexDirection="column" gap={2}>
            {files.map((file, index) => {
              const fileURL = URL.createObjectURL(file); // Generate file URL
              const fileType = file.type || ""; // Detect file type
              const fileName = file.name || `File_${index + 1}`; // Fallback for file name

              // Render previews with a delete option
              return (
                <Box
                  key={index}
                  display="flex"
                  alignItems="center"
                  gap={2}
                  sx={{
                    padding: 1,
                    border: "1px solid #ccc",
                    borderRadius: "8px",
                    backgroundColor: "#f9f9f9",
                  }}
                >
                  {fileType.startsWith("image/") ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={file.url}
                        alt={`Preview ${index}`}
                        style={{
                          maxWidth: "150px",
                          maxHeight: "150px",
                          marginBottom: "8px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          try {
                            // Extract the base64 data (remove the data:image/png;base64, prefix)
                            const base64Data = file.url.split(",")[1];

                            // Convert base64 to binary
                            const byteCharacters = atob(base64Data);
                            const byteArrays = [];

                            for (let i = 0; i < byteCharacters.length; i++) {
                              byteArrays.push(byteCharacters.charCodeAt(i));
                            }

                            // Create blob from binary
                            const blob = new Blob(
                              [new Uint8Array(byteArrays)],
                              { type: "image/png" }
                            );

                            // Create download link
                            const url = window.URL.createObjectURL(blob);
                            const link = document.createElement("a");
                            link.href = url;
                            link.download = `complaint-image-${index}.png`;

                            // Trigger download
                            document.body.appendChild(link);
                            link.click();

                            // Cleanup
                            document.body.removeChild(link);
                            window.URL.revokeObjectURL(url);
                          } catch (error) {
                            console.error("Error downloading image:", error);
                            alert(
                              "Failed to download image. Please try again."
                            );
                          }
                        }}
                      />
                      <Button
                        variant="text"
                        size="small"
                        startIcon={<DownloadIcon />}
                        onClick={() => {
                          // Convert base64 to Blob
                          const base64Response = fetch(file.url);
                          base64Response
                            .then((res) => res.blob())
                            .then((blob) => {
                              // Create blob URL
                              const blobUrl = window.URL.createObjectURL(blob);

                              // Create temporary link and trigger download
                              const link = document.createElement("a");
                              link.href = blobUrl;
                              link.download = `complaint-image-${index}.png`;
                              document.body.appendChild(link);
                              link.click();

                              // Cleanup
                              document.body.removeChild(link);
                              window.URL.revokeObjectURL(blobUrl);
                            });
                        }}
                      >
                        Download
                      </Button>
                    </div>
                  ) : fileType === "application/pdf" ? (
                    <a
                      href={fileURL}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: "#1976d2", textDecoration: "none" }}
                    >
                      {`View PDF: ${fileName}`}
                    </a>
                  ) : (
                    <a
                      href={fileURL}
                      download={fileName}
                      style={{ color: "#1976d2", textDecoration: "none" }}
                    >
                      {`Download File: ${fileName}`}
                    </a>
                  )}

                  {/* Delete Button */}
                  <IconButton
                    onClick={() => handleRemoveFile(index)}
                    color="error"
                    aria-label={`Delete ${fileName}`}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              );
            })}
          </Box>
        )}

        <div className="ComplainForm__UploadFiles">
          <div className="ComplainForm__UploadedFiles">
            {images.map((file, index) => (
              <div key={index} className="ComplainForm__UploadedFileItem">
                {file.type === "pdf" ? (
                  // Render PDF link and open via function
                  <div>
                    <span>📄 PDF File</span>
                    <button
                      onClick={() => handleOpenPDF(file.url)}
                      style={{
                        background: "none",
                        color: "#1976d2",
                        border: "none",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                    >
                      Open PDF
                    </button>
                  </div>
                ) : (
                  // Render image preview
                  <div>
                    <img
                      src={file.url}
                      alt={`Preview ${index}`}
                      style={{
                        maxWidth: "150px",
                        maxHeight: "150px",
                        marginBottom: "8px",
                      }}
                      onClick={() => handleDownload(file, index)}
                    />
                    <img
                      href={file.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: "#1976d2", textDecoration: "none" }}
                    />
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>

        {/* Action Buttons */}
        <Box mt={3} display="flex" justifyContent="space-between">
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Save Changes
          </Button>
          <Button variant="outlined" color="secondary" onClick={onClose}>
            Cancel
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}

export default ModifyComplaint;
