import React, { Suspense, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import "./css/Login.css";
import Modal from "@mui/material/Modal";
import MFA from "./MFA/MFA";
import EmailRecuperation from "./MFA/EmailRecuperation";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import ForgotPassword from "./ForgotPassword";
import ForgotUsername from "./ForgotUsername";
import { useSelector } from "react-redux";
import CustomAlert from "../Common/Components/CustomAlert";

import icone_portailBb from "../../resources/imagesLogin/icone_portail_Brandbook.png";
import icone_portailExtEN from "../../resources/imagesLogin/icone_portail_Extranet_EN.png";
import icone_portailExtFR from "../../resources/imagesLogin/icone_portail_Extranet_FR.png";
import icone_portailLm from "../../resources/imagesLogin/icone_portail_LivreMarque.png";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  height: 600,
  overflow: "auto",
  maxheight: "80%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export function Login() {
  const [username, setUserName] = useState();
  const [password, setPassword] = useState();
  const [userInfo, setUserInfo] = useState();
  const [idSession, setIdSession] = useState();
  const { t, i18n } = useTranslation(["Login"]);
  const [open, setOpen] = useState(false);
  const [openForgotPassword, setOpenForgotPassword] = useState(false);
  const [openForgotUsername, setOpenForgotUsername] = useState(false);
  const [hasEmail, setHasEmail] = useState(false);
  const [checked, setChecked] = useState(false);
  const [ip, setIP] = useState("");
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleOpenForgotPassword = () => setOpenForgotPassword(true);
  const handleOpenForgotUsername = () => setOpenForgotUsername(true);
  const handleCloseForgotPassword = () => setOpenForgotPassword(false);
  const handleCloseForgotUsername = () => setOpenForgotUsername(false);
  const [token, setToken] = useState();

  const [alertOpen, setAlertOpen] = useState(false); // State for alert visibility
  const [alertMessage, setAlertMessage] = useState(""); // State for alert message
  const [alertSeverity, setAlertSeverity] = useState("error"); // State for alert severity

  const userState = useSelector((state) => state.user);

  const handleChange = (event) => {
    setChecked(event.target.checked);
    if (event.target.checked) {
      i18n.changeLanguage("Fr");
    } else {
      i18n.changeLanguage("En");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    sessionStorage.clear();
    localStorage.clear();

    const username = e.target.username.value;
    const password = e.target.password.value;
    setUserName(username);
    setPassword(password);

    const dataUser = {
      username: username,
      password: password,
      ipAdresse: ip,
    };

    try {
      const loginResponse = await fetch(
        `${process.env.REACT_APP_API_URL}/Login/Login`,
        {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(dataUser),
        }
      );

      // Check if response is ok and has content
      if (!loginResponse.ok) {
        throw new Error(`HTTP error! status: ${loginResponse.status}`);
      }

      const contentType = loginResponse.headers.get("content-type");
      if (!contentType || !contentType.includes("application/json")) {
        throw new Error("Server didn't return JSON");
      }

      const responseText = await loginResponse.text();
      if (!responseText) {
        throw new Error("Empty response from server");
      }

      let loginData;
      try {
        loginData = JSON.parse(responseText);
      } catch (e) {
        console.error("Failed to parse JSON:", responseText);
        throw new Error("Invalid JSON response from server");
      }

      if (loginData.token) {
        setToken(loginData.token);
        setIdSession(loginData.idSession);

        const employeeResponse = await fetch(
          `${process.env.REACT_APP_API_URL}/employee/GetEmploye/${loginData.username}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${loginData.token}`,
            },
          }
        );

        if (!employeeResponse.ok) {
          throw new Error(`HTTP error! status: ${employeeResponse.status}`);
        }

        const employeeData = await employeeResponse.json();
        setUserInfo(employeeData);
        setHasEmail(Boolean(employeeData.email));

        handleOpen();
      } else {
        const message = loginData.message || "Username or password incorrect!";
        setAlertMessage(message);
        setAlertSeverity("error");
        setAlertOpen(true);
      }
    } catch (error) {
      console.error("Login error:", error);
      setAlertMessage(error.message || "An error occurred while logging in.");
      setAlertSeverity("error");
      setAlertOpen(true);
    }
  };

  return (
    <>
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        className="Login__toggleButton"
      >
        <Typography>{t("En")}</Typography>
        <Switch
          checked={checked}
          onChange={handleChange}
          inputProps={{ "aria-label": "controlled" }}
        />
        <Typography>{t("Fr")}</Typography>
      </Stack>
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "#FFBC0F" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {t("SignIn")}
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              fullWidth
              id="username"
              label={t("Username") + " ou " + t("Email")}
              name="username"
              onChange={(e) => setUserName(e.currentTarget.value)}
            />
            <TextField
              margin="normal"
              fullWidth
              name="password"
              label={t("Password")}
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={(e) => setPassword(e.currentTarget.value)}
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label={t("RememberMe")}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, bgcolor: "#FFBC0F" }}
              autoFocus
            >
              {t("SignIn")}
            </Button>
            <Grid container justifyContent="center" spacing={2}>
              <Grid item xs={6} textAlign="center">
                <Link variant="body2" onClick={handleOpenForgotUsername}>
                  {t("ForgotUsername")}
                </Link>
              </Grid>
              <Grid item xs={6} textAlign="center">
                <Link variant="body2" onClick={handleOpenForgotPassword}>
                  {t("ForgotPassword")}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
      <Grid
        container
        item
        justifyContent="center"
        alignItems="center"
        sx={{ mt: 2 }}
        spacing={2}
      >
        {/* Image Links */}
        <Grid item>
          <Link href="https://web.chezcora.com/extranet">
            <img
              src={
                i18n.language == "En" ? icone_portailExtEN : icone_portailExtFR
              }
              alt="Extranet"
              style={{ width: 300, height: 80 }}
            />
          </Link>
        </Grid>
        <Grid item>
          <Link href="https://brandbook.chezcora.com">
            <img
              src={i18n.language == "En" ? icone_portailBb : icone_portailLm}
              alt="Brandbook"
              style={{ width: 300, height: 80 }}
            />
          </Link>
        </Grid>
      </Grid>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="Login__Modal">
          <Suspense>
            {hasEmail ? (
              <MFA userInfo={userInfo} idSession={idSession} token={token} />
            ) : (
              <EmailRecuperation
                username={username}
                idSession={idSession}
                token={token}
              />
            )}
          </Suspense>
        </Box>
      </Modal>
      <Modal
        open={openForgotPassword}
        onClose={handleCloseForgotPassword}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="Login__Modal">
          <Suspense>
            <ForgotPassword />
          </Suspense>
        </Box>
      </Modal>
      <Modal
        open={openForgotUsername}
        onClose={handleCloseForgotUsername}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="Login__Modal">
          <Suspense>
            <ForgotUsername />
          </Suspense>
        </Box>
      </Modal>
      <CustomAlert
        open={alertOpen}
        message={alertMessage}
        typeMessage={alertSeverity}
        closeMessage={setAlertOpen}
      />
    </>
  );
}

export default Login;
