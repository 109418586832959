import React, { useState } from "react";
import { ResponsivePie } from "@nivo/pie";
import {
  Typography,
  Box,
  CircularProgress,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import { useTranslation } from "react-i18next";

function CustomersPieChart({ data: rawData, loading, companyId }) {
  const { t } = useTranslation(["Dashboard"]);
  const [timeFilter, setTimeFilter] = useState("month");

  const getComparisonData = () => {
    if (!rawData || !rawData[companyId]) {
      console.log("Invalid or empty rawData:", rawData);
      return null;
    }

    const companyData = rawData[companyId];
    const now = new Date();
    let currentPeriod, previousPeriod;

    const sumDeclarationResults = (items) => {
      // Check if items exists and is an array
      if (!items || !Array.isArray(items)) {
        return 0;
      }

      // Process the sum
      const sum = items.reduce((total, item) => {
        const value = parseInt(item.declarationDataResultat, 10);
        const newTotal = total + (isNaN(value) ? 0 : value);
        return newTotal;
      }, 0);

      return sum;
    };

    try {
      switch (timeFilter) {
        case "day": {
          const today = new Date().toISOString().split("T")[0];
          const yesterday = new Date(
            new Date().setDate(new Date().getDate() - 1)
          )
            .toISOString()
            .split("T")[0];

          currentPeriod = sumDeclarationResults(companyData.daily[today]);
          previousPeriod = sumDeclarationResults(companyData.daily[yesterday]);
          break;
        }

        case "week": {
          const thisWeek = `${now.getFullYear()}-W${String(
            now.getWeek()
          ).padStart(2, "0")}`;
          const lastWeekDate = new Date(now);
          lastWeekDate.setDate(lastWeekDate.getDate() - 7);
          const lastWeek = `${lastWeekDate.getFullYear()}-W${String(
            lastWeekDate.getWeek()
          ).padStart(2, "0")}`;

          currentPeriod = sumDeclarationResults(companyData.weekly[thisWeek]);
          previousPeriod = sumDeclarationResults(companyData.weekly[lastWeek]);
          break;
        }

        case "month": {
          const thisMonth = `${now.getFullYear()}-${String(
            now.getMonth() + 1
          ).padStart(2, "0")}`;
          const lastMonthDate = new Date(now.getFullYear(), now.getMonth() - 1);
          const lastMonth = `${lastMonthDate.getFullYear()}-${String(
            lastMonthDate.getMonth() + 1
          ).padStart(2, "0")}`;

          currentPeriod = sumDeclarationResults(companyData.monthly[thisMonth]);
          previousPeriod = sumDeclarationResults(
            companyData.monthly[lastMonth]
          );
          break;
        }

        case "year": {
          const thisYear = now.getFullYear().toString();
          const lastYear = (now.getFullYear() - 1).toString();

          currentPeriod = sumDeclarationResults(companyData.yearly[thisYear]);
          previousPeriod = sumDeclarationResults(companyData.yearly[lastYear]);
          break;
        }

        default:
          return null;
      }

      return {
        current: currentPeriod || 0,
        previous: previousPeriod || 0,
      };
    } catch (error) {
      console.error("Error in getComparisonData:", error);
      return {
        current: 0,
        previous: 0,
      };
    }
  };

  // Add helper method for getting week number
  Date.prototype.getWeek = function () {
    const d = new Date(
      Date.UTC(this.getFullYear(), this.getMonth(), this.getDate())
    );
    const dayNum = d.getUTCDay() || 7;
    d.setUTCDate(d.getUTCDate() + 4 - dayNum);
    const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    return Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
  };

  const comparisonData = getComparisonData();

  const hasValidData =
    comparisonData &&
    typeof comparisonData.current === "number" &&
    typeof comparisonData.previous === "number";

  const getLabels = () => {
    const now = new Date();

    switch (timeFilter) {
      case "day":
        const today = new Date();
        const yesterday = new Date(now);
        yesterday.setDate(yesterday.getDate() - 1);
        return {
          current: today.toLocaleDateString(undefined, { weekday: "long" }),
          previous: yesterday.toLocaleDateString(undefined, {
            weekday: "long",
          }),
        };

      case "month":
        const thisMonth = new Date();
        const lastMonth = new Date(now);
        lastMonth.setMonth(lastMonth.getMonth() - 1);
        return {
          current: thisMonth.toLocaleDateString(undefined, { month: "long" }),
          previous: lastMonth.toLocaleDateString(undefined, { month: "long" }),
        };

      case "year":
        return {
          current: now.getFullYear().toString(),
          previous: (now.getFullYear() - 1).toString(),
        };

      case "week":
      default:
        return {
          current: t("Current"),
          previous: t("Previous"),
        };
    }
  };

  const labels = getLabels();

  const calculatePercentageChange = () => {
    if (!comparisonData?.previous) return 0;
    return (
      ((comparisonData.current - comparisonData.previous) /
        comparisonData.previous) *
      100
    ).toFixed(1);
  };

  return (
    <Box sx={{ width: "100%", height: "100%", minHeight: 300 }}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Typography variant="h6">{t("CustomerComparison")}</Typography>
        <FormControl size="small">
          <Select
            value={timeFilter}
            onChange={(e) => setTimeFilter(e.target.value)}
            sx={{ minWidth: 120 }}
          >
            <MenuItem value="day">{t("Daily")}</MenuItem>
            <MenuItem value="week">{t("Weekly")}</MenuItem>
            <MenuItem value="month">{t("Monthly")}</MenuItem>
            <MenuItem value="year">{t("Yearly")}</MenuItem>
          </Select>
        </FormControl>
      </Box>

      <Box height="80%">
        {loading || !hasValidData ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
          >
            <CircularProgress />
          </Box>
        ) : comparisonData?.current === 0 && comparisonData?.previous === 0 ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
            flexDirection="column"
          >
            <Typography variant="h6" color="textSecondary">
              {t("NoDataAvailable")}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {t("NoDataForPeriod")}
            </Typography>
          </Box>
        ) : (
          <ResponsivePie
            data={[
              {
                id: labels.current,
                label: labels.current,
                value: Math.max(0, Number(comparisonData?.current || 0)),
                color: "#0088FE",
              },
              {
                id: labels.previous,
                label: labels.previous,
                value: Math.max(0, Number(comparisonData?.previous || 0)),
                color: "#00C49F",
              },
            ]}
            margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
            innerRadius={0.5}
            padAngle={0.7}
            cornerRadius={3}
            activeOuterRadiusOffset={8}
            colors={{ datum: "data.color" }}
            borderWidth={1}
            borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
            arcLinkLabelsSkipAngle={10}
            arcLinkLabelsTextColor="#333333"
            arcLinkLabelsThickness={2}
            arcLinkLabelsColor={{ from: "color" }}
            arcLabelsSkipAngle={10}
            arcLabelsTextColor={{ from: "color", modifiers: [["darker", 2]] }}
            legends={[
              {
                anchor: "bottom",
                direction: "row",
                justify: false,
                translateX: 0,
                translateY: 56,
                itemsSpacing: 0,
                itemWidth: 100,
                itemHeight: 18,
                itemTextColor: "#999",
                itemDirection: "left-to-right",
                itemOpacity: 1,
                symbolSize: 18,
                symbolShape: "circle",
              },
            ]}
          />
        )}
      </Box>

      <Typography
        variant="body2"
        align="center"
        sx={{
          color:
            calculatePercentageChange() > 0 ? "success.main" : "error.main",
          fontWeight: "bold",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "4px",
        }}
      >
        {calculatePercentageChange() > 0 ? "↑" : "↓"}
        {Math.abs(calculatePercentageChange())}%
        <Typography
          component="span"
          variant="body2"
          color="textSecondary"
          sx={{ ml: 1 }}
        >
          {t("ComparedToLastWeek")}
        </Typography>
      </Typography>
    </Box>
  );
}

export default CustomersPieChart;
