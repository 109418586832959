import React from "react";
import { Checkbox } from "@mui/material";
import TreeItem from "@mui/lab/TreeItem";
import { useSelector } from "react-redux";

function CustomTreeNode({
  node,
  selectedNodes,
  handleNodeSelect,
  handleExpandClick,
  indeterminateCheckbox,
  readOnly,
}) {
  const userState = useSelector((state) => state.user);
  return (
    <TreeItem
      key={node.id}
      nodeId={node.id}
      onClick={handleExpandClick}
      label={
        <>
          <Checkbox
            checked={
              Array.isArray(selectedNodes) && selectedNodes.includes(node.id)
            }
            tabIndex={-1}
            disableRipple
            onClick={(event) => handleNodeSelect(event, node.id)}
            disabled={readOnly}
            indeterminate={indeterminateCheckbox.includes(node.id)}
          />
          {userState.lang === "FR"
            ? node.paysFr ||
              node.provinceFr ||
              node.regionFr ||
              node.nomEmplacement
            : node.paysEn ||
              node.provinceEn ||
              node.regionEn ||
              node.nomEmplacement}
        </>
      }
    >
      {node.children?.map((child) => (
        <CustomTreeNode
          key={child.id}
          node={child}
          selectedNodes={selectedNodes}
          handleNodeSelect={handleNodeSelect}
          handleExpandClick={handleExpandClick}
          indeterminateCheckbox={indeterminateCheckbox}
          readOnly={readOnly}
        />
      ))}
    </TreeItem>
  );
}

export default CustomTreeNode;
